import { type TagProps, composeRenderProps, Tag as AriaTag } from 'react-aria-components';
import { IconButton } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './TagGroup.module.css';

export function Tag(props: TagProps) {
  const textValue = typeof props.children === 'string' ? props.children : undefined;

  return (
    <AriaTag
      textValue={textValue}
      {...props}
      className={composeRenderProps(props.className, (className) => clsx(className, styles.tag))}
    >
      {composeRenderProps(props.children, (children, { allowsRemoving, isDisabled }) => (
        <>
          {children}
          {allowsRemoving && (
            <IconButton
              aria-label="Remove tag"
              size="small"
              variant="minimal"
              icon="cancel-circle-outline"
              slot="remove"
              isDisabled={isDisabled}
            />
          )}
        </>
      ))}
    </AriaTag>
  );
}
