import { useQuery } from '@tanstack/react-query';

import { getWarehouseNativeIntegrationHealthQuery } from './warehouseNativeIntegrationConfigs';

export const useGetWarehouseNativeIntegrationHealth = (
  projectKey: string,
  environmentKey: string,
  integrationConfigurationId: string,
  enabled: boolean,
) => {
  return useQuery({
    ...getWarehouseNativeIntegrationHealthQuery({ projectKey, environmentKey, integrationConfigurationId }),
    enabled,
  });
};
