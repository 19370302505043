export function removeIntercom() {
  // Ensures that intercom is not initalized elsewhere, so we can cleanly boot it up.
  if (typeof window?.Intercom !== 'undefined' && window?.Intercom !== null) {
    // eslint-disable-next-line new-cap
    window.Intercom('shutdown');
    delete window.intercomSettings;
    // @ts-expect-error: we know that Intercom is not null or undefined and we know it is on the window object
    delete window.Intercom;
  }
}
