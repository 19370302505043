import { ComponentProps } from 'react';
import { Tag, TagAvatar, TagFallback, TextTruncator } from '@gonfalon/launchpad-experimental';
import { MemberAvatar, memberDisplayName, memberInitials } from '@gonfalon/members';
import { useMember } from '@gonfalon/rest-api';

export function MemberMaintainerTag({ maintainerId }: ComponentProps<typeof Tag> & { maintainerId: string }) {
  const { data: member, isLoading } = useMember({ memberId: maintainerId });

  if (isLoading || !member) {
    return (
      <TagFallback>
        <TagFallback.Visual />
        <TagFallback.Text />
      </TagFallback>
    );
  }

  return (
    <Tag aria-label={memberDisplayName(member)} id={maintainerId} textValue={maintainerId}>
      <TagAvatar>
        <MemberAvatar size="small" email={member.email} initials={memberInitials(member)} />
      </TagAvatar>{' '}
      <TextTruncator>{memberDisplayName(member)}</TextTruncator>
    </Tag>
  );
}
