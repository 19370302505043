/* eslint-disable @typescript-eslint/naming-convention */
import { dogfoodContext, intercomFinAppUserIdentityHash } from '@gonfalon/constants';

import { InitIntercomFinAppParams } from './types';

export async function loadIntercomFinAppScript(appId: string, options?: InitIntercomFinAppParams) {
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: appId,
    // @ts-expect-error member name does exist
    name: window?.ldConstants?.dogfoodContext?.member?.name,
    // @ts-expect-error intercomFinAppUserIdentityHash does exist
    user_hash: options?.identityVerificationEnabled ? intercomFinAppUserIdentityHash() : null,
    // @ts-expect-error dogfood context member key exists
    user_id: options?.identityVerificationEnabled ? dogfoodContext()?.member?.key : null,
    vertical_padding: 60, // Adjust vertical spacing
    horizontal_padding: 5, // Adjust horizontal spacing
    hide_default_launcher: true,
    custom_launcher_selector: '#ia-fin-launcher',
    flowName: options?.flowName,
  };

  return new Promise<void>((resolve, reject) => {
    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
        resolve();
      } else {
        const d = document;
        const i = function () {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        };
        // @ts-expect-error not sure what type this is
        i.q = [];
        i.c = function (args: unknown) {
          i.q.push(args);
        };
        // @ts-expect-error intercom does exist
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${appId}`;
          s.addEventListener('error', reject);
          const x = d.getElementsByTagName('script')[0];
          x.parentNode?.insertBefore(s, x);
          return s;
        };
        if (document.readyState === 'complete') {
          const intercomScript = l();
          // @ts-expect-error: attachEvent expects GlobalEventHandlers, but onload returns void | PromiseLike<void>
          intercomScript.addEventListener('load', resolve);
        } else {
          // @ts-expect-error attachEvent does exist
          if (Boolean(w.attachEvent)) {
            // @ts-expect-error attachEvent does exist
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      }
    })();
  });
}
