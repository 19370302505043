import { getIssues, GetIssuesQueryParams } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { createQueryOptions } from './internal/createQueryOptions';
import { monitoringIssuesList } from './internal/queries';

export const fetchMonitoringIssues = createQueryFetcher(
  ({ projectKey, query }: { projectKey: string; query?: GetIssuesQueryParams }) =>
    monitoringIssuesList({ projectKey, query: query ?? { status: ['open'], sortBy: 'last_seen_error' } }),
);

export const getIssuesQuery = createQueryOptions(getIssues);
