import {
  describeAIConfig,
  getAIConfigMetrics,
  getAIConfigMetricsByVariation,
  getAIConfigMetricsByVersion,
} from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';
// Fetch metrics for ai config monitoring/metrics tab
export const getAiConfigMetricsQuery = createQueryOptions(getAIConfigMetrics);
export const getAiConfigMetricsByVariationQuery = createQueryOptions(getAIConfigMetricsByVariation);
export const getAiConfigMetricsByVersionQuery = createQueryOptions(getAIConfigMetricsByVersion);
export const describeAiConfigQuery = createQueryOptions(describeAIConfig);
