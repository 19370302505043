import { CSSProperties } from 'react';
import { type IconProps, Icon } from '@launchpad-ui/icons';
import clsx from 'clsx';

import styles from './Diamond.module.css';

export interface DiamondProps {
  fill?: string;
  className?: string;
  size?: IconProps['size'];
  // FIXME(REL-4604): this should not be exposed here since it's dependent on whether diamonds are stacked or not
  stroke?: string;
  // FIXME(REL-4604): this should not be exposed here since it's dependent on whether diamonds are stacked or not
  // strokeWidth?: number;
  // FIXME(REL-4604): this should not be exposed here since it's dependent on whether diamonds are stacked or not
  // displayOuterStroke?: boolean;
  // FIXME(REL-4604): do we want still want this?
  style?: CSSProperties;
}

/**
 * Render a diamond icon with an optional outer stroke.
 *
 * We use the LaunchPad icon shape to ensure consistency throughout the UI.
 */
export function Diamond({ fill, stroke, className, size }: DiamondProps) {
  return (
    <div
      className={clsx(styles.diamond, className)}
      style={{
        // @ts-expect-error unknown css property
        '--fill-color': fill,
        '--stroke-color': stroke,
        '--stroke-width': stroke !== undefined ? 2 : undefined,
      }}
    >
      {stroke !== undefined ? <Icon name="diamond" size={size} className={styles.outline} /> : null}
      <Icon name="diamond" style={{ fill }} size={size} className={styles.fill} />
    </div>
  );
}
