// eslint-disable-next-line no-restricted-imports
import { fromJS, is, isImmutable, List, Map, OrderedSet, Record, RecordOf, Set } from 'immutable';

import type { Color } from 'utils/colorUtils';

import { SamlConfig } from './accountUtils';

// In many places, we use immutable.Map as an immutable.Record. That is, we have specific expectations
// around property keys, values and their types. More concretely, we use immutable.Map<K,V>, where V can vary
// from property to property.
//
// ImmutableMap<T> describes maps in those instances by narrowing the base interface so we get type-safety.
//
// For example,
//
// ```
// type Thing = ImmutableMap<{x:number,y:number}>;
// const thing = Map({ x: 0, y: 1}) as Thing;
//
// thing.get('x'); // number
// thing.get('z'); // type error: 'z' is not a property of Thing;
// ```
//
// Context for disabling `typescript-eslint/consistent-type-definitions:
//
// Using an interface that extends Map<string, any> still gives us the expected Map properties,
// but narrows the type of .get in what seemed more reliable.
//
// This is not fully understood. Another alternative which was an improvement over our previous implementation:
//
// export type ImmutableMap<T> = {
//   get<K extends keyof T>(name: K): T[K];
// } & Map<string, any>;
//
// Note the order of the types in the intersection. However, that wasn't as convenient as the interface which
// also improves autocompletion.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ImmutableMap<T> extends Map<any, any> {
  samlConfig?: SamlConfig | null;
  get<K extends keyof T>(key: K, notSetValue?: T[K]): T[K];

  update<K extends keyof T>(key: K, notSetValue: T[K], updater: (value: T[K]) => T[K]): this;
  update<K extends keyof T>(key: K, updater: (value: T[K]) => T[K]): this;
  update(updater: (value: this) => this): this;

  delete<K extends keyof T>(key: K): this;
  remove<K extends keyof T>(key: K): this;

  merge<K extends keyof T>(...collections: Array<Iterable<[K, T[K]]>>): this;
  merge<K extends keyof T>(...collections: Array<Partial<{ [key in K]: T[K] }>>): this;
}

// Useful predicate for pick or omit keys from collections.
//
// fromJS({x:1, y:2, z:3}).filter(keyIn('y', 'z')) === fromJS({y:2, z:3})
// fromJS({x:1, y:2, z:3}).filterNot(keyIn('y', 'z')) === fromJS({x:1})
export function keyIn(...keys: string[]) {
  const keySet = Set(keys);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (_: any, k: string) => keySet.has(k);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const valueExists = (v: any) => v !== null && v !== undefined && v !== '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function modifications(oldMap: Map<string, any>, newMap: Map<string, any>): Map<string, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let mods = Map<string, any>();

  for (const prop of oldMap.keySeq().toArray()) {
    if (!is(oldMap.get(prop), newMap.get(prop))) {
      mods = mods.set(prop, newMap.get(prop));
    }
  }

  return mods;
}

type KeyedRecord = {
  _key?: string;
  key?: string;
};

export function findIndexByKey<V extends KeyedRecord>(list: List<V>, item: V) {
  return list.findIndex((listItem) => {
    if (listItem._key) {
      return listItem._key === item._key;
    }

    if (listItem.key) {
      return listItem.key === item.key;
    }

    return false;
  });
}

type BasicType = boolean | string | number | null | undefined;

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? never : K }[keyof T];
type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

/**
 *
 * This type is meant to be tweaked as needed, the same way we update `ImmutableMap<T>`.
 *
 * [Situation]
 *
 * We use factory functions to instantiate `Record<T>`.
 *
 * Those functions accept partial values. Which means we only need to provide values for properties we care about at creation.
 *
 * Some record properties are `ImmutableMap<T>`, and do not have factory functions.
 *
 * Our records may contain nested `Record<T>` and `ImmutableMap<T>`.
 *
 * To use object literal syntax, we use `Partial<T>` for the type of the argument to factory functions.
 *
 * [Complication]
 *
 * At creation, properties of nested immutable.js properties should be partial, but only at creation. We may have
 * different expectations at runtime (depending on the API).
 *
 * `Partial<T>` is not recursive, so we have to use factory functions for nested properties,
 * which is cumbersome. We get around that by using `fromJS` which takes `any` and returns `any`, so we lose type-safety.
 *
 * [Question]
 *
 * Can we create records in a simple and type-safe way?
 *
 * [Answer]
 *
 * We introduce a new type for record creation that recursively converts an immutable.js input type into an
 * object type where all properties are optional.
 *
 * [Arguments]
 *
 * Type safety will ensure we do not write passing unit tests against incorrect inputs, which is confusing.
 *
 * This also simplifies record creation by letting us use object literal syntax as if we had received it via the API. It'll make
 *
 * Removing immutable.js will be easier: we can remove `Record<T>` definitions, and rely on the underlying types, like
 * `FlagType` or `SavedDashboardType`.
 */

export type CreateFunctionInput<I> =
  | (I extends Record<infer T>
      ? Partial<{
          [K in keyof NonFunctionProperties<T>]: T[K] extends BasicType ? T[K] : CreateFunctionInput<T[K]>;
        }>
      : Partial<{
          [K in keyof NonFunctionProperties<I>]: I[K] extends BasicType ? I[K] : CreateFunctionInput<I[K]>;
        }>)
  | (I extends ImmutableMap<infer T>
      ? Partial<{
          [K in keyof NonFunctionProperties<T>]: T[K] extends BasicType ? T[K] : CreateFunctionInput<T[K]>;
        }>
      : Partial<{
          [K in keyof NonFunctionProperties<I>]: I[K] extends BasicType ? I[K] : CreateFunctionInput<I[K]>;
        }>)
  | (I extends Color ? string | undefined : I)
  | (I extends List<infer T> | Set<infer T> | OrderedSet<infer T>
      ? Array<T extends BasicType ? T : CreateFunctionInput<T>>
      : I);

// This can be used in place of `fromJS` when creating an immutable state entity in a reducer
//
// The benefit is that this function has more explicit typing, whereas `fromJS` takes `any` and returns `any`.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createImmutableState<T extends { [k: string]: any }>(obj: T): ImmutableMap<T> {
  return fromJS(obj);
}
/**
 * This type is supposed to represent the return value of Immutable.js's "toJS" method, which returns "any."
 * This type is intended to recursively convert Immutable.js collections into their respecting JavaScript structures.
 */
type AsJS<I> =
  I extends RecordOf<infer T>
    ? {
        [K in keyof NonFunctionProperties<T>]: T[K] extends BasicType ? T[K] : AsJS<T[K]>;
      }
    : I extends ImmutableMap<infer T>
      ? { [K in keyof T]: T[K] extends BasicType ? T[K] : AsJS<T[K]> }
      : I extends Map<infer K, infer T>
        ? K extends string | number
          ? {
              [key in K]: T extends BasicType ? T : AsJS<T>;
            }
          : never
        : I extends List<infer T> | Set<infer T> | OrderedSet<infer T>
          ? Array<T extends BasicType ? T : AsJS<T>>
          : I;

/**
 * This converts an Immutable.js structure to plain JavaScript, if it is an Immutable.js structure (it has a "toJS" method).
 * Otherwise, the provided value is returned unaltered.
 */
export function toJS<T>(value: T): AsJS<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isImmutable(value)) {
    // Type-safe conversion for immutable values
    const jsValue = value.toJS();
    return jsValue as AsJS<T>;
  }
  return value as AsJS<T>;
}
