import { isThirdPartyScriptsEnabled } from '@gonfalon/dogfood-flags';
import { getGravatarUrl } from '@gonfalon/gravatar';
import { Avatar, AvatarProps } from '@launchpad-ui/components';
import clsx from 'clsx';

export interface MemberAvatarProps extends AvatarProps {
  email: string | undefined;
  initials: string | undefined;
  className?: string;
  ariaLabel?: string;
}

export function MemberAvatar({ email, initials, size, className, ariaLabel }: MemberAvatarProps) {
  const useGravatar = isThirdPartyScriptsEnabled();

  let src = '';
  if (useGravatar && email !== undefined) {
    src = getGravatarUrl(email, {
      // instruct gravatar to 404 if it has no image for the given email
      // this is handled in Avatar with a fallback to our default
      default: '404',
      size: 48,
    });
  }

  let finalInitials;
  if (initials) {
    finalInitials = initials.toUpperCase();
  } else if (email) {
    finalInitials = email.slice(0, 2).toUpperCase();
  } else {
    finalInitials = 'N/A';
  }

  return (
    <Avatar alt="Member avatar" size={size} src={src} className={clsx('fs-exclude', className)} aria-label={ariaLabel}>
      {finalInitials}
    </Avatar>
  );
}
