import { type AnalyticEventData, createTrackerForCategory } from '@gonfalon/analytics';

export type EnvEvaluationSummaryAttributes = {
  hasEnvEvaluated: boolean;
  envLastEvaluatedDate?: number;
  percentageOfFlagsEvaluated?: number;
};
export type FlagEvaluationSummaryAttributes = {
  hasFlagEvaluated: boolean;
  flagLastEvaluatedDate?: number;
  flagThirtyDayEvaluatedCount?: number;
};

export const trackFilterEvent = createTrackerForCategory('Filter');
export const trackExperimentEvent = createTrackerForCategory('Flag Experiments');
export const trackMetricsEvent = createTrackerForCategory('Metrics');
export const trackNavigationEvent = createTrackerForCategory('Manage Flag Navigation');
export const trackSidenavEvent = createTrackerForCategory('Sidenav');
export const trackNotificationSettingsEvent = createTrackerForCategory('Notification Settings');
export const getTrackingLocation = (pathname: string) => {
  if (pathname.includes('/segments') && (pathname.endsWith('/settings') || pathname.endsWith('/targeting'))) {
    return 'segment detail';
  }
  if (pathname.includes('/segments')) {
    return 'segment';
  }

  if (pathname.includes('/features') && pathname.endsWith('/targeting')) {
    return 'flag detail';
  }

  if (pathname.includes('/features/compare')) {
    return 'compare flags';
  }

  if (pathname.includes('/features')) {
    return 'feature flags';
  }
};

//Please be careful when updating. Locations are used across multiple events.
export enum EventLocation {
  FLAG_LIST = 'FlagList',
  FLAG_TARGETING = 'FlagTargeting',
  EXPERIMENT_LIST = 'ExperimentList',
  MEMBER_LIST = 'MemberList',
  METRIC_PAGE = 'MetricPage',
  METRIC_GROUP_PAGE = 'MetricGroupPage',
  //experiments tab on flags
  FLAG_EXPERIMENTS = 'FlagExperiments',
  TEAM_CUSTOM_ROLES = 'TeamCustomRoles',
  TEAMS = 'Teams',
  USER_FLAGS = 'UserFlags',
  FLAG_LINKS = 'FLagLinks',
  EDIT_METRIC = 'EditMetric',
  PAYLOAD_FILTERS = 'PayloadFilters',
  FLAGS_IN_SEGMENT = 'FlagsInSegment',
  TOP_NAV = 'TopNav',
  FLAG_INSIGHTS = 'FlagInsights',
  ACCOUNT_USAGE = 'AccountUsage',
}

export type AnalyticAttributes = { component: string; path: string; url: string; type: string; cta?: string };
export const trackFeatureUpsells = createTrackerForCategory('Upsell');

export const trackUpsellFeatureViewed = (featureKind: string, analyticAttributes: AnalyticAttributes) =>
  trackFeatureUpsells('Feature Upsell Viewed', { featureKind, ...analyticAttributes });

export const trackUpsellLearnMore = (featureKind: string, analyticAttributes: AnalyticAttributes) =>
  trackFeatureUpsells('Upsell Learn More Clicked', { featureKind, ...analyticAttributes });

export const trackUpsellCtaClicked = (
  featureKind: string,
  cta: string,
  analyticAttributes: AnalyticAttributes,
  copy?: string,
) => trackFeatureUpsells('Upsell CTA Clicked', { featureKind, cta, copy, ...analyticAttributes });

export const trackIntegrationEvent = createTrackerForCategory('Integration');

export const trackFlagLinksToolbarEvent = createTrackerForCategory('FlagLinksToolbar');

export type GetComponentAnalyticsArgs = {
  component: string;
  cta?: string;
  type: string;
};

export const getComponentAnalytics = ({ component, cta, type }: GetComponentAnalyticsArgs): AnalyticAttributes => ({
  component,
  url: window.location.href,
  path: window.location.pathname,
  type,
  cta,
});

const trackDocumentationLink = createTrackerForCategory('Documentation Links');
export const trackDocumentationLinkClicked = (targetUrl: string, analyticsAttributes: AnalyticAttributes) =>
  trackDocumentationLink('Documentation Link Clicked', { targetUrl, ...analyticsAttributes });

const trackKeyPressEvent = createTrackerForCategory('KeyPress');
export const trackOnPageSearchEvent = () => {
  document.addEventListener('keydown', (event) => {
    if (!((event.metaKey || event.ctrlKey) && event.key === 'f')) {
      return;
    }

    const { pathname } = document.location;
    if (pathname.match(/settings\/projects$/)) {
      trackKeyPressEvent('Projects Tab - Command + F Pressed');
    } else if (pathname.match(/settings\/projects\/.*\/environments$/)) {
      trackKeyPressEvent('Environments Tab - Command + F Pressed');
    }
  });
};

const trackPlanFeaturesDrawer = createTrackerForCategory('PlanFeaturesDrawer');

export const trackPlanFeaturesDrawerCtaClicked = (analyticAttributes: AnalyticAttributes) =>
  trackPlanFeaturesDrawer('Plan Features CTA Clicked', analyticAttributes);

export const trackPlanFeaturesDrawerInAppLinkClicked = (analyticAttributes: AnalyticAttributes) =>
  trackPlanFeaturesDrawer('Feature CTA Clicked', analyticAttributes);

export const trackAmberfloEvent = (event?: string, attributes?: AnalyticEventData) => {
  createTrackerForCategory('Amberflo')(event || 'Amberflo event', attributes);
};

const trackFollowFlags = createTrackerForCategory('Follow Flags');

export const trackFollowButtonClicked = (
  analyticAttributes: AnalyticAttributes,
  followFlagsAttributes: { isUserFollowingFlag: boolean; flagKey: string },
) => trackFollowFlags('Follow Button Clicked', { ...analyticAttributes, ...followFlagsAttributes });

const UTMParamKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'] as const;
type UTMParamType = (typeof UTMParamKeys)[number];

export type UTMParams = {
  [key in UTMParamType]?: string;
};

export const getUTMParams = () => {
  const params = new URLSearchParams(window.location.search);
  const utmParams: UTMParams = {};

  params.forEach((value, key) => {
    if (UTMParamKeys.includes(key as UTMParamType)) {
      utmParams[key as UTMParamType] = value;
    }
  });

  return utmParams;
};

// This is here as a backup, in case open branches are importing from analyticsUtils and not from @gonfalon/analytics
// If you're setting up a new tracker, be sure to `import { createTrackerForCategory } from '@gonfalon/analytics';`
export { createTrackerForCategory } from '@gonfalon/analytics';
