import { useCallback, useRef, useState } from 'react';
import { useResizeObserver } from '@react-aria/utils';

/**
 * Return a ref and the client height of the element.
 */
export function useElementClientHeight<T extends HTMLElement>() {
  const [clientHeight, setClientHeight] = useState(0);
  const elementRef = useRef<T | null>(null);

  const updateHeight = useCallback(() => {
    if (elementRef.current) {
      setClientHeight(elementRef.current.clientHeight);
    } else {
      setClientHeight(0);
    }
  }, []);

  /**
   * Use a callback ref to detect when the ref is set or changed
   * and update the client height. This is necessary because the ref
   * is not set immediately when the component is mounted, so the
   * client height will not be calculated correctly.
   */
  const ref = useCallback(
    (element: T | null) => {
      elementRef.current = element;

      if (element) {
        setClientHeight(element.clientHeight);
      } else {
        setClientHeight(0);
      }
    },
    [elementRef.current],
  );

  // Use resize observer to update height when element is resized
  useResizeObserver({
    ref: elementRef,
    onResize: updateHeight,
  });

  return { ref, clientHeight } as const;
}
