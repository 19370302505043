import { deleteTeam, getTeam, getTeamRoles, getTeams, patchTeam } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';
import { teams } from './internal/queries';

export const getTeamsQuery = createQueryOptions(getTeams);
export const getTeamQuery = createQueryOptions(getTeam);
export const getTeamRolesQuery = createQueryOptions(getTeamRoles);

export const usePatchTeam = createMutationHook(patchTeam, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: getTeamQuery.partialQueryKey() }),
      queryClient.invalidateQueries({ queryKey: teams.list._def }),
      queryClient.invalidateQueries({ queryKey: getTeamRolesQuery.partialQueryKey() }),
    ]);
  },
});

export const useDeleteTeam = createMutationHook(deleteTeam, {
  onSuccess: async (_, { teamKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: teams.detail({ teamKey }).queryKey });
    await queryClient.invalidateQueries({ queryKey: teams.list._def });
  },
});
