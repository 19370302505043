import { isAnalyticsTrackingEnabled } from '@gonfalon/dogfood-flags';

declare const window: Window & {
  analytics?: SegmentAnalytics.AnalyticsJS;
};

export function trackRouteChange({
  url,
  referer,
  pathPattern,
  refererPathPattern,
}: {
  url: string;
  referer: string | undefined;
  pathPattern: string;
  refererPathPattern: string | undefined;
}) {
  if (isAnalyticsTrackingEnabled()) {
    window.analytics?.page?.({ url, referer, pathPattern, refererPathPattern });
  }
}
