import { patchIssuesBulk, schemas } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { monitoringIssueCounts, monitoringIssuesList } from './internal/queries';

type UpdateIssuesBatchInput = Parameters<typeof patchIssuesBulk>[0];
type MonitoringIssuesList = schemas['Issues'];
type MonitoringIssueCounts = schemas['IssueCounts'];

export const useUpdateIssuesBatch = createMutationHook(
  (input: UpdateIssuesBatchInput) => patchIssuesBulk({ ...input, apiVersion: 'beta' }),
  {
    onMutate: async ({ projectKey, body }) => {
      const queryClient = getQueryClient();

      // Find all matching queries based on a predicate
      const findMatchingQueries = (partialKey: ReturnType<typeof monitoringIssuesList.partialQueryKey>) =>
        queryClient.getQueryCache().findAll({
          predicate: (query) => {
            const key = query.queryKey[0];
            if (key === null || typeof key !== 'object' || 'type' in key === false || 'projectKey' in key === false) {
              return false;
            }
            return key.type === partialKey[0].type && key.projectKey === projectKey;
          },
        });

      // Find matching issue list and counts queries
      const issueListQueries = findMatchingQueries(monitoringIssuesList.partialQueryKey());
      const countsQueries = findMatchingQueries(monitoringIssueCounts.partialQueryKey());
      const countsQuery = countsQueries[0];

      if (!countsQuery) {
        return;
      }

      // Cancel any outgoing refetches
      await Promise.all([
        ...issueListQueries.map((query) => queryClient.cancelQueries({ queryKey: query.queryKey })),
        queryClient.cancelQueries({ queryKey: countsQuery.queryKey }),
      ]);

      // Snapshot previous state
      const previousState = {
        issues: issueListQueries.map((query) => ({
          queryKey: query.queryKey,
          data: queryClient.getQueryData<MonitoringIssuesList>(query.queryKey),
        })),
        counts: queryClient.getQueryData<MonitoringIssueCounts>(countsQuery.queryKey),
      };

      // Locate the status of the first issue being updated
      const currentStatus = previousState.issues
        .flatMap(({ data }) => data?.issues ?? [])
        .find((issue) => body.issueIds.includes(issue.id))?.status;
      if (!currentStatus) {
        return;
      }

      // Type-guard to narrow the target status
      const targetStatus = body.status;
      if (targetStatus !== 'open' && targetStatus !== 'resolved' && targetStatus !== 'ignored') {
        return;
      }

      // Optimistically update issue lists
      issueListQueries.forEach(({ queryKey }) => {
        const key = queryKey[0] as { query: { status: string[] } };
        const queryStatus = key.query.status[0];

        // If this is a list for the old status, remove the issues
        if (queryStatus === currentStatus) {
          queryClient.setQueryData(queryKey, (oldData?: MonitoringIssuesList) => ({
            ...oldData,
            issues: oldData?.issues?.filter((issue) => !body.issueIds.includes(issue.id)) ?? [],
          }));
        }

        // If this is a list for the target status, add the issues
        if (queryStatus === targetStatus) {
          const issuesToAdd = previousState.issues
            .flatMap(({ data }) => data?.issues ?? [])
            .filter((issue) => body.issueIds.includes(issue.id))
            .map((issue) => ({ ...issue, status: targetStatus }));

          queryClient.setQueryData(queryKey, (oldData?: MonitoringIssuesList) => ({
            ...oldData,
            issues: [...(oldData?.issues ?? []), ...issuesToAdd],
          }));
        }
      });

      // Optimistically update counts
      queryClient.setQueryData(countsQuery.queryKey, (oldCounts?: MonitoringIssueCounts) => ({
        ...oldCounts,
        counts: {
          ...oldCounts?.counts,
          [currentStatus]: Math.max(0, (oldCounts?.counts?.[currentStatus] ?? 0) - body.issueIds.length),
          [targetStatus]: (oldCounts?.counts?.[targetStatus] ?? 0) + body.issueIds.length,
        },
      }));

      return {
        previousState,
        countsQueryKey: countsQuery.queryKey,
      };
    },
    onError: (_error, _variables, context) => {
      if (!context) {
        return;
      }

      const queryClient = getQueryClient();

      // Revert optimistic updates on error
      context.previousState.issues.forEach(({ queryKey, data }) =>
        queryClient.setQueryData<MonitoringIssuesList>(queryKey, data as MonitoringIssuesList),
      );

      queryClient.setQueryData<MonitoringIssueCounts>(
        context.countsQueryKey,
        context.previousState.counts as MonitoringIssueCounts,
      );
    },
  },
);
