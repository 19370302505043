import { type ReactNode } from 'react';

import { Tag } from './Tag';

import styles from './TagFallback.module.css';

export function TagFallback({ children }: { children?: ReactNode }) {
  return (
    <Tag textValue="fallback">
      <div className={styles.fallback}>{children}</div>
    </Tag>
  );
}

function Visual() {
  return <div className={styles.visual} />;
}

function Text() {
  return <div className={styles.text} />;
}

TagFallback.Visual = Visual;
TagFallback.Text = Text;
