import { and, anyOf, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { EvaluateContextInstanceOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type EvaluateContextInstanceQueryParams = Omit<EvaluateContextInstanceOpenAPIQueryParams, 'filter'> & {
  filter?: {
    query?: string;
    tags?: string[];
    purpose?: 'all';
  };
};

export function querySerializer(queryParams: EvaluateContextInstanceQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.filter) {
    const serializedFilter = serializeEvaluateContextInstanceFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

export function serializeEvaluateContextInstanceFilterParam(value: EvaluateContextInstanceQueryParams['filter']) {
  const filter: QueryFilter[] = [];

  if (value?.query) {
    filter.push(equals('query', value.query));
  }

  if (value?.purpose) {
    filter.push(equals('purpose', value.purpose));
  }

  if (value?.tags?.length) {
    filter.push(anyOf('tags', value.tags));
  }

  return queryfilterToString(and(filter));
}
