import { CreateSupportTicketLink } from '@gonfalon/contact-support';

export const GenericError = () => (
  <section className="u-flex-center u-mt-xl">
    <h1 className="u-fs-xxl">Uh oh, something went wrong</h1>
    <p className="u-fs-l u-mt-m">
      We ran into an unexpected error. We've been notified. Please try again later or{' '}
      <CreateSupportTicketLink identifierForAnalyticsTracking="ErrorBoundary" />.
    </p>
  </section>
);
