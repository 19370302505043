import { CustomRole, RoleResourceCategory } from './internal/types';

export function isProjectRole(role: CustomRole) {
  return role.resourceCategory === RoleResourceCategory.PROJECT;
}

export function isOrganizationRole(role: CustomRole) {
  return role.resourceCategory === RoleResourceCategory.ORGANIZATION;
}

export function isCustomRole(role: CustomRole) {
  return role.resourceCategory === RoleResourceCategory.ANY || !role.resourceCategory;
}
