import { type GetSegmentsQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { segments } from './internal/queries';

export const useSegments = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
    batch,
    apiVersion,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsQueryParams;
    batch?: boolean;
    apiVersion?: string;
  }) => {
    return segments.list({ projectKey, environmentKey, params, batch, apiVersion });
  },
);
