import {
  getExperiment,
  getExperimentationSettings,
  getExperimentResultsLastUpdated,
  getExperiments,
} from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';

export const getExperimentsQuery = createQueryOptions(getExperiments);

export const getExperimentQuery = createQueryOptions(getExperiment);

export const getExperimentResultsLastUpdatedQuery = createQueryOptions(getExperimentResultsLastUpdated);

export const getExperimentationSettingsQuery = createQueryOptions(getExperimentationSettings);
