import { ReactElement, useEffect, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';
import { memberModifier } from '@gonfalon/members';
import { isRESTAPIError, JSONPatch, usePatchMember } from '@gonfalon/rest-api';
import {
  Button,
  ButtonGroup,
  Dialog,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  ProgressBar,
  Radio,
  RadioGroup,
  snackbarQueue,
  toastQueue,
} from '@launchpad-ui/components';
import { Box, ButtonProps, IconButtonProps } from '@launchpad-ui/core';

import { useMemberOrganizationRoles } from '../hooks';
import { Member } from '../internal/types';

export type UpdateOrganizationRoleModalProps = {
  trigger: ReactElement<ButtonProps | IconButtonProps>;
  edit?: boolean;
  member: Member;
  createJsonPatch: <T>(
    prev: T,
    next: T,
  ) =>
    | JSONPatch
    | {
        comment: string;
        patch: JSONPatch;
      };
};

export const UpdateOrganizationRoleModal = ({
  edit,
  trigger,
  member,
  createJsonPatch,
}: UpdateOrganizationRoleModalProps) => {
  const { allOrganizationRoles, assignedOrganizationRole } = useMemberOrganizationRoles(member);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrgRoleId, setSelectedOrgRoleId] = useState<string | undefined>();

  const { mutate: updateMember, isPending: isUpdatingMember } = usePatchMember();

  const isDirty = selectedOrgRoleId !== assignedOrganizationRole?._id;

  useEffect(() => {
    setSelectedOrgRoleId(assignedOrganizationRole?._id);
  }, [assignedOrganizationRole]);

  const handleOpenChange = (nextIsOpen: boolean) => {
    setIsOpen(nextIsOpen);
  };

  const handleSelectionChange = (value: string) => {
    setSelectedOrgRoleId(value);
  };

  const handleUpdateMember = () => {
    const updatedMember = assignedOrganizationRole
      ? memberModifier(member).removeCustomRole(assignedOrganizationRole._id).addCustomRoles([selectedOrgRoleId])
      : memberModifier(member).addCustomRoles([selectedOrgRoleId]);

    const patch = createJsonPatch(member, updatedMember) as JSONPatch;

    updateMember(
      {
        id: member._id,
        body: patch,
      },
      {
        onSuccess: () => {
          toastQueue.add({ title: 'Organization role updated', status: 'success' });
          handleOpenChange(false);
        },
        onError: (err: Error) => {
          snackbarQueue.add({
            description: isRESTAPIError(err) ? err?.message : 'Failed to update organization role. Try again later.',
            status: 'error',
          });
        },
      },
    );
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      {trigger}
      <ModalOverlay>
        <Modal>
          <Dialog>
            <div slot="header">
              <Heading slot="title">{edit ? 'Edit' : 'Add'} organization role</Heading>
              <IconButton
                aria-label="close modal"
                icon="cancel"
                variant="minimal"
                onPress={() => handleOpenChange(false)}
              />
            </div>

            <Box slot="body" display="grid" gap="$500">
              <p>Assign this member to an organization role.</p>
              <RadioGroup value={selectedOrgRoleId} aria-label="Organization roles" onChange={handleSelectionChange}>
                {allOrganizationRoles.map((r) => (
                  <Radio value={r._id} key={r._id}>
                    {r.name}
                  </Radio>
                ))}
              </RadioGroup>
            </Box>

            <div slot="footer">
              <ButtonGroup>
                <Button variant="default" onPress={() => handleOpenChange(false)}>
                  Cancel
                </Button>
                <Button variant="primary" isDisabled={!isDirty || isUpdatingMember} onPress={handleUpdateMember}>
                  {isUpdatingMember ? <ProgressBar isIndeterminate size="small" /> : 'Save changes'}
                </Button>
              </ButtonGroup>
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
};
