import { ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { isRESTAPIError } from '@gonfalon/rest-api';
import { trackComponentError } from '@gonfalon/telemetry';
import { QueryErrorResetBoundary } from '@tanstack/react-query';

export function QueryErrorBoundary({
  fallbackRender,
  children,
}: {
  fallbackRender: (props: FallbackProps) => ReactNode;
  children: ReactNode;
}) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onError={(error, info) => {
            if (isRESTAPIError(error) && error.status !== 404) {
              trackComponentError(error, info);
            }
          }}
          onReset={reset}
          fallbackRender={fallbackRender}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
