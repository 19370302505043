import { settle } from '@gonfalon/async';
import { patchFeatureFlag } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { reactQueryResponseAdapter } from './internal/reactQueryResponseAdapter';
import { JSONPatch } from './internal/types';
import { getFeatureFlagQuery, getFeatureFlagsQuery, getReleaseByFlagKeyQuery } from './flags';

export async function updateFlag({
  projectKey,
  flagKey,
  comment,
  patch,
}: {
  projectKey: string;
  flagKey: string;
  comment?: string;
  patch: JSONPatch;
}) {
  const queryClient = getQueryClient();

  const [result, error] = await settle(async () =>
    reactQueryResponseAdapter(
      patchFeatureFlag({
        projectKey,
        featureFlagKey: flagKey,
        body: {
          comment,
          patch,
        },
      }),
    ),
  );

  if (error) {
    throw error;
  }

  await queryClient.invalidateQueries({ queryKey: getFeatureFlagsQuery({ projectKey }).queryKey });
  await queryClient.invalidateQueries({
    queryKey: getFeatureFlagQuery({ projectKey, featureFlagKey: flagKey }).queryKey,
  });

  // since the request can return a 404 error, resetting the query rather than invalidating it actually updates the UI
  await queryClient.resetQueries({ queryKey: getReleaseByFlagKeyQuery({ projectKey, flagKey }).queryKey });

  return result;
}
