import { useMemo } from 'react';
import { Collection, Key, Selection } from 'react-aria-components';
import { enableOutOfTheBoxRolesBE, isCustomRolesEnabled } from '@gonfalon/dogfood-flags';
import { uniqBy } from '@gonfalon/es6-utils';
import { SearchField, TextTruncator } from '@gonfalon/launchpad-experimental';
import { getCustomRoleQuery, getCustomRolesQuery } from '@gonfalon/rest-api';
import { conditionalSuspenseQuery, isSuspenseQueryEnabled } from '@gonfalon/suspense';
import { Dialog, Header, Menu, MenuItem, MenuSection, Text } from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';
import { useSuspenseQueries, UseSuspenseQueryOptions } from '@tanstack/react-query';

import { CustomRole } from '../internal/types';
import { isCustomRole, isOrganizationRole, isProjectRole } from '../isRoleCategory';
import { isSimpleRole } from '../isSimpleRole';
import { formattedRoleName, simpleRoles } from '../roles';

import { RoleFilterProps } from './RoleFilter';

type RoleFilterDialogProps = RoleFilterProps & {
  search: string;
  handleSearch: (search: string) => void;
  isTransitioning: boolean;
};

export function RoleFilterDialog({
  search,
  handleSearch,
  selectedKeys,
  onSelectionChange,
  isTransitioning,
}: RoleFilterDialogProps) {
  const enableCustomRoles = isCustomRolesEnabled();
  const isOOTBBEEnabled = enableOutOfTheBoxRolesBE();

  const [projectRolesQuery, orgRolesQuery] = useSuspenseQueries({
    queries: [
      conditionalSuspenseQuery({
        query: getCustomRolesQuery({
          query: {
            filter: {
              query: search,
              resourceCategory: isOOTBBEEnabled ? ['project', 'any'] : undefined,
            },
            limit: 20,
          },
        }),
        enabled: enableCustomRoles,
      }),
      conditionalSuspenseQuery({
        query: getCustomRolesQuery({
          query: {
            filter: {
              query: search,
              resourceCategory: isOOTBBEEnabled ? ['organization'] : undefined,
            },
          },
        }),
        enabled: enableCustomRoles,
      }),
    ],
  });

  // ensure to fetch each of the selected roles so that way we can show them in the menu even if they aren't in the GET roles response,
  // excluding the simple roles since those can't be fetched at /roles/:roleKey
  const selectedRolesQueries = useSuspenseQueries({
    queries: Array.from(selectedKeys)
      .filter((k) => !isSimpleRole(k.toString()))
      .map(
        (key) =>
          conditionalSuspenseQuery({
            query: getCustomRoleQuery({ customRoleKey: key.toString() }),
            enabled: selectedKeys.size > 0 && enableCustomRoles,
          }) as UseSuspenseQueryOptions<CustomRole, Error, CustomRole>,
      ),
  });

  const [projectRolesRes, orgRolesRes] = useMemo(
    () => [
      isSuspenseQueryEnabled(projectRolesQuery) ? projectRolesQuery.data : { items: [] },
      isSuspenseQueryEnabled(orgRolesQuery) ? orgRolesQuery.data : { items: [] },
    ],
    [projectRolesQuery, orgRolesQuery],
  );

  const selectedRolesRes = useMemo(() => {
    if (selectedRolesQueries.every((q) => !isSuspenseQueryEnabled(q))) {
      return [];
    }
    return selectedRolesQueries.map((q) => q.data);
  }, [selectedRolesQueries]);

  const [orgRoles, legacyRoles, allProjectAndCustomRoles] = useMemo(() => {
    const _allRoles = uniqBy([...projectRolesRes.items, ...orgRolesRes.items, ...selectedRolesRes], 'key');
    const _projRoles = _allRoles.filter(isProjectRole);
    const _orgRoles = _allRoles.filter(isOrganizationRole);

    const _legacyRoles = simpleRoles
      .filter((r) => !search || formattedRoleName(r).toLowerCase().includes(search.toLowerCase()))
      .map((r) => ({
        name: formattedRoleName(r),
        key: r,
      }))
      .sort((a, b) => sortSelectedFirst(selectedKeys, a.key, b.key));

    // sorts in the following order for resourceCategory:  any, undefined
    const _customRoles = _allRoles.filter(isCustomRole).sort((a, b) => {
      const order: Record<string, number> = { any: 0 };
      const aValue = a.resourceCategory ? (order[a.resourceCategory] ?? 1) : 1;
      const bValue = b.resourceCategory ? (order[b.resourceCategory] ?? 1) : 1;
      return aValue - bValue;
    });

    // project roles from the API in addition to the custom roles, sorted by selected first
    const _allProjectAndCustomRoles = [..._projRoles, ..._customRoles].sort((a, b) =>
      sortSelectedFirst(selectedKeys, a.key, b.key),
    );

    return [_orgRoles, _legacyRoles, _allProjectAndCustomRoles];
  }, [projectRolesRes, orgRolesRes, selectedRolesRes, selectedKeys, search]);

  const handleSelectionChange = (val: Selection) => {
    if (typeof val === 'string') {
      return;
    }

    const newSelection = new Set(val);
    onSelectionChange(newSelection);
  };

  const hasNoRoles = projectRolesRes.items.length === 0 && orgRolesRes.items.length === 0 && legacyRoles.length === 0;

  return (
    <Dialog aria-label="Role filter">
      <SearchField
        autoFocus
        aria-label="Search roles"
        placeholder="Search roles"
        onChange={handleSearch}
        isBusy={isTransitioning}
      />
      <Menu
        selectionMode="multiple"
        selectedKeys={selectedKeys}
        onSelectionChange={handleSelectionChange}
        aria-label="Role filter menu"
      >
        {orgRoles.length > 0 && (
          <MenuSection>
            <Header>Organization roles</Header>
            <Collection items={orgRoles}>
              {(role) => (
                <MenuItem textValue={role.name} aria-label={role.name}>
                  <Text slot="label">
                    <TextTruncator>{role.name}</TextTruncator>
                  </Text>
                </MenuItem>
              )}
            </Collection>
          </MenuSection>
        )}
        {legacyRoles.length > 0 && (
          <MenuSection>
            <Header>Legacy roles</Header>
            <Collection items={legacyRoles}>
              {(role) => (
                <MenuItem textValue={role.name} aria-label={role.name}>
                  <Text slot="label">
                    <TextTruncator>{`${role.name} (legacy)`}</TextTruncator>
                  </Text>
                </MenuItem>
              )}
            </Collection>
          </MenuSection>
        )}
        {allProjectAndCustomRoles.length > 0 && (
          <MenuSection>
            <Header>Project roles</Header>
            <Collection items={allProjectAndCustomRoles}>
              {(role) => (
                <MenuItem textValue={role.name} aria-label={role.name}>
                  <Text slot="label">
                    <TextTruncator>{role.name}</TextTruncator>
                  </Text>
                </MenuItem>
              )}
            </Collection>
          </MenuSection>
        )}
      </Menu>
      {enableCustomRoles && hasNoRoles && <Box>No additional roles found</Box>}
    </Dialog>
  );
}

function sortSelectedFirst(selectedKeys: Set<Key>, a: Key, b: Key) {
  const aSelected = selectedKeys.has(a);
  const bSelected = selectedKeys.has(b);
  return bSelected === aSelected ? 0 : bSelected ? 1 : -1;
}
