import {
  deleteCustomRole,
  getCustomRole,
  getCustomRoles,
  internalGetRolePresets,
  internalPatchRolePresets,
  patchCustomRole,
  postCustomRole,
} from '@gonfalon/openapi';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

export const getCustomRolesQuery = createQueryOptions(getCustomRoles);
export const getCustomRoleQuery = createQueryOptions(getCustomRole);
export const useCreateCustomRole = createMutationHook(postCustomRole);
export const usePatchCustomRole = createMutationHook(patchCustomRole);
export const useDeleteCustomRole = createMutationHook(deleteCustomRole);

export const getRolePresetsQuery = createQueryOptions(internalGetRolePresets);
export const usePatchRolePresets = createMutationHook(internalPatchRolePresets);
