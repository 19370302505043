import {
  getMetricEventDefinition,
  getMetricEventDefinitionInstances,
  getMetricEventInstances,
  getMetricEventsByEventKey,
  getMetricEventsByProject,
} from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';

export const getMetricEventDefinitionQuery = createQueryOptions(getMetricEventDefinition);

export const getMetricEventInstancesQuery = createQueryOptions(getMetricEventInstances);

export const getMetricEventsQuery = createQueryOptions(getMetricEventsByProject);

export const getMetricEventsByEventKeyQuery = createQueryOptions(getMetricEventsByEventKey);

export const getMetricEventDefinitionInstancesQuery = createQueryOptions(getMetricEventDefinitionInstances);
