import { type ReactNode } from 'react';
import { ErrorSeverity } from '@gonfalon/telemetry';

import { ErrorBoundary } from './ErrorBoundary';
import { GenericError } from './GenericError';

type NoisyErrorBoundaryProps = {
  children?: ReactNode;
  severity?: ErrorSeverity;
};

// `NoisyErrorBoundary` returns a prominent error message as a fallback. Useful as a wrapper for critical content.
// By default, this will be recorded as a "high" severity error in observability tools
export const NoisyErrorBoundary = ({ children, severity = 'high' }: NoisyErrorBoundaryProps) => (
  <ErrorBoundary FallbackComponent={GenericError} severity={severity}>
    {children}
  </ErrorBoundary>
);
