import { hasCustomRoles } from './hasCustomRoles';
import { hasTeamCustomRoles } from './hasTeamCustomRoles';
import { isAdmin } from './isAdmin';
import { isOwner } from './isOwner';
import { Member } from './types';

export function hasCreateMemberRights(baseMember: Member, member: Member) {
  // Owners and Admins can always invite members.
  if (isOwner(baseMember) || isAdmin(baseMember)) {
    return true;
  }

  // Members who aren't admins or owners will require custom roles to be able to invite new members.
  // If they don't have a custom role (custom member role or custom team role), they won't be able to invite members.
  if (!(hasCustomRoles(baseMember) || hasTeamCustomRoles(baseMember))) {
    return false;
  }

  // At this point in the logic, we'll need to evaluate the `_access` block, and so will require a member.
  // Although TypeScript requires a member, there are occasions in the compiled JS before the members list has loaded
  // where no member is passed in. We default to false.
  if (!member) {
    return false;
  }

  return !member._access?.denied.find((v) => v.action === 'createMember');
}
