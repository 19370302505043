import { ResourceSpecifier } from './ast';

const topLevelResources = [
  'acct',
  'application',
  'code-reference-repository',
  'domain-verification',
  'integration',
  'member',
  'proj',
  'relay-proxy-config',
  'role',
  'service-token',
  'pending-request',
  'team',
  'template',
  'token',
  'webhook',
] as const satisfies Array<ResourceSpecifier['type']>;

export type TopLevelResource = Extract<ResourceSpecifier, { type: (typeof topLevelResources)[number] }>;

export const isTopLevelResourceType = (resourceType: ResourceSpecifier['type']) =>
  (topLevelResources as readonly string[]).includes(resourceType);
