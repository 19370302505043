/* eslint-disable no-param-reassign */
import { type Writeable, createRecordModifier } from '@gonfalon/record-modifier';

import { Member, RoleAttributeMap } from './types';

const addCustomRoles = (roleKeys: string[]) => (member: Writeable<Member>) => {
  member.customRoles = Array.from(new Set([...member.customRoles, ...roleKeys]));
};

const removeCustomRole = (roleId: string) => (member: Writeable<Member>) => {
  member.customRoles = member.customRoles.filter((id) => id !== roleId);
};

const replaceRoleAttributes = (roleAttributes?: RoleAttributeMap) => (member: Writeable<Member>) => {
  if (roleAttributes) {
    member.roleAttributes = roleAttributes;
    return;
  }

  delete member.roleAttributes;
};

const setRole = (role: 'admin' | 'writer' | 'reader' | 'no_access' | 'custom') => (member: Writeable<Member>) => {
  member.role = role;
};

export const memberModifier = createRecordModifier({
  addCustomRoles,
  removeCustomRole,
  replaceRoleAttributes,
  setRole,
});
