import { deleteFlagLink } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getFlagLinksQuery } from './flags';

export const useDeleteFlagLink = createMutationHook(deleteFlagLink, {
  onSuccess: async (data, { projectKey, featureFlagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: getFlagLinksQuery({ projectKey, featureFlagKey }).queryKey,
    });
  },
});
