import { getRolePresetsQuery } from '@gonfalon/rest-api';
import { pluralize } from '@gonfalon/strings';
import { Alert } from '@launchpad-ui/components';
import { useQuery } from '@tanstack/react-query';

import { UpdateOOTBRolesModal } from './UpdateOOTBRolesModal';

import styles from './UpdateOOTBRolesAlert.module.css';

export const UpdateOOTBRolesAlert = () => {
  const { data: rolePresets } = useQuery(
    getRolePresetsQuery({
      query: {
        expand: 'roleTemplates',
      },
    }),
  );

  // Only show alert if there's an available update
  if (!rolePresets?.availableUpdate) {
    return null;
  }

  const availableUpdatesCount = rolePresets.availableUpdate.roleTemplates?.length || 0;

  return (
    <Alert status="info" className={styles.alert}>
      <div className={styles.alertContent}>
        <h3>Updates to predefined {pluralize('role', availableUpdatesCount)}</h3>
        <p>
          The role policy for {availableUpdatesCount} predefined LaunchDarkly {pluralize('role', availableUpdatesCount)}{' '}
          have been updated. View the changes before rolling out this update across members in your organization.
        </p>
      </div>
      <div className={styles.alertActions}>
        <UpdateOOTBRolesModal rolePresets={rolePresets} />
      </div>
    </Alert>
  );
};
