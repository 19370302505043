import { CustomRole, RoleResourceCategory } from './internal/types';
import { isOrganizationRole, isProjectRole } from './isRoleCategory';

export function getRoleCategory(role: CustomRole): RoleResourceCategory {
  if (isProjectRole(role)) {
    return RoleResourceCategory.PROJECT;
  }

  if (isOrganizationRole(role)) {
    return RoleResourceCategory.ORGANIZATION;
  }

  return RoleResourceCategory.ANY;
}
