import { and, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetMetricGroupsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export type GetMetricGroupsQueryParams = Omit<GetMetricGroupsOpenAPIQueryParams, 'filter' | 'expand'> & {
  expand?: Array<'experiments' | 'experimentCount'>;
  filter?: { experimentStatus?: string; query?: string; kind?: string };
};

export function querySerializer(queryParams: GetMetricGroupsQueryParams) {
  const searchParams = new URLSearchParams();

  if ((queryParams.limit ?? 0) > 0) {
    searchParams.set('limit', String(queryParams.limit));
  }

  if ((queryParams.offset ?? 0) > 0) {
    searchParams.set('offset', String(queryParams.offset));
  }

  if (queryParams.sort) {
    searchParams.set('sort', String(queryParams.sort));
  }

  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.filter) {
    const serializedFilter = serializeMetricGroupFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

export function serializeMetricGroupFilterParam(value: NonNullable<GetMetricGroupsQueryParams['filter']>) {
  const filter: QueryFilter[] = [];

  if (value.experimentStatus) {
    filter.push(equals('experimentStatus', value.experimentStatus));
  }

  if (value.query) {
    filter.push(equals('query', value.query));
  }

  if (value.kind) {
    filter.push(equals('kind', value.kind));
  }

  return queryfilterToString(and(filter));
}
