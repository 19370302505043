import { GetMetricOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetMetricQueryParams extends Omit<GetMetricOpenAPIQueryParams, 'expand'> {
  expand?: Array<
    | 'experiments'
    | 'experimentCount'
    | 'metricGroups'
    | 'metricGroupCount'
    | 'eventSources'
    | 'guardedRollouts'
    | 'guardedRolloutCount'
  >;
}

export function querySerializer(queryParams: GetMetricQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.versionId && queryParams.versionId !== '') {
    searchParams.set('versionId', queryParams.versionId);
  }

  return searchParams.toString();
}
