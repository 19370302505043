export enum TeamInstructionKind {
  ADD_ROLE_ATTRIBUTE = 'addRoleAttribute',
  UPDATE_ROLE_ATTRIBUTE = 'updateRoleAttribute',
  REMOVE_ROLE_ATTRIBUTE = 'removeRoleAttribute',
  ADD_CUSTOM_ROLES = 'addCustomRoles',
  REMOVE_CUSTOM_ROLES = 'removeCustomRoles',
  UPDATE_NAME = 'updateName',
  UPDATE_DESCRIPTION = 'updateDescription',
}

/**
 * Role attribute instructions
 */
export type AddRoleAttributeInstruction = {
  kind: TeamInstructionKind.ADD_ROLE_ATTRIBUTE;
  key: string;
  values: string[];
};

export type UpdateRoleAttributeInstruction = {
  kind: TeamInstructionKind.UPDATE_ROLE_ATTRIBUTE;
  key: string;
  values: string[];
};

export type RemoveRoleAttributeInstruction = {
  kind: TeamInstructionKind.REMOVE_ROLE_ATTRIBUTE;
  key: string;
};

export type RoleAttributeInstruction =
  | AddRoleAttributeInstruction
  | UpdateRoleAttributeInstruction
  | RemoveRoleAttributeInstruction;

/**
 * Custom role instructions
 */
export type AddCustomRolesInstruction = {
  kind: TeamInstructionKind.ADD_CUSTOM_ROLES;
  values: string[]; // role keys
};

export type RemoveCustomRolesInstruction = {
  kind: TeamInstructionKind.REMOVE_CUSTOM_ROLES;
  values: string[]; // role keys
};

export type CustomRoleInstruction = AddCustomRolesInstruction | RemoveCustomRolesInstruction;

export type UpdateNameInstruction = {
  kind: TeamInstructionKind.UPDATE_NAME;
  value: string;
};

export type UpdateDescriptionInstruction = {
  kind: TeamInstructionKind.UPDATE_DESCRIPTION;
  value: string;
};

/**
 * All team instructions
 */
export type TeamInstruction =
  | RoleAttributeInstruction
  | CustomRoleInstruction
  | UpdateNameInstruction
  | UpdateDescriptionInstruction; // union more instructions here;

// todo ktlo - add the rest of the instructions
