/**
 * Resize Observer Tracker
 *
 * A wrapper around resize observers that parses
 * the desired entry and only emits changes when
 * the width of height of the desired entry is
 * different than the previous reference
 */
export class ResizeObserverTracker {
  private width = -1;
  private height = -1;
  public box: ResizeObserverBoxOptions;
  public onResize: (dimensions: Dimensions) => void;
  constructor(box: ResizeObserverBoxOptions, onResize: (dimensions: Dimensions) => void) {
    this.box = box;
    this.onResize = onResize;
  }

  public diff(entryList: ResizeObserverEntry) {
    let emit = false;
    const entries = this.parseBox(entryList);
    for (const entry of entries) {
      if (entry.inlineSize !== this.width) {
        this.width = entry.inlineSize;
        emit = true;
      }
      if (entry.blockSize !== this.height) {
        this.height = entry.blockSize;
        emit = true;
      }
      if (emit) {
        return this.onResize({ width: this.width, height: this.height });
      }
    }
  }

  public destroy() {
    this.width = -1;
    this.height = -1;
  }

  private parseBox(entry: ResizeObserverEntry) {
    if (this.box === 'border-box') {
      return entry.borderBoxSize;
    }
    if (this.box === 'content-box') {
      return entry.contentBoxSize;
    }
    if (this.box === 'device-pixel-content-box') {
      return entry.devicePixelContentBoxSize;
    }
    return [];
  }
}

export interface Dimensions {
  width: number;
  height: number;
}
