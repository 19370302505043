import {
  getAccountSubscription,
  getBillingPlans,
  getLimitPlans,
  getSubscription,
  getSubscriptionUsage,
} from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';

export const getBillingPlansQuery = createQueryOptions(getBillingPlans);
export const getLimitPlansQuery = createQueryOptions(getLimitPlans);

export const getSubscriptionQuery = createQueryOptions(getSubscription);
export const getSubscriptionUsageQuery = createQueryOptions(getSubscriptionUsage);
export const getLegacySubscriptionQuery = createQueryOptions(getAccountSubscription);
