import { ReactNode, useState } from 'react';
import { frontendQueryCachePersistence, isReactQueryDevtoolsEnabled } from '@gonfalon/dogfood-flags';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { createPersistenceConfiguration } from './persistence/createPersistenceConfiguration';
import { getQueryClient } from './getQueryClient';

declare global {
  /* eslint-disable no-var, @typescript-eslint/naming-convention */
  var __DEV__: boolean;
  var __TEST__: boolean;
  /* eslint-enable no-var, @typescript-eslint/naming-convention */
}

// Renders the react-query icon in the bottom left of your browser.
// Clicking it opens the dev tools so you can inspect your cache and queries.
// https://react-query.tanstack.com/devtools
const renderDevTools = () => {
  if (__DEV__ && isReactQueryDevtoolsEnabled()) {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }

  return null;
};

export function ReactQueryClientProvider({ children }: { children: ReactNode }) {
  const strategy = frontendQueryCachePersistence();

  const client = getQueryClient();
  const defaultClientOptions = client.getDefaultOptions() ?? {};
  const [persistOptions] = useState(
    strategy !== 'disabled'
      ? createPersistenceConfiguration({ strategy, gcTime: defaultClientOptions.queries?.gcTime })
      : undefined,
  );

  if (persistOptions === undefined) {
    return (
      <QueryClientProvider client={client}>
        {children}
        {renderDevTools()}
      </QueryClientProvider>
    );
  }

  return (
    <PersistQueryClientProvider client={client} persistOptions={persistOptions}>
      {children}
      {renderDevTools()}
    </PersistQueryClientProvider>
  );
}
