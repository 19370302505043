import { deleteFeaturePreviewEnrollments } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { featurePreviews } from './internal/queries';

export const useWithdrawFromFeaturePreview = createMutationHook(deleteFeaturePreviewEnrollments, {
  onSuccess: (data) => {
    const queryClient = getQueryClient();
    queryClient.setQueryData(featurePreviews.list.queryKey, data);
  },
});
