import { updatePhaseStatus } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getReleaseByFlagKeyQuery } from './flags';

export const useUpdateReleasePhase = createMutationHook(updatePhaseStatus, {
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getReleaseByFlagKeyQuery({ projectKey, flagKey }).queryKey });
  },
});
