import {
  createAnnouncement,
  createAnnouncementPublic,
  deleteAnnouncement,
  deleteAnnouncementPublic,
  getAnnouncements,
  getAnnouncementsPublic,
  updateAnnouncement,
  updateAnnouncementPublic,
} from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

export const announcementsQuery = createQueryOptions(getAnnouncements);

export const createAnnouncementMutation = createMutationHook(createAnnouncement, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: announcementsQuery.partialQueryKey() });
  },
});

export const updateAnnouncementMutation = createMutationHook(updateAnnouncement, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: announcementsQuery.partialQueryKey() });
  },
});

export const deleteAnnouncementMutation = createMutationHook(deleteAnnouncement, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: announcementsQuery.partialQueryKey() });
  },
});

export const announcementsQueryPublic = createQueryOptions(getAnnouncementsPublic);

export const createAnnouncementMutationPublic = createMutationHook(createAnnouncementPublic, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: announcementsQueryPublic.partialQueryKey() });
  },
});

export const updateAnnouncementMutationPublic = createMutationHook(updateAnnouncementPublic, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: announcementsQueryPublic.partialQueryKey() });
  },
});

export const deleteAnnouncementMutationPublic = createMutationHook(deleteAnnouncementPublic, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: announcementsQueryPublic.partialQueryKey() });
  },
});
