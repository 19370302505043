import { GetCustomRolesOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

// paginated
export const apiVersion = '20240415';

export type ResourceCategory = 'any' | 'organization' | 'project';

export type GetCustomRolesQueryParams = GetCustomRolesOpenAPIQueryParams & {
  filter?: {
    query?: string;
    resourceCategory?: ResourceCategory[];
  };
  // supports only one expand for now, but we can add more if needed
  expand?: Array<'assignedTo'>;
};

export function querySerializer(queryParams: GetCustomRolesQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  // only available on API version '20240415' and above
  if (queryParams.filter && Object.values(queryParams.filter ?? {}).some(Boolean)) {
    searchParams.set('filter', serializeCustomRolesFilterParam(queryParams.filter));
  }

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}

export function serializeCustomRolesFilterParam(value: NonNullable<GetCustomRolesQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.resourceCategory) {
    value.resourceCategory.forEach((resourceCategory) => {
      filter.push(`resourceCategory:${resourceCategory}`);
    });
  }

  filter.sort();

  return filter.join(',');
}
