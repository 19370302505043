import { putFlagMeasuredRolloutConfiguration } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getFlagMeasuredRolloutConfigurationQuery } from './flags';

export const useUpdateFlagMeasuredRolloutConfiguration = createMutationHook(putFlagMeasuredRolloutConfiguration, {
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: getFlagMeasuredRolloutConfigurationQuery({ projectKey, flagKey }).queryKey,
    });
  },
});
