import { ErrorSeverity } from '@gonfalon/telemetry';

import { ErrorBoundary, ErrorBoundaryProps } from './ErrorBoundary';
import { QuietError } from './QuietError';

type QuietErrorBoundaryProps = Omit<
  ErrorBoundaryProps,
  'FallbackComponent' | 'fallback' | 'fallbackRender' | 'severity'
> & {
  severity?: ErrorSeverity;
};

// `QuietErrorBoundary` will silently fail (return `null` as a fallback) by default in production.
// Useful as a wrapper for non-critical components or in places where a prominent error display doesn't make sense.
// If you'd like to provide your own fallback, use `ErrorBoundary` directly.
// By default, this will be recorded as a "medium" severity error in observability tools
export const QuietErrorBoundary = ({ children, severity = 'medium', ...additionalProps }: QuietErrorBoundaryProps) => (
  <ErrorBoundary {...additionalProps} FallbackComponent={QuietError} severity={severity}>
    {children}
  </ErrorBoundary>
);
