import { type ReactNode, ForwardedRef, forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';

import styles from './TextTruncator.module.css';

type Props = { asChild?: boolean; children?: ReactNode; role?: string };

export const TextTruncator = forwardRef(({ asChild, ...props }: Props, ref: ForwardedRef<HTMLElement>) => {
  const Comp = asChild ? Slot : 'span';
  return <Comp ref={ref} {...props} className={styles.truncate} />;
});
