import { FallbackProps } from 'react-error-boundary';
import { Button } from '@launchpad-ui/components';
/*
  * This is a custom error boundary fallback component that allows the user to reset the error boundary and try again.
  * It is intended to be used as a `FallbackComponent` in an `ErrorBoundary`.
  * @example
  * ```tsx
  * <ErrorBoundary severity="medium" FallbackComponent={RetryFallback}>
  *   <MyComponent />
  * </ErrorBoundary>
  * ```

 */
export const RetryFallback = ({ resetErrorBoundary }: FallbackProps) => (
  <div>
    Something went wrong.{' '}
    <Button size="small" onPress={() => resetErrorBoundary()}>
      Try again
    </Button>
  </div>
);
