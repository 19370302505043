import { patchFeatureFlag } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getFeatureFlagQuery, getFeatureFlagsQuery, getReleaseByFlagKeyQuery } from './flags';

export const useUpdateFlag = createMutationHook(patchFeatureFlag, {
  onSuccess: async (data, { projectKey, featureFlagKey: flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getFeatureFlagsQuery({ projectKey }).queryKey });
    await queryClient.invalidateQueries({
      queryKey: getFeatureFlagQuery({ projectKey, featureFlagKey: flagKey }).queryKey,
    });

    // since the request can return a 404 error, resetting the query rather than invalidating it actually updates the UI
    await queryClient.resetQueries({ queryKey: getReleaseByFlagKeyQuery({ projectKey, flagKey }).queryKey });
  },
});
