import { getMetric, GetMetricQueryParams, getMetrics } from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';

export const getMetricsQuery = createQueryOptions(getMetrics);
export const getMetricQuery = createQueryOptions(getMetric);

export const getMetricDetailsQuery = ({
  projectKey,
  metricKey,
  query = {
    expand: [
      'metricGroups',
      'experiments',
      'experimentCount',
      'metricGroupCount',
      'eventSources',
      'guardedRollouts',
      'guardedRolloutCount',
    ],
  },
}: {
  projectKey: string;
  metricKey: string;
  query?: GetMetricQueryParams;
}) => {
  return getMetricQuery({
    projectKey,
    metricKey,
    query,
  });
};
