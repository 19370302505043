import { curry, get, kebabCase } from '@gonfalon/es6-utils';

import { capitalizedWordRegexp, keyRegexp } from './patterns';
import { stringContainsEmoji } from './stringContainsEmoji';
import { stripEmojisFromString } from './stripEmojisFromString';

function simplePredicateFor(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorFn: (v: any, obj?: { [key: string]: any }) => boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageFn: (v: any, propName?: string, obj?: { [key: string]: any }) => string,
  prop: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: { [key: string]: any },
) {
  const value = get(object, prop, null);
  const failed = errorFn.length > 1 ? errorFn(value, object) : errorFn(value);

  if (failed) {
    return { for: prop, errors: [messageFn(value, prop, object)] };
  }
}

export const predicateFor = curry(simplePredicateFor);

/*
  Used to auto-convert entity names to valid keys.

  Names that are valid keys will be left alone.
   - One exception is that names that are capitalized single words, eg `Test`, will be lowercased
  Names that are not valid keys will be kebab-cased, which lowercases and sets spaces and special chars to `-`
 */

export const convertToKey = (input: string) => {
  const trimmed = (input || '').trim();
  if (!keyRegexp.test(trimmed)) {
    if (stringContainsEmoji(trimmed)) {
      const stripEmoji = stripEmojisFromString(trimmed);
      return kebabCase(stripEmoji);
    }
    return kebabCase(trimmed);
  }
  if (capitalizedWordRegexp.test(trimmed)) {
    return trimmed.toLowerCase();
  }

  return trimmed;
};
