import { IDBCache } from '@instructure/idb-cache';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';

export function createIndexedDBPersister({ cacheKey, buster }: { cacheKey: string; buster: string }) {
  const idbCache = new IDBCache({
    cacheKey,
    cacheBuster: buster,
  });

  return createAsyncStoragePersister({
    storage: idbCache,
  });
}
