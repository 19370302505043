import { ReactNode, useMemo } from 'react';
import classNames from 'clsx';
import { Change, diffJson, diffLines, diffWords } from 'diff';

import styles from './Diff.module.css';

type DiffViewProps = {
  original: string;
  compare: string;
  showLineNumbers?: boolean;
  includeSubDiffs?: boolean;
  title?: string;
  isJSON?: boolean;
  className?: string;
};

type ElementState = 'added' | 'removed' | 'unchanged';

const elementClassNames: Record<ElementState, string> = {
  added: styles.added,
  removed: styles.removed,
  unchanged: styles.unchanged,
};

const elementDataAttributes: Record<ElementState, string> = {
  added: 'line-added',
  removed: 'line-removed',
  unchanged: 'line-unchanged',
};

function renderDiff(
  diff: Change[],
  showLineNumbers: boolean,
  includeSubDiffs: boolean,
  elements: React.ReactNode[] = [],
  lineNumber: number = 1,
  prevItem: Change | null = null,
) {
  const [current, ...rest] = diff;
  const elementState: ElementState = current.added ? 'added' : current.removed ? 'removed' : 'unchanged';
  const next = rest[0];
  // Capture the value as the inner element; some processes below may modify this
  // to contain additional elements
  let innerElements: ReactNode[] = [current.value];
  // Handle special cases where we need to render inner diffs for added/removed lines
  if (current.added && prevItem?.removed && includeSubDiffs) {
    // Create an inner diff of the words that have changed and render those out.
    // We're already operating per-line so we can break down the line into words without affecting
    // the structure of the rest of the diff
    const innerDiff = diffWords(prevItem.value, current.value);
    innerElements = innerDiff.map((change) => {
      if (change.added) {
        return (
          <span className={styles.addedInner} key={change.value} data-line-added-inner>
            {change.value}
          </span>
        );
      }
      // Skip removed since this is an "added" line
      if (change.removed) {
        return null;
      }

      return (
        <span className={styles.unchangedInner} key={change.value}>
          {change.value}
        </span>
      );
    });
  }

  if (current.removed && next?.added && includeSubDiffs) {
    const innerDiff = diffWords(current.value, next.value);
    innerElements = innerDiff.map((change) => {
      if (change.removed) {
        return (
          <span key={change.value} className={styles.removedInner} data-line-removed-inner>
            {change.value}
          </span>
        );
      }

      // Skip added since this is a "removed" line
      if (change.added) {
        return null;
      }

      return (
        <span className={styles.unchangedInner} key={change.value}>
          {change.value}
        </span>
      );
    });
  }

  // Special case for empty lines to preserve white-space passed in by the implementer
  if (current.value.trim() === '') {
    innerElements = [<br key={current.value + lineNumber} />];
  }

  const elementClassName = elementClassNames[elementState];
  const dataAttribute = elementDataAttributes[elementState];

  elements.push(
    <div key={current.value + lineNumber} className={elementClassName} {...{ [`data-${dataAttribute}`]: true }}>
      {showLineNumbers && (
        <div data-line-number className={styles.lineNumber}>
          {lineNumber}
        </div>
      )}

      {showLineNumbers && elementState === 'unchanged' && (
        <div data-line-number className={`${styles.lineNumber} ${styles.lineNumberUnchanged}`}>
          {lineNumber}
        </div>
      )}

      {innerElements}
    </div>,
  );

  if (!next) {
    return elements;
  } else {
    // If we had a removal don't increment the line number if it's followed by an addition so that we
    // get N for both line numbers rather than N and N+1
    const nextLineNumber = current.removed && next.added ? lineNumber : lineNumber + 1;

    return renderDiff(rest, showLineNumbers, includeSubDiffs, elements, nextLineNumber, current);
  }
}
// Convenience method for stringifying objects for diffing in the format they need
// to display well
export function stringifyForDiff(obj: object) {
  return JSON.stringify(obj, null, 2);
}

/*
 * Breaks the content down by newline and diffs each line individually.
 * This approach achieves a couple of things:
 *  - It allows us to track which line we're on; we don't need to do any shenanigans to track the line number.
 *  - The diffs it generates are better
 *  - It allows us to sub-process on lines, e.g. if we want to highlight the specific words that have changed
 */
const diffByLine = (original: string, compare: string) => {
  // Split the content into lines, then determine which is longer as that will
  // determine what we need to map over
  const originalLines = original.split('\n');
  const compareLines = compare.split('\n');
  const isOriginalLonger = originalLines.length > compareLines.length;
  // Capture the longer/shorter array as that'll determine which order we pass the vars in
  // for the diff function
  const [longer, other] = isOriginalLonger ? [originalLines, compareLines] : [compareLines, originalLines];

  return (
    longer
      .map((longerLine, idx) => {
        // Get the other corresponding line or '' to show that we're at the end of the shorter array
        const otherLine = other[idx] ?? '';
        // Determine which order we pass the vars in for the diff function (we want the older line first)
        const args: [string, string] = [
          isOriginalLonger ? longerLine : otherLine,
          isOriginalLonger ? otherLine : longerLine,
        ];

        return diffLines(...args);
      })
      // Flatten the array of diffs into a single array as they'll be Change[][]
      .flatMap((v) => v)
  );
};

// Using the diffJson function results in a ~ significantly ~ better diff, but it doesn't
// split unchanged sections into multiple lines. We'll do a little post-processing here
// to ensure that unchanged sections are split across multiple lines so that our line numbering
// can function as expected when line numbers are required
const diffJSONByLine = (original: string, compare: string) => {
  const diff = diffJson(original, compare);
  // Map through the diff and split unchanged lines into multiple Change objects that'll
  // be flattened by flatMap() into a Change[]
  const unchangedSplitByLine = diff
    .flatMap((change) => {
      if (change.added || change.removed) {
        return change;
      }
      // split the unchanged line into multiple Change objects
      const unchangedLines = change.value.split('\n');
      return unchangedLines.map((line) => ({ ...change, value: line }));
    })
    .filter((change) => change.value.trim());

  return unchangedSplitByLine;
};

export function Diff({
  original,
  compare,
  showLineNumbers = true,
  title,
  includeSubDiffs = false,
  isJSON = false,
  className,
}: DiffViewProps) {
  const isSameDocument = useMemo(() => original === compare, [original, compare]);

  const diff = useMemo(() => {
    if (isJSON) {
      if (showLineNumbers) {
        return diffJSONByLine(original, compare);
      }

      return diffJson(original, compare);
    }

    return diffByLine(original, compare);
  }, [original, compare, isJSON, showLineNumbers]);

  return (
    <div className={classNames(styles.diffView, { [styles.diffViewSameDoc]: isSameDocument })}>
      <div className={styles.controls}>{title && <div className={styles.title}>{title}</div>}</div>

      <div className={classNames(styles.diff, className)} data-test-id="diff-container">
        {renderDiff(diff, showLineNumbers, includeSubDiffs)}
      </div>
    </div>
  );
}
