/* eslint-disable no-console */
import { frontendLogLevel } from '@gonfalon/dogfood-flags';
import { padEnd } from '@gonfalon/es6-utils';
import { capitalize } from '@gonfalon/strings';
import { format } from 'date-fns';
type LogLevel = 'debug' | 'log' | 'warn' | 'error';

const LEVELS: { [key: string]: number } = { debug: 10, log: 20, warn: 30, error: 40 };
const frontendLogLevelValue = () => frontendLogLevel();
const catfoodLogLevel = () => LEVELS[frontendLogLevelValue()];

/* eslint-disable import/no-default-export */
export default class Logger {
  constructor(module: string) {
    this.module = module || 'gonfalon';

    this.debug = this.debug.bind(this);
    this.log = this.log.bind(this);
    this.warn = this.warn.bind(this);
    this.error = this.error.bind(this);
    this.consoleWriter = this.consoleWriter.bind(this);
  }

  module: string;

  static get(module: string) {
    return new Logger(module);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consoleWriter(level: LogLevel, message: string, ...context: any) {
    if (LEVELS[level] >= catfoodLogLevel()) {
      if (__DEV__ || __TEST__) {
        const dateTime = format(new Date(), 'MM-dd-yyyy hh:mm:ss:sss');
        const formattedLevel = padEnd(capitalize(level), 5);
        const formattedMessage = `%c${dateTime} ${formattedLevel} [${this.module}] ${message}`;
        let css = 'font-size: 12px;';

        switch (level) {
          case 'debug':
            css += 'color: navy';
            break;
          case 'log':
            css += 'color: green';
            break;
          default:
            css = 'font-size: 14px';
            break;
        }

        console[level](formattedMessage, css, ...context);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message: string, ...context: any): void {
    this.consoleWriter('debug', message, ...context);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(message: string, ...context: any): void {
    this.consoleWriter('log', message, ...context);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message: string, ...context: any): void {
    this.consoleWriter('warn', message, ...context);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: string, ...context: any): void {
    this.consoleWriter('error', message, ...context);
  }
}
