import { Suspense, useState, useTransition } from 'react';
import { DialogTrigger, Key } from 'react-aria-components';
import { useDebounce } from '@gonfalon/async';
import { ErrorBoundary } from '@gonfalon/error-boundaries';
import { pluralize } from '@gonfalon/strings';
import { Alert, Button, ButtonGroup, IconButton, Popover, ProgressBar } from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import { RoleFilterDialog } from './RoleFilterDialog';

import styles from './RoleFilter.module.css';

export type RoleFilterProps = {
  selectedKeys: Set<Key>;
  onSelectionChange: (selectedKeys: Set<Key>) => void;
};

export function RoleFilter({ selectedKeys, onSelectionChange }: RoleFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isTransitioning, startTransition] = useTransition();

  const handleSearch = (val: string) => {
    startTransition(() => {
      setSearch(val);
    });
  };
  const handleSearchDebounced = useDebounce(handleSearch, 300);

  const buttonText =
    selectedKeys.size > 0 ? `${selectedKeys.size} ${pluralize('role', selectedKeys.size)}` : 'Role: All';

  const handleClearFilter = () => {
    setSearch('');
    onSelectionChange(new Set());
  };

  const handleOpenChange = (nextIsOpen: boolean) => {
    setIsOpen(nextIsOpen);
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      <ButtonGroup className={cx(styles.buttonGroup, { [styles.active]: selectedKeys.size > 0 })}>
        <Button aria-label={buttonText} onPress={() => handleOpenChange(!isOpen)} className={styles.button}>
          {buttonText} <Icon name="chevron-down" size="small" />
        </Button>
        {selectedKeys.size > 0 && (
          <IconButton
            icon="cancel"
            size="small"
            aria-label="Clear filter"
            onPress={handleClearFilter}
            className={styles.clearButton}
          />
        )}
      </ButtonGroup>
      <Popover>
        <ErrorBoundary
          severity="medium"
          fallbackRender={() => (
            <Alert status="error" variant="inline">
              An error occurred. Our team has been notified.
            </Alert>
          )}
        >
          <Suspense
            fallback={
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
              </Box>
            }
          >
            <RoleFilterDialog
              search={search}
              handleSearch={handleSearchDebounced}
              selectedKeys={selectedKeys}
              onSelectionChange={onSelectionChange}
              isTransitioning={isTransitioning}
            />
          </Suspense>
        </ErrorBoundary>
      </Popover>
    </DialogTrigger>
  );
}
