/**
 * TODO: this is used to automatically clear a user's cache
 *
 * Should it be…
 * - frontend version?
 *  - too aggressive: that would clear the cache on every frontend deploy
 * - user/member id?
 *
 * Other things to consider:
 * - clear the cache when the user logs out
 */
export function createCacheBuster() {
  return 'launchdarkly-temporary-cache-buster';
}
