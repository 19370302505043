import { type JSX, lazy, Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, useLocation } from 'react-router';
import { enableCheckYourEmailEndpoint, enableDomainMatching } from '@gonfalon/dogfood-flags';
import { NoisyErrorBoundary } from '@gonfalon/error-boundaries';
import { toLogin } from '@gonfalon/navigator';
import { useMatchingUnauthenticatedPathPattern } from '@gonfalon/router';
import { setRouteContext } from '@gonfalon/telemetry';
import { ProgressBar, SnackbarRegion } from '@launchpad-ui/components';
import { unauthenticatedRouteOwnership } from 'routes/unauthenticatedRouteOwnership';

import { updateLocation } from 'actions/router';
import LoginContainer from 'components/loginAndSignUp/LoginContainer';
import { useDispatch } from 'hooks/useDispatch';

const AcceptInvitation = lazy(
  async () => import(/* webpackChunkName: "AcceptInvitation" */ 'components/loginAndSignUp/AcceptInvitation'),
);

const FacilitatedTrialSignupContainer = lazy(async () =>
  import(
    /* webpackChunkName: "FacilitatedTrialSignupContainer" */ 'components/loginAndSignUp/FacilitatedTrialSignupContainer'
  ).then((module) => ({
    default: module.Component,
  })),
);

const LazyTrialLimitContainer = lazy(
  async () => import(/* webpackChunkName: "LazyTrialLimitContainer" */ 'components/loginAndSignUp/TrialLimitContainer'),
);

const ChooseOrganizationPasswordFormContainer = lazy(
  async () =>
    import(
      /* webpackChunkName: "ChooseOrganizationPasswordFormContainer" */ 'components/loginAndSignUp/ChooseOrganizationPasswordFormContainer'
    ),
);

const ChooseOrganizationContainer = lazy(
  async () =>
    import(
      /* webpackChunkName: "ChooseOrganizationContainer" */ 'components/loginAndSignUp/ChooseOrganizationContainer'
    ),
);
const CreateOrJoinContainer = lazy(
  async () => import(/* webpackChunkName: "CreateOrJoinContainer" */ 'components/loginAndSignUp/CreateOrJoinContainer'),
);

const CreateOrJoinSwitcher = lazy(
  async () => import(/* webpackChunkName: "CreateOrJoinSwitcher" */ 'components/loginAndSignUp/CreateOrJoinSwitcher'),
);

const ForgotPasswordContainer = lazy(
  async () =>
    import(/* webpackChunkName: "ForgotPasswordContainer" */ 'components/loginAndSignUp/ForgotPasswordContainer'),
);
const ResetPasswordContainer = lazy(
  async () => import(/* webpackChunkName: "PasswordContainer" */ 'components/loginAndSignUp/ResetPasswordContainer'),
);

const OAuthContainer = lazy(
  async () => import(/* webpackChunkName: "OAuthContainer" */ 'components/loginAndSignUp/OAuthContainer'),
);

const JoinYourOrganization = lazy(
  async () => import(/* webpackChunkName: "JoinYourOrganization" */ 'components/loginAndSignUp/JoinYourOrganization'),
);

const TrustThisDeviceContainer = lazy(
  async () =>
    import(/* webpackChunkName: "TrustThisDeviceContainer" */ 'components/loginAndSignUp/TrustThisDeviceContainer'),
);

const UnauthenticatedEmailVerificationContainer = lazy(
  async () =>
    import(
      /* webpackChunkName: "UnauthenticatedEmailVerificationContainer" */ 'components/loginAndSignUp/UnauthenticatedEmailVerification/UnauthenticatedEmailVerificationContainer'
    ),
);

const Welcome = lazy(async () =>
  import(/* webpackChunkName: "Welcome" */ 'components/loginAndSignUp/welcome').then((module) => ({
    default: module.Welcome,
  })),
);

const isDomainMatchingEnabled = enableDomainMatching();

function UnauthenticatedApp(props: { routes: JSX.Element }) {
  const matchingPathPattern = useMatchingUnauthenticatedPathPattern(props.routes);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(updateLocation(location));
  }, [dispatch, location]);

  useEffect(() => {
    if (matchingPathPattern) {
      const owner = unauthenticatedRouteOwnership[matchingPathPattern];
      setRouteContext(matchingPathPattern, owner);
    }
  }, [matchingPathPattern]);

  return (
    <>
      <NoisyErrorBoundary severity="critical">
        <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate />}>
          <Outlet />
        </Suspense>

        <SnackbarRegion />
      </NoisyErrorBoundary>
    </>
  );
}

export function unauthenticatedRoutes() {
  const routes = (
    <>
      <Route path="/choose-organization/:email" element={<ChooseOrganizationContainer />} />

      <Route
        path="/choose-organization/:email/:selectedOrganization/enter-password"
        element={<ChooseOrganizationPasswordFormContainer />}
      />
      <Route path="/choose-organization/:selectedOrganization/forgot" element={<ForgotPasswordContainer />} />

      <Route path="/choose-organization/verify/:token" element={<TrustThisDeviceContainer />} />

      <Route path="/join-your-organization/:token" element={<JoinYourOrganization />} />
      <Route path="/welcome/:token" element={<Welcome />} />
      <Route path="/signup/:token" element={<FacilitatedTrialSignupContainer />} />
      <Route element={<CreateOrJoinContainer />}>
        <Route path="/signup" element={<CreateOrJoinSwitcher isSignup />} />
        {/* disable the /join route and redirect to signup if domain matching is enabled */}
        <Route path="/join" element={isDomainMatchingEnabled ? null : <CreateOrJoinSwitcher />} />
      </Route>

      {/* "Forgot password" is the view where the user can enter their email and get a reset link emailed to them */}
      <Route path="/forgot" element={<ForgotPasswordContainer />} />
      {/* "Reset password" is the view where the user can enter in a new password */}
      <Route path="/reset/:passwordResetToken" element={<ResetPasswordContainer />} />
      <Route path="/invite/:invitationToken" element={<AcceptInvitation />} />
      <Route path="/oauth/google" element={<OAuthContainer />} />
      <Route path="/oauth/github" element={<OAuthContainer />} />
      <Route path="/login" element={<LoginContainer />} />
      <Route path="/trial-limit" element={<LazyTrialLimitContainer />} />

      {enableCheckYourEmailEndpoint() ? (
        <>
          <Route path="/check-your-email" element={<UnauthenticatedEmailVerificationContainer />} />
          <Route
            path="/choose-organization/check-your-email"
            element={<UnauthenticatedEmailVerificationContainer isChooseOrg />}
          />
        </>
      ) : (
        <>
          <Route path="/verify-email/:email" element={<UnauthenticatedEmailVerificationContainer />} />
          <Route
            path="/choose-organization/verify-email/:email"
            element={<UnauthenticatedEmailVerificationContainer isChooseOrg />}
          />
        </>
      )}

      {/*
        If we're rendering unauth'd routes for anything else, it's likely that
        the user requested a valid route but isn't logged in. Redirect to the
        login route and pass the requested route along, the login container will
        find this `redirect` param and forward the user along after they log in
        */}
      <Route path="*" element={<NavigateToLogin />} />
    </>
  );

  // We need a static list of routes so that useMatchingPathPattern can inspect them, and a Router context which this
  // wrapping layout route provides
  return (
    <Route
      element={<UnauthenticatedApp routes={routes} />}
      hydrateFallbackElement={<ProgressBar aria-label="Loading…" isIndeterminate />}
    >
      {routes}
    </Route>
  );
}

function NavigateToLogin() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  searchParams.set('redirect', location.pathname);
  return <Navigate to={toLogin({ search: searchParams.toString() })} />;
}
