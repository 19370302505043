import { composeRenderProps } from 'react-aria-components';
import {
  type SearchFieldProps,
  FieldError,
  Group,
  IconButton,
  Input,
  Label,
  ProgressBar,
  SearchField as AriaSearchField,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import clsx from 'clsx';

import { Description } from './Description';

export type Props = SearchFieldProps & {
  label?: string;
  description?: string;
  placeholder?: string;
  errorMessage?: string;
  isBusy?: boolean;
};

export function SearchField({ placeholder, label, description, errorMessage, ...props }: Props) {
  return (
    <AriaSearchField
      {...props}
      slot="search"
      className={composeRenderProps(props.className, (className) => clsx(className))}
    >
      {label && <Label>{label}</Label>}
      <Group>
        <Icon name="search" size="small" />
        <Input placeholder={placeholder} />
        {props.isBusy && (
          <span>
            <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
          </span>
        )}
        <IconButton aria-label="Clear" icon="cancel-circle-outline" size="small" variant="minimal" />
      </Group>
      {description && <Description>{description}</Description>}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </AriaSearchField>
  );
}
