import { ComponentProps } from 'react';
import { enableQueryCacheMetaPersistHeuristic, frontendQueryCachePersistence } from '@gonfalon/dogfood-flags';
import { assertNever } from '@gonfalon/types';
import { QueryOptions } from '@tanstack/react-query';
import { Persister, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { createCacheBuster } from './createCacheBuster';
import { createCacheKey } from './createCacheKey';
import { createIndexedDBPersister } from './createIndexedDBPersister';
import { createLocalStoragePersister } from './createLocalStoragePersister';

export interface PersisterConfigurationOptions extends Pick<QueryOptions, 'gcTime'> {
  strategy: ReturnType<typeof frontendQueryCachePersistence>;
}

export function createPersistenceConfiguration({ gcTime, strategy }: PersisterConfigurationOptions) {
  if (strategy === 'disabled') {
    return undefined;
  }

  const buster = createCacheBuster();
  const cacheKey = createCacheKey();

  let persister: Persister;
  switch (strategy) {
    case 'localstorage':
      persister = createLocalStoragePersister({ cacheKey });
      break;
    case 'indexeddb':
      persister = createIndexedDBPersister({ cacheKey, buster });
      break;
    default:
      assertNever(strategy);
      break;
  }

  return {
    persister,
    maxAge: gcTime,
    buster,
    dehydrateOptions: {
      shouldDehydrateQuery: (query) => {
        if (
          query.state.status === 'success' &&
          (query.queryKey.includes('migration-metrics') || query.queryKey.includes('shortcuts'))
        ) {
          return true;
        }

        if (enableQueryCacheMetaPersistHeuristic()) {
          if (query.meta?.persist) {
            return true;
          }
        }

        return false;
      },
    },
  } satisfies ComponentProps<typeof PersistQueryClientProvider>['persistOptions'];
}
