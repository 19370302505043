import { type GetMeasuredRolloutsQueryParams } from '@gonfalon/openapi';
import { useQuery } from '@tanstack/react-query';

import { getMeasuredRolloutsQuery } from './measuredRollouts';

type Options = ReturnType<typeof getMeasuredRolloutsQuery>;

export const useMeasuredRollouts = (
  params: {
    projectKey: string;
    flagKey: string;
    query?: GetMeasuredRolloutsQueryParams;
  },
  options: Omit<Options, 'queryKey' | 'queryFn'> = {},
) => {
  return useQuery({
    ...getMeasuredRolloutsQuery(params),
    ...options,
  });
};
