import { JSX, useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router';
import { useMatchingAppPathPattern } from '@gonfalon/router';

import { trackRouteChange } from './trackRouteChange';

export function useTrackClientSidePageViews({ routes, enabled = true }: { routes: JSX.Element; enabled?: boolean }) {
  const location = useLocation();
  const previousLocation = useRef<Location | undefined>(undefined);
  const pathPattern = useMatchingAppPathPattern(routes);
  const previousPathPattern = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (pathPattern) {
      const url = new URL(location.pathname, window.location.origin);
      url.search = location.search;
      url.hash = location.hash;

      const referer = previousLocation.current
        ? new URL(previousLocation.current.pathname, window.location.origin)
        : undefined;
      if (referer) {
        referer.search = previousLocation.current?.search ?? '';
        referer.hash = previousLocation.current?.hash ?? '';
      }

      trackRouteChange({
        url: url.toString(),
        referer: referer?.toString(),
        pathPattern,
        refererPathPattern: previousPathPattern.current,
      });
      previousPathPattern.current = pathPattern;
      previousLocation.current = location;
    }
  }, [location, pathPattern, previousLocation, enabled]);
}
