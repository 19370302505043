import { createExperiment } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getExperimentsQuery } from './experiments';

export const useCreateExperiment = createMutationHook(createExperiment, {
  onSuccess: async (_, { projectKey, environmentKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: getExperimentsQuery.partialQueryKey({
        projectKey,
        environmentKey,
      }),
    });
  },
});
