import { ResourceSpecifier } from './ast';

const applicationScopedResources = ['version'] as const satisfies Array<ResourceSpecifier['type']>;

export type ApplicationScopedResource = Extract<
  ResourceSpecifier,
  { type: (typeof applicationScopedResources)[number] }
>;

export const isApplicationScopedResourceType = (resourceType: ResourceSpecifier['type']) =>
  (applicationScopedResources as readonly string[]).includes(resourceType);

export const isApplicationScopedResource = (resource: ResourceSpecifier): resource is ApplicationScopedResource =>
  isApplicationScopedResourceType(resource.type);
