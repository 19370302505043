import { useState } from 'react';
import { Diff } from '@gonfalon/launchpad-experimental';
import { RolePresetsBundle } from '@gonfalon/permissions';
import { isRESTAPIError, usePatchRolePresets } from '@gonfalon/rest-api';
import { formatJSON } from '@gonfalon/strings';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTrigger,
  IconButton,
  Modal,
  ModalOverlay,
  snackbarQueue,
  toastQueue,
} from '@launchpad-ui/components';

import styles from './UpdateOOTBRolesModal.module.css';

export type RolePresetsResponse = {
  current: RolePresetsBundle;
  availableUpdate?: RolePresetsBundle;
};

export const UpdateOOTBRolesModal = ({ rolePresets }: { rolePresets: RolePresetsResponse }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: updateRolePresets, isPending } = usePatchRolePresets();

  const currentRoles = rolePresets.current.roleTemplates;
  const updatedRoles = rolePresets.availableUpdate?.roleTemplates || [];
  const totalUpdates = updatedRoles.length;

  const currentRole = updatedRoles[currentIndex];
  const previousRole = currentRoles?.find((r) => r.key === currentRole?.key);

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, totalUpdates - 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleApplyChanges = () => {
    if (!rolePresets.availableUpdate) {
      return;
    }

    const onError = (err: Error) => {
      snackbarQueue.add({
        description: isRESTAPIError(err) ? err?.message : 'Failed to update role presets. Try again later.',
        status: 'error',
      });
    };

    updateRolePresets(
      {
        body: {
          bundleVersion: rolePresets.availableUpdate?.bundleVersion,
        },
      },
      {
        onSuccess: () => {
          toastQueue.add({ title: 'Role presets updated', status: 'success' });
          setIsOpen(false);
        },
        onError,
      },
    );
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <Button variant="default" size="medium" className={styles.button}>
        View changes
      </Button>
      <ModalOverlay>
        <Modal size="large">
          <Dialog>
            <div className={styles.modalContent}>
              <h3>Updates roles</h3>
              <p>
                Inspect what changed in the role policy before applying these changes to members in your organization
                who have been assigned this role.
              </p>
              <div className={styles.roleControls}>
                <IconButton
                  icon="chevron-left"
                  onPress={handlePrevious}
                  isDisabled={currentIndex === 0}
                  aria-label="Previous role"
                  variant="minimal"
                />
                <p>
                  Reviewing {currentIndex + 1} of {totalUpdates}
                </p>
                <IconButton
                  icon="chevron-right"
                  onPress={handleNext}
                  isDisabled={currentIndex === totalUpdates - 1}
                  aria-label="Next role"
                  variant="minimal"
                />
              </div>
              <Diff
                title={currentRole?.name}
                original={previousRole ? formatJSON(JSON.stringify(previousRole)) : ''}
                compare={currentRole ? formatJSON(JSON.stringify(currentRole)) : ''}
                isJSON
                className={styles.diff}
              />
              <ButtonGroup>
                <Button variant="minimal" onPress={() => setIsOpen(false)} isDisabled={isPending}>
                  Skip for now
                </Button>
                <Button
                  variant="primary"
                  onPress={handleApplyChanges}
                  isDisabled={isPending || !rolePresets.availableUpdate}
                >
                  Apply all changes
                </Button>
              </ButtonGroup>
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
};
