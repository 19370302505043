import { releaseGuardian } from '@gonfalon/dogfood-flags';
import { type GetFlagMeasuredRolloutConfigurationPathParams } from '@gonfalon/openapi';
import { useQuery } from '@tanstack/react-query';

import { getFlagMeasuredRolloutConfigurationQuery } from './flags';

type Options = ReturnType<typeof getFlagMeasuredRolloutConfigurationQuery>;

export const useFlagMeasuredRolloutConfiguration = (
  params: {
    projectKey: string;
    flagKey: string;
    query?: GetFlagMeasuredRolloutConfigurationPathParams;
  },
  options: Omit<Options, 'queryKey' | 'queryFn'> = {
    enabled: true,
  },
) => {
  return useQuery({
    ...getFlagMeasuredRolloutConfigurationQuery(params),
    ...options,
    enabled: options.enabled && releaseGuardian(),
  });
};
