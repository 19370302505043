import { chunk } from '@gonfalon/collections';
import { getMember, getMembers, patchMember, patchMembers, postMembers } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';
import { useMutation } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

const getProfile = async () => getMember({ id: 'me', query: { expand: 'roleAttributes' } });

export const memberProfileQuery = createQueryOptions(getProfile);
export const memberQuery = createQueryOptions(getMember);
export const membersQuery = createQueryOptions(getMembers);
export const usePatchMember = createMutationHook(patchMember, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: memberQuery.partialQueryKey() });
  },
});
export const usePatchMembers = createMutationHook(patchMembers, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: membersQuery.partialQueryKey() });
  },
});
export const useInviteMembers = createMutationHook(postMembers, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: membersQuery.partialQueryKey() });
  },
});

export class InviteMembersBatchError extends Error {
  invalidEmails?: string[];
  rateLimitedEmails?: string[];

  constructor(message: string, invalidEmails?: string[], rateLimitedEmails?: string[]) {
    super(message);
    this.invalidEmails = invalidEmails;
    this.rateLimitedEmails = rateLimitedEmails;
  }
}

export function useInviteMembersBatch() {
  function isFixableBatchError(errStr: string) {
    return ['email_already_exists_in_account', 'email_taken_in_different_account', 'duplicate_emails'].includes(errStr);
  }

  return useMutation({
    mutationFn: async (params: Parameters<typeof postMembers>[0]) => {
      const batchSize = 50;
      const batches = chunk(params.body, batchSize);

      const successfulEmails: string[] = [];
      const errors: Array<{
        invalidEmails?: string[];
        rateLimitedEmails?: string[];
      }> = [];

      for (const batch of batches) {
        const response = await postMembers({ body: batch });
        if (response.error) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const error = response.error as { code: string; message: string; invalid_emails: string[] };
          invariant(
            Array.isArray(error.invalid_emails) && error.invalid_emails.every((email) => typeof email === 'string'),
            'Expected invalid_emails to be array of strings',
          );

          if (isFixableBatchError(error.code) && error.invalid_emails.length > 0) {
            errors.push({ invalidEmails: error.invalid_emails });
            continue;
          }

          if (error.code === 'rate_limited') {
            errors.push({ rateLimitedEmails: error.invalid_emails });
            continue;
          }
        }

        successfulEmails.push(...(response.data?.items.map((m) => m.email) ?? []));
      }

      if (errors.length > 0) {
        throw new InviteMembersBatchError(
          'Failed to invite members',
          errors.flatMap((e) => e.invalidEmails ?? []),
          errors.flatMap((e) => e.rateLimitedEmails ?? []),
        );
      }

      return { successfulEmails };
    },
    retry: 2,
    // exponential backoff starting at 1s, maxing at 30s
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    onSuccess: async () => {
      const queryClient = getQueryClient();
      await queryClient.invalidateQueries({ queryKey: membersQuery.partialQueryKey() });
    },
  });
}
