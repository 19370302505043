import { isNoAccessRoleEnabled, outOfTheBoxRolesReleasePhase } from '@gonfalon/dogfood-flags';
import { capitalize } from '@gonfalon/strings';

import { RoleName } from './internal/types';

const BASIC_ROLES = [
  ...(outOfTheBoxRolesReleasePhase() === 1 ? [RoleName.OWNER] : []),
  RoleName.ADMIN,
  RoleName.WRITER,
  RoleName.READER,
  ...(isNoAccessRoleEnabled() ? [RoleName.NO_ACCESS] : []),
];

export const simpleRoles = Object.freeze(BASIC_ROLES);

export const formattedRoleName = (role: RoleName) => capitalize(role.replace('_', ' '));

export const roleDescription = (role: RoleName) => {
  switch (role) {
    case RoleName.NO_ACCESS:
      return 'Can’t read or modify anything until assigned another role or to a team';
    case RoleName.READER:
      return 'Can read everything, but can’t modify anything.';
    case RoleName.WRITER:
      return 'Can read everything and make changes to the platform, but can’t manage account members, billing details or change the account owner';
    case RoleName.ADMIN:
      return 'Can read and modify anything but can’t change the account owner.';
    case RoleName.OWNER:
      return outOfTheBoxRolesReleasePhase() === 1
        ? 'Can also add or remove the Admin (legacy) role and the Owner (legacy) role from other members. Only one member can have this role.'
        : '';
    case RoleName.CUSTOM:
      return 'Members will have specific custom roles that you’ll assign below';
    default:
      return '';
  }
};

export const samlRoleDescription = (role: RoleName) => {
  switch (role) {
    case RoleName.NO_ACCESS:
      return 'Members won’t be able to read or modify anything until assigned another role or to a team';
    case RoleName.READER:
      return 'Members will be able to read everything, but won’t be able to modify anything until assigned another role or to a team';
    case RoleName.CUSTOM:
      return 'Members will have a specific custom role that you’ll assign below';
    default:
      return '';
  }
};
