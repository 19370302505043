import { GetTagsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetTagsQueryParams extends Omit<GetTagsOpenAPIQueryParams, 'kind'> {
  kind?: 'flag' | 'project' | 'environment' | 'segment' | 'metric' | 'aiconfig';
}

export function querySerializer(queryParams: GetTagsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.archived) {
    searchParams.set('archived', String(queryParams.archived));
  }

  if (queryParams.pre) {
    searchParams.set('pre', queryParams.pre);
  }

  if (queryParams.kind) {
    searchParams.set('kind', queryParams.kind);
  }

  if (queryParams.limit) {
    searchParams.set('limit', String(queryParams.limit));
  }

  if (queryParams.offset) {
    searchParams.set('offset', String(queryParams.offset));
  }

  return searchParams.toString();
}
