import { PatchSegmentOpenAPIRequestBody } from '../generated/operations/raw-openapi-request-body-for-serializers';
import { schemas } from '..';

type JSONPatch = PatchSegmentOpenAPIRequestBody;

interface SemanticPatch {
  comment?: string;
  environmentKey?: string;
  instructions: SegmentSemanticInstruction[];
}

export type PatchSegmentRequestBody = JSONPatch | SemanticPatch;

export function bodySerializer(body: PatchSegmentRequestBody): string {
  return JSON.stringify(body);
}

export type Segment = schemas['UserSegment'];

export type SegmentRule = Omit<schemas['UserSegmentRule'], 'clauses'> & {
  _key?: string; // We use _key instead of id for new rules
  clauses: SegmentRuleClause[];
};

export type SegmentRuleClause = schemas['Clause'] & {
  _key?: string; // We use _key instead of id for new clauses
};

export type SegmentSemanticInstruction =
  | AddBigSegmentIncludedTargetsInstruction
  | AddClausesToRuleInstruction
  | AddExcludedTargetsInstruction
  | AddExcludedUsersInstruction
  | AddExpiringTargetInstruction
  | AddIncludedTargetsInstruction
  | AddIncludedUsersInstruction
  | AddRuleInstruction
  | AddValuesToClauseInstruction
  | ProcessBigSegmentImportInstruction
  | RemoveBigSegmentIncludedTargetsInstruction
  | RemoveExcludedTargetsInstruction
  | RemoveExcludedUsersInstruction
  | RemoveExpiringTargetInstruction
  | RemoveIncludedTargetsInstruction
  | RemoveClausesFromRuleInstruction
  | RemoveIncludedUsersInstruction
  | RemoveRuleInstruction
  | RemoveValuesFromClauseInstruction
  | ReorderRulesInstruction
  | UpdateClauseInstruction
  | UpdateExpiringTargetInstruction
  | UpdateRuleDescriptionInstruction
  | UpdateRuleRolloutInstruction;

export type AddBigSegmentIncludedTargetsInstruction = {
  kind: 'addBigSegmentIncludedTargets';
  values: string[];
};

export type AddClausesToRuleInstruction = {
  kind: 'addClauses';
  ruleId: string;
  clauses: SegmentRuleClause[];
};

export type AddExcludedTargetsInstruction = {
  kind: 'addExcludedTargets';
  contextKind: string;
  values: string[];
};

export type AddExcludedUsersInstruction = {
  kind: 'addExcludedUsers';
  values: string[];
};

export type AddExpiringTargetInstruction = {
  kind: 'addExpiringTarget';
  value: number;
  targetType: string;
  contextKey: string;
  contextKind: string;
};

export type AddIncludedTargetsInstruction = {
  kind: 'addIncludedTargets';
  contextKind: string;
  values: string[];
};

export type AddIncludedUsersInstruction = {
  kind: 'addIncludedUsers';
  values: string[];
};

export type AddRuleInstruction = {
  kind: 'addRule';
  beforeRuleId?: string;
} & SegmentRule;

export type AddValuesToClauseInstruction = {
  kind: 'addValuesToClause';
  ruleId: string;
  clauseId: string;
  values: unknown[];
};

export type ProcessBigSegmentImportInstruction = {
  kind: 'processBigSegmentImport';
  importId: string;
};

export type RemoveBigSegmentIncludedTargetsInstruction = {
  kind: 'removeBigSegmentIncludedTargets';
  values: string[];
};

export type RemoveClausesFromRuleInstruction = {
  kind: 'removeClauses';
  ruleId: string;
  clauseIds: string[];
};

export type RemoveExcludedTargetsInstruction = {
  kind: 'removeExcludedTargets';
  contextKind: string;
  values: string[];
};

export type RemoveExcludedUsersInstruction = {
  kind: 'removeExcludedUsers';
  values: string[];
};

export type RemoveExpiringTargetInstruction = {
  kind: 'removeExpiringTarget';
  targetType: string;
  contextKey: string;
  contextKind: string;
};

export type RemoveIncludedTargetsInstruction = {
  kind: 'removeIncludedTargets';
  contextKind: string;
  values: string[];
};

export type RemoveIncludedUsersInstruction = {
  kind: 'removeIncludedUsers';
  values: string[];
};

export type RemoveRuleInstruction = {
  kind: 'removeRule';
  ruleId: string;
};

export type RemoveValuesFromClauseInstruction = {
  kind: 'removeValuesFromClause';
  clauseId: string;
  ruleId: string;
  values: unknown[];
};

export type ReorderRulesInstruction = {
  kind: 'reorderRules';
  ruleIds: string[];
};

export type UpdateClauseInstruction = {
  kind: 'updateClause';
  ruleId: string;
  clauseId: string;
  clause: SegmentRuleClause;
};

export type UpdateExpiringTargetInstruction = {
  kind: 'updateExpiringTarget';
  value: number;
  targetType: string;
  contextKey: string;
  contextKind: string;
  version: number;
};

export type UpdateRuleDescriptionInstruction = {
  kind: 'updateRuleDescription';
  ruleId: string;
  description: string;
};

type UpdateRuleRolloutInstructionBase = {
  kind: 'updateRuleRolloutAndContextKind';
  ruleId: string;
};

export type UpdateRuleRolloutInstruction = UpdateRuleRolloutInstructionBase &
  (
    | {
        weight: number;
        contextKind: string;
        resetRollout?: undefined;
      }
    | { weight?: number; contextKind: string; resetRollout?: undefined }
    | { weight: number; contextKind?: string; resetRollout?: undefined }
    | { weight?: undefined; contextKind?: undefined; resetRollout: true }
  );
