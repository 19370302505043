import { useEffect } from 'react';
import { connect } from 'react-redux';
import { rulesOfBehaviorIntro } from '@gonfalon/bootstrap-data';
import { disallowSignups, federalCssStylesEnabled as federalCssStyles } from '@gonfalon/constants';
import { areSignupsDisallowed } from '@gonfalon/dogfood-flags';
import { getSession } from '@gonfalon/rest-api';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { createSelector } from 'reselect';

import { createUseMFARecoveryAction, recoverMFA, verifyMFA } from 'actions/auth';
import { resetTimeToLoginMetrics, setTimeToLoginStartTime } from 'actions/timeToLoginMetric';
import Login, { LoginProps } from 'components/loginAndSignUp/Login';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { loginSelector } from 'reducers/auth';
import { selectTimeToLoginMetrics } from 'reducers/timeToLoginMetric';
import { trackSignInPageLoaded } from 'utils/loginUtils';
import { shouldEnableGithubOAuthSignUp, shouldEnableGoogleOAuthSignUp } from 'utils/oauthUtils';

const useRedux = () => {
  const dispatch = useDispatch();
  const timeToLoginMetrics = useSelector(selectTimeToLoginMetrics);

  const dispatchResetTimeToLoginMetrics = () => dispatch(resetTimeToLoginMetrics());
  const setTimeToLoginStartTimeMetric = (startTime: number) => dispatch(setTimeToLoginStartTime(startTime));

  return {
    dispatchResetTimeToLoginMetrics,
    setTimeToLoginStartTimeMetric,
    timeToLoginMetrics,
  };
};

type LoginContainerProps = LoginProps & {
  isSSOConfigured?: boolean;
};

const LoginContainer = (props: LoginContainerProps) => {
  const { dispatchResetTimeToLoginMetrics, setTimeToLoginStartTimeMetric } = useRedux();
  const { redirect = '/', requirePassword, beastMode, isSSOConfigured } = props;
  const { data } = useQuery({
    ...getSession({}),
    // Customers may have multiple tabs open, we want to refetch the session when the user focuses on the tab
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (data && !beastMode) {
      window.location.assign(redirect);
    }
  }, [data, redirect, beastMode]);

  useEffect(() => {
    trackSignInPageLoaded();
    dispatchResetTimeToLoginMetrics();
    setTimeToLoginStartTimeMetric(Date.now());
  }, []);

  return <Login {...props} requirePassword={requirePassword || !!(beastMode && !isSSOConfigured)} />;
};

const mapStateToProps = createSelector(loginSelector, (loginState) => {
  const { token, error, needsRefresh, requirePassword, isRecovery } = loginState;
  // `showPassword` is a hack to enable Calibre to log in programmatically
  const queryParams = qs.parse(window.location.search.slice(1));
  const forcePassword = !!queryParams.showPassword;
  const googleOauthError = !!queryParams.googleOauthError;
  const githubOauthError = !!queryParams.githubOauthError;
  const redirect = queryParams.redirect;

  return {
    token,
    error,
    githubOauthError,
    googleOauthError,
    needsRefresh,
    requirePassword: forcePassword || requirePassword,
    redirect,
    isRecovery,
    rulesOfBehaviorIntro: rulesOfBehaviorIntro(),
    disallowSignups: disallowSignups() || areSignupsDisallowed(),
    enableGoogleOAuthSignUp: shouldEnableGoogleOAuthSignUp(),
    enableGithubOAuthSignUp: shouldEnableGithubOAuthSignUp(),
    federalCssStylesEnabled: federalCssStyles(),
  };
});

const mapDispatchToProps = {
  onMFAVerify: verifyMFA,
  onMFARecover: recoverMFA,
  onUseMFARecovery: createUseMFARecoveryAction,
};

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
