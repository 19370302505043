import { VisuallyHidden } from 'react-aria';
import { Link } from '@launchpad-ui/components';
import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import styles from './NewTabLink.module.css';

type NewTabLinkProps = {
  href: string;
  text?: string;
  showIcon?: boolean;
  onClick?: () => void;
  className?: string;
  size?: IconProps['size'];
  renderIconFirst?: boolean;
};

export function NewTabLink({
  className,
  href,
  text = 'read the documentation',
  showIcon = true,
  size = 'small',
  renderIconFirst = false,
  onClick,
}: NewTabLinkProps) {
  const shouldRenderIconFirst = showIcon && renderIconFirst;
  const shouldRenderIconLast = showIcon && !renderIconFirst;
  const icon = (
    <Icon
      name="link-external"
      data-test-id="new-tab-link-icon"
      className={cx('Icon', styles.icon, {
        [styles.first]: shouldRenderIconFirst,
        [styles.last]: shouldRenderIconLast,
      })}
      size={size}
    />
  );

  return (
    <Link href={href} rel="noopener noreferrer" target="_blank" onPress={onClick} className={className}>
      {shouldRenderIconFirst && icon}
      {text}
      <VisuallyHidden elementType="span">(Link opens in new tab)</VisuallyHidden>
      {shouldRenderIconLast && icon}
    </Link>
  );
}
