/* eslint-disable no-param-reassign */
import { type Writeable, createRecordModifier } from '@gonfalon/record-modifier';

import { RoleAttributeMap, Team, TeamCustomRole } from './types';

const addCustomRoles = (roles: TeamCustomRole[]) => (team: Writeable<Team>) => {
  team.roles = {
    ...team.roles,
    items: [...(team.roles?.items || []), ...roles],
  };
};

const removeCustomRole = (roleKey: string) => (team: Writeable<Team>) => {
  if (team.roles?.items) {
    team.roles.items = team.roles.items.filter(({ key }) => key !== roleKey);
  }
};

const replaceRoleAttributes = (roleAttributes?: RoleAttributeMap) => (team: Writeable<Team>) => {
  if (roleAttributes) {
    team.roleAttributes = roleAttributes;
    return;
  }

  delete team.roleAttributes;
};

const updateRoleAttributes = (roleAttributes: RoleAttributeMap) => (team: Writeable<Team>) => {
  team.roleAttributes = Object.entries(roleAttributes).reduce(
    (acc, [key, values]) => ({
      ...acc,
      // Simply replace the values for each key
      [key]: values ?? [],
    }),
    // Start with existing roleAttributes (for untouched keys)
    team.roleAttributes || {},
  );
};

export const teamModifier = createRecordModifier({
  addCustomRoles,
  replaceRoleAttributes,
  removeCustomRole,
  updateRoleAttributes,
});
