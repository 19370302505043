import { deleteSourceMap } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { sourcemapsMetadataList } from './internal/queries';

type DeleteSourcemapInput = {
  sourceMapId: string;
} & Partial<Omit<Parameters<typeof deleteSourceMap>[0], 'sourceMapId'>>;

export const useDeleteSourcemap = createMutationHook(
  (input: DeleteSourcemapInput) =>
    deleteSourceMap({ sourceMapId: input.sourceMapId, header: { 'LD-API-Version': 'beta' } }),
  {
    onSuccess: async () => {
      const queryClient = getQueryClient();
      await queryClient.invalidateQueries({
        queryKey: sourcemapsMetadataList({ header: { 'LD-API-Version': 'beta' } }).queryKey,
      });
    },
  },
);
