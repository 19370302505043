import { useMemo } from 'react';
import { getCustomRolesQuery } from '@gonfalon/rest-api';
import { useQuery } from '@tanstack/react-query';

import { Member } from '../internal/types';

export const useMemberOrganizationRoles = (member: Member) => {
  const { data: organizationRolesRes, isPending } = useQuery({
    ...getCustomRolesQuery({
      query: {
        filter: {
          resourceCategory: ['organization'],
        },
        limit: 100, // a large enough limit to ensure we get all org roles
      },
    }),
  });

  const allOrganizationRoles = useMemo(() => organizationRolesRes?.items ?? [], [organizationRolesRes]);

  const assignedOrganizationRole = useMemo(() => {
    const orgRoleIds = allOrganizationRoles.map((role) => role._id);
    // find the first org role that is in the member's custom roles
    const assignedOrgRoleId = member.customRoles.find((roleId) => orgRoleIds.includes(roleId));

    // return the CustomRole object
    return allOrganizationRoles.find((role) => role._id === assignedOrgRoleId);
  }, [allOrganizationRoles, member]);

  return { allOrganizationRoles, assignedOrganizationRole, isLoading: isPending };
};
