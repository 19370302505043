import { ResourceSpecifier } from './ast';

const projectScopedResources = [
  'context-kind',
  'release-pipeline',
  'aiconfig',
  'ai-model-config',
  'env',
  'layer',
  'metric',
  'metric-group',
  'goal',
  'payload-filter',
  'issue',
] as const satisfies Array<ResourceSpecifier['type']>;

export type ProjectScopedResource = Extract<ResourceSpecifier, { type: (typeof projectScopedResources)[number] }>;

export const isProjectScopedResourceType = (resourceType: ResourceSpecifier['type']) =>
  (projectScopedResources as readonly string[]).includes(resourceType);

export const isProjectScopedResource = (resource: ResourceSpecifier): resource is ProjectScopedResource =>
  isProjectScopedResourceType(resource.type);
