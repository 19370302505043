import { Team } from '../types';

import {
  AddCustomRolesInstruction,
  AddRoleAttributeInstruction,
  CustomRoleInstruction,
  RemoveCustomRolesInstruction,
  RemoveRoleAttributeInstruction,
  RoleAttributeInstruction,
  TeamInstructionKind,
  UpdateRoleAttributeInstruction,
} from './types';

function makeAddRoleAttributeInstruction(key: string, values: string[]): AddRoleAttributeInstruction {
  return {
    kind: TeamInstructionKind.ADD_ROLE_ATTRIBUTE,
    key,
    values,
  };
}

function makeUpdateRoleAttributeInstruction(key: string, values: string[]): UpdateRoleAttributeInstruction {
  return {
    kind: TeamInstructionKind.UPDATE_ROLE_ATTRIBUTE,
    key,
    values,
  };
}

function makeRemoveRoleAttributeInstruction(key: string): RemoveRoleAttributeInstruction {
  return {
    kind: TeamInstructionKind.REMOVE_ROLE_ATTRIBUTE,
    key,
  };
}

export function makeRoleAttributeInstructions(team: Team, updatedTeam: Team) {
  const instructions: RoleAttributeInstruction[] = [];

  // Get all keys from both teams' role attributes
  const oldKeys = new Set(Object.keys(team.roleAttributes ?? {}));
  const newKeys = new Set(Object.keys(updatedTeam.roleAttributes ?? {}));

  // Handle new and updated role attributes
  for (const key of newKeys) {
    const oldValue = team.roleAttributes?.[key];
    const newValue = updatedTeam.roleAttributes?.[key];

    if (!oldValue && newValue) {
      // Key doesn't exist in old team - this is an addition
      instructions.push(makeAddRoleAttributeInstruction(key, newValue));
    } else if (oldValue && newValue && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      // Key exists but value is different - this is an update
      instructions.push(makeUpdateRoleAttributeInstruction(key, newValue));
    }
  }

  // Handle removed role attributes
  for (const key of oldKeys) {
    if (!newKeys.has(key)) {
      // Key exists in old team but not in new team - this is a removal
      instructions.push(makeRemoveRoleAttributeInstruction(key));
    }
  }

  return instructions;
}

export function makeAddCustomRolesInstruction(values: string[]): AddCustomRolesInstruction {
  return {
    kind: TeamInstructionKind.ADD_CUSTOM_ROLES,
    values,
  };
}

export function makeRemoveCustomRolesInstruction(values: string[]): RemoveCustomRolesInstruction {
  return {
    kind: TeamInstructionKind.REMOVE_CUSTOM_ROLES,
    values,
  };
}

export function makeCustomRoleInstructions(team: Team, updatedTeam: Team) {
  const instructions: CustomRoleInstruction[] = [];

  const oldRoleKeys = new Set(team.roles?.items?.map((tr) => tr.key));
  const newRoleKeys = new Set(updatedTeam.roles?.items?.map((tr) => tr.key));

  for (const key of newRoleKeys) {
    if (key && !oldRoleKeys.has(key)) {
      // Key doesn't exist in old team - this is an addition
      instructions.push(makeAddCustomRolesInstruction([key]));
    }
  }

  for (const key of oldRoleKeys) {
    if (key && !newRoleKeys.has(key)) {
      // Key exists in old team but not in new team - this is a removal
      instructions.push(makeRemoveCustomRolesInstruction([key]));
    }
  }

  return instructions;
}

export function makeUpdateNameInstruction(name: string) {
  return {
    kind: TeamInstructionKind.UPDATE_NAME,
    value: name,
  };
}

export function makeUpdateDescriptionInstruction(description?: string) {
  return {
    kind: TeamInstructionKind.UPDATE_DESCRIPTION,
    value: description,
  };
}
