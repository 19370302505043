import {
  countBy,
  curry,
  flatten,
  get,
  isArray,
  isEmpty,
  isNil,
  isObject,
  isString,
  keys,
  map,
  overSome,
  some,
  uniq,
} from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';
import semverRegex from 'semver-regex';
import equalsValidator from 'validator/lib/equals';
import isEmailValidator from 'validator/lib/isEmail';
import isJSONValidator from 'validator/lib/isJSON';
import isURLValidator from 'validator/lib/isURL';

import { Member } from './accountUtils';
import { FlagConfigMigrationSettings } from './flagUtils';
import { Team } from './teamsUtils';

export const REQUIRED_FIELD_MESSAGE = 'This field is required';

type PredicateResult = {
  for: string;
  errors: string[] | ValidationResults;
};

export type ValidationResults = {
  [prop: string]: string[] | ValidationResults[];
};

export type ImmutableValidationResults = Map<string, List<string> | List<ValidationResults>>;

type ExperimentType = {
  _id: string;
  name: string;
  apiKey: string;
};

type Predicate = {
  (prop: string): (object: { [key: string]: $TSFixMe }) => PredicateResult | undefined;
  (prop: string, object: { [key: string]: $TSFixMe }): PredicateResult | undefined;
};

type BoundPredicate = (object: $TSFixMe) => PredicateResult | PredicateResult[] | undefined | null;

function validator(...predicates: BoundPredicate[]) {
  return function (object: { [key: string]: $TSFixMe }) {
    const predicateResults = predicates
      .reduce<Array<PredicateResult | PredicateResult[] | undefined | null>>((rs, pred) => [...rs, pred(object)], [])
      .filter((r) => {
        if ((r as PredicateResult)?.errors) {
          // @ts-expect-error TypeScript properly infers that `r` is `string[] | ValidationResults`,
          // but we're punting on untangling the types for this module because it's working as
          // expected in our consumers, and we might move away from this style of validation
          // eventually.
          return (r as PredicateResult).errors.length > 0;
        } else {
          return !isNil(r);
        }
      }) as PredicateResult[];
    const results = flatten(predicateResults);

    const fields = uniq(results.map((r) => r.for));
    const fieldErrors = fields.map<[string, string[] | ValidationResults]>((field) => {
      const listOfErrors = results.filter((r) => r.for === field).map((r) => r.errors);
      const errors: Array<string | ValidationResults> = flatten<string | ValidationResults>(listOfErrors);
      if (errors.length === 1 && isObject(errors[0])) {
        return [field, errors[0]];
      } else {
        return [field, errors as string[]];
      }
    });

    return fieldErrors.reduce(
      (acc, error) =>
        Object.assign(acc, {
          [error[0]]: error[1],
        }),
      {} as ValidationResults,
    );
  };
}

// validateRecord does not care about the type of record, just that it is a record.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function validateRecord<T extends Record<any>>(record: T, ...predicates: BoundPredicate[]): ImmutableValidationResults {
  return fromJS(validator(...predicates)(record.toJS()));
}

function optional(predicate: Predicate, prop: string, object: { [key: string]: $TSFixMe }) {
  const value = get(object, prop);
  if (!isEmpty(value) && !isNil(value)) {
    return predicate(prop, object);
  }
}

const optionalCurried = curry(optional);

function simplePredicateFor(
  errorFn: (v: $TSFixMe, obj?: { [key: string]: $TSFixMe }) => boolean,
  messageFn: (v: $TSFixMe, propName?: string, obj?: { [key: string]: $TSFixMe }) => string,
  prop: string,
  object: { [key: string]: $TSFixMe },
) {
  const value = get(object, prop, null);
  const failed = errorFn.length > 1 ? errorFn(value, object) : errorFn(value);

  if (failed) {
    return { for: prop, errors: [messageFn(value, prop, object)] };
  }
}

const simplePredicateForCurried = curry(simplePredicateFor);

// Combine multiple predicates into one. Note that in this
// case errors are not cumulative. The first validation error
// will be returned.
const combinePredicates = (predicates: Predicate[], prop: string, object: { [key: string]: $TSFixMe }) => {
  for (const pred of predicates) {
    const result = pred(prop, object);
    if (result) {
      return result;
    }
  }
};

const combinePredicatesCurried = curry(combinePredicates);

function atLeastOne(fn: (v: $TSFixMe) => boolean, message: string) {
  return simplePredicateForCurried(
    (v: $TSFixMe[]) => !some(v, fn),
    () => message,
  );
}

function isValidEach<T extends { [k: string]: $TSFixMe }>(
  keyFn: (v: $TSFixMe, index: number) => $TSFixMe,
  validation: (obj: T) => ValidationResults,
  prop: string,
  object: { [key: string]: $TSFixMe[] },
) {
  const results = object[prop]?.map(validation);

  const indexed = map(results, (result, index) => ({
    for: `${prop}.${keyFn(object[prop][index], index)}`,
    errors: isEmpty(keys(result)) ? {} : result,
  }));

  const withErrors = indexed.filter((i) => !isEmpty(keys(i.errors)));

  return withErrors as PredicateResult[];
}

export const getIndexedValidationKey = (prop: string, index: number) => `${prop}.${index}`;

export const getIndexedValidationPath = (prop: string, index: number, fieldName: string) => {
  const key = getIndexedValidationKey(prop, index);
  return [key, fieldName];
};

const isValidEachCurried = curry(isValidEach);

const isValidEachIndexed = isValidEachCurried((_: $TSFixMe, index: number) => index);

const isNullOrUndefined = (v: $TSFixMe) => v === null || v === undefined;

const isStringAndEmpty = (v: $TSFixMe) => isString(v) && v.trim() === '';

const isArrayAndEmpty = (v: $TSFixMe) => isArray(v) && isEmpty(v);

const isEnvironmentAndEmpty = (v: ExperimentType) => isNullOrUndefined(v) || isStringAndEmpty(v._id);

const isNotEmpty = simplePredicateForCurried(
  overSome([isNullOrUndefined, isStringAndEmpty, isArrayAndEmpty]),
  () => REQUIRED_FIELD_MESSAGE,
);

const isNotZero = simplePredicateForCurried(
  (v: number) => v === 0,
  () => REQUIRED_FIELD_MESSAGE,
);

const isEnvironment = simplePredicateForCurried(
  (v: ExperimentType) => isEnvironmentAndEmpty(v),
  () => 'This is not a valid Environment',
);

const isURL = simplePredicateForCurried(
  (v: string) => !isURLValidator(v.toLowerCase(), { protocols: ['http', 'https'] }),
  () => 'This is not a valid URL',
);

const validateHttpOrHttps = simplePredicateForCurried(
  (url) => {
    const trimmed = url.trim().toLowerCase();
    return !(trimmed.startsWith('http://') || trimmed.startsWith('https://'));
  },
  () => 'Only HTTP or HTTPS are supported',
);

const isEmail = simplePredicateForCurried(
  (v: string) => !isEmailValidator(v.trim ? v.trim() : v),
  () => 'This is not a valid email address',
);

const isValidEmailList = simplePredicateForCurried(
  (emails: string[]) => emails.some((v) => !isEmailValidator(v.trim ? v.trim() : v)),
  (emails: string[]) => `Invalid email address: ${emails.find((v) => !isEmailValidator(v.trim ? v.trim() : v))}`,
);

// This is a modified version of https://github.com/sindresorhus/semver-regex/blob/main/index.js that checks for versions
// with only two parts (eg `1.2` and `1.2-rc4`), which aren't valid semver per the spec but are supported in our SDKs
const customSemVerRegex = () => /^(?:0|[1-9]\d{0,9}?)\.(?:0|[1-9]\d{0,9})(?:-(?:--+)?(?:0|[1-9]\d*|\d*[a-z]+\d*))?$/gi;

// test against both our custom semver regex and the standard semver regex
const testSemVer = (v: string) => customSemVerRegex().test(v) || semverRegex().test(v);
const isSemVer = simplePredicateForCurried(
  (v: string) => !testSemVer(v),
  () => 'This is not a valid semantic version',
);

const isLength = (min: number, max?: number) =>
  simplePredicateForCurried(
    (v: string | $TSFixMe[]) => (!isNil(max) && v?.length > max) || v?.length < min,
    (v: string | $TSFixMe[]) => {
      if (max && v.length > max) {
        return `This must be at most ${max} ${isString(v) ? 'characters' : 'entries'}`;
      } else {
        if (min === 1) {
          return `This must be at least ${min} ${isString(v) ? 'character' : 'entry'}`;
        } else {
          return `This must be at least ${min} ${isString(v) ? 'characters' : 'entries'}`;
        }
      }
    },
  );

const getWordForInt = (num: number) => {
  const numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
  return num < numbers.length ? numbers[num] : num.toString();
};

const passwordComplexityMessageClause = ({
  requiredCharCountPerClass,
  requiredClassCount,
}: {
  requiredCharCountPerClass: number;
  requiredClassCount: number;
}) => {
  if (requiredClassCount === 4) {
    if (requiredCharCountPerClass === 1) {
      return 'contain a combination of uppercase letters, lowercase letters, numbers, and special characters';
    }
    const charCountWord = getWordForInt(requiredCharCountPerClass);
    return `contain at least ${charCountWord} each of uppercase letters, lowercase letters, numbers, and special characters`;
  }
  const classCountWord = getWordForInt(requiredClassCount);
  if (requiredCharCountPerClass === 1) {
    return `contain at least ${classCountWord} of the following: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character`;
  }
  return `contain at least ${classCountWord} of the following: ${requiredCharCountPerClass} uppercase letters, ${requiredCharCountPerClass} lowercase letters, ${requiredCharCountPerClass} numbers, and ${requiredCharCountPerClass} special characters`;
};

const checkPasswordCharacters = ({
  requiredCharCountPerClass,
  requiredClassCount,
}: {
  requiredCharCountPerClass: number;
  requiredClassCount: number;
}) =>
  simplePredicateForCurried(
    (v: string) => {
      const upperCaseMatch = v.match(/[A-Z]/g);
      const lowerCaseMatch = v.match(/[a-z]/g);
      const numberMatch = v.match(/[0-9]/g);
      const specialCharMatch = v.match(/[-~!@#$%^&*_+=`|\\(){}[\]:;"'<>,.?/]/g);
      const upperCaseCount = upperCaseMatch?.length ?? 0;
      const lowerCaseCount = lowerCaseMatch?.length ?? 0;
      const numberCount = numberMatch?.length ?? 0;
      const specialCharCount = specialCharMatch?.length ?? 0;
      const validClassCount = countBy(
        [lowerCaseCount, upperCaseCount, numberCount, specialCharCount],
        (count) => count >= requiredCharCountPerClass,
      ).true;
      const isValid = validClassCount >= requiredClassCount;
      return !isValid;
    },
    () => `This must ${passwordComplexityMessageClause({ requiredCharCountPerClass, requiredClassCount })}`,
  );

const areAllUnique = (iterateeFn = (it: $TSFixMe) => it, filterFn: (it?: $TSFixMe) => boolean = () => true) =>
  simplePredicateForCurried((v: $TSFixMe[]) => {
    const vs = fromJS(v.filter(filterFn).map(iterateeFn));
    return vs.size !== vs.toSet().size;
  });

const isInRange = ({ min, max }: { min?: number; max?: number } = {}) =>
  simplePredicateForCurried(
    (v: number) => {
      if (min !== undefined && max !== undefined) {
        return !(v >= min && v <= max);
      } else if (min !== undefined && max === undefined) {
        return !(v >= min);
      } else if (min === undefined && max !== undefined) {
        return !(v <= max);
      }
      return false;
    },
    () => {
      if (min !== undefined && max !== undefined) {
        return `This must be an integer between ${min} and ${max}`;
      } else if (min !== undefined && max === undefined) {
        return `This must be an integer greater than ${min}`;
      } else if (max !== undefined && min === undefined) {
        return `This must be an integer less than ${max}`;
      } else {
        return 'This must be an integer';
      }
    },
  );

// TODO:this exists because lodash simplePredicateForCurried doesn't support JSON path get syntax yet,
// and we need to get the value of the nested field checkRatio in migrationSettings
const checkRadioIsInRange = ({ min, max }: { min?: number; max?: number } = {}) =>
  simplePredicateForCurried(
    (v: FlagConfigMigrationSettings) => {
      const { checkRatio } = v;
      const num = checkRatio;
      if (min !== undefined && max !== undefined) {
        return !(num >= min && num <= max);
      } else if (min !== undefined && max === undefined) {
        return !(num >= min);
      } else if (min === undefined && max !== undefined) {
        return !(num <= max);
      }
      return false;
    },
    () => {
      if (min !== undefined && max !== undefined) {
        return `This must be an integer between ${min} and ${max}`;
      } else if (min !== undefined && max === undefined) {
        return `This must be an integer greater than ${min}`;
      } else if (max !== undefined && min === undefined) {
        return `This must be an integer less than ${max}`;
      } else {
        return 'This must be an integer';
      }
    },
  );

const isJSON = simplePredicateForCurried(
  (v: string) => !isJSONValidator(v),
  () => 'This must be valid JSON',
);

const isChecked = simplePredicateForCurried(
  (v: boolean) => !(v === true),
  () => 'This must be checked',
);

const equals = (comparison: string) =>
  simplePredicateForCurried(
    (v: string) => !equalsValidator(v, comparison),
    () => `This must be ${comparison}`,
  );

const matches = (prop: string) =>
  simplePredicateForCurried(
    (v: $TSFixMe, obj?: { [key: string]: $TSFixMe }) => (isNil(obj) ? true : obj[prop] !== v),
    () => "This doesn't match",
  );

const reservedKeys = ['new'];
const isReservedKey = simplePredicateForCurried(
  (v: string) => reservedKeys.includes(v.toLowerCase()),
  (v: string) => `Invalid key. '${v}' is a restricted key`,
);

const tagRegexp = /^[.A-Za-z_\-0-9]+$/;
const isValidTagList = simplePredicateForCurried(
  (tags: string[]) => tags?.some((t) => !tagRegexp.test(t)),
  () => "Invalid tags. Tags must only contain letters, numbers, '.', '_' or '-'",
);

const isValidNotifyMembersList = (maxLength: number) =>
  simplePredicateForCurried(
    (v: string[]) => v.length > maxLength,
    () => `You may only specify at most ${maxLength} reviewers`,
  );

const isValidNotifyReviewersCount = (maxLength: number, teams: Team[], members: Member[]) =>
  simplePredicateForCurried(
    () => validateNotifyReviewersCount(maxLength, teams, members),
    () => `You may only specify at most ${maxLength} reviewers`,
  );

function validateNotifyReviewersCount(maxLength: number, teams: Team[], members: Member[]) {
  let count = members.length;
  for (const team of teams) {
    if (team.members) {
      count += team.members?.totalCount;
    }
  }
  return count > maxLength;
}

const isFalse = simplePredicateForCurried(
  (v: boolean) => v,
  () => 'This condition should not be true',
);

// TODO: should return value enforce custom role type? does not seem necessary.
function validateCustomRoles<T>(fieldFn: (record: T) => $TSFixMe, forceCustom: boolean) {
  return function (record: T) {
    let errors: string[] = [];

    const customRoles = fieldFn(record);

    if (forceCustom && customRoles.length === 0) {
      errors = errors.concat('At least one custom role is required');
    }

    return errors.length !== 0 ? { for: 'customRoles', errors } : null;
  };
}

export {
  validator,
  validateRecord,
  simplePredicateForCurried as predicateFor,
  equals,
  matches,
  isNotEmpty,
  isNotZero,
  isEnvironment,
  isURL,
  validateHttpOrHttps,
  isEmail,
  isSemVer,
  testSemVer,
  isLength,
  isInRange,
  checkRadioIsInRange,
  isJSON,
  isChecked,
  areAllUnique,
  optionalCurried as optional,
  isValidEachIndexed as isValidEach,
  isValidEachCurried as isValidEachKeyed,
  atLeastOne,
  combinePredicatesCurried as combinePredicates,
  checkPasswordCharacters,
  passwordComplexityMessageClause,
  isReservedKey,
  isValidTagList,
  isValidEmailList,
  validateCustomRoles,
  isValidNotifyMembersList,
  isValidNotifyReviewersCount,
  isFalse,
  validateNotifyReviewersCount,
  isNullOrUndefined,
};
