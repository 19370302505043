import { Github } from 'components/ui/icons/integrationLogos';
import { trackLoginForm } from 'utils/loginUtils';

import { OAuthButtonContainer } from '../OAuthButtonContainer';

import styles from './GitHubOAuthButton.module.css';

export const GitHubOAuthButton = () => {
  const href = 'oauth/github';
  return (
    <OAuthButtonContainer className={styles.container}>
      <a href={href} onClick={() => trackLoginForm('Github OAuth Button Clicked')}>
        <Github size="small" className={styles.icon} /> Continue with GitHub
      </a>
    </OAuthButtonContainer>
  );
};
