import invariant from 'tiny-invariant';

// eslint-disable-next-line import/no-namespace
import * as resources from './ast';
import { ResourceSpecifier } from './ast';

export const resourceBuilderMap = {
  acct: resources.account,
  aiconfig: resources.aiConfig,
  'ai-model-config': resources.aiModelConfig,
  application: resources.application,
  version: resources.applicationVersion,
  'code-reference-repository': resources.repository,
  'context-kind': resources.contextKind,
  destination: resources.destination,
  'domain-verification': resources.domainVerification,
  env: resources.environment,
  experiment: resources.experiment,
  feature: resources.flag,
  flag: resources.flag,
  goal: resources.goal,
  holdout: resources.holdout,
  integration: resources.integration,
  issue: resources.issue,
  layer: resources.layer,
  member: resources.member,
  metric: resources.metric,
  'metric-group': resources.metricGroup,
  'payload-filter': resources.payloadFilter,
  'pending-request': resources.pendingRequest,
  proj: resources.project,
  'relay-proxy-config': resources.relayProxyConfig,
  'release-pipeline': resources.releasePipeline,
  role: resources.role,
  segment: resources.segment,
  'service-token': resources.serviceToken,
  team: resources.team,
  template: resources.template,
  token: resources.token,
  user: resources.user,
  webhook: resources.webhook,
};

export function getResourceSpecifierBuilder<T extends ResourceSpecifier['type']>(resourceType: T) {
  const builder = resourceBuilderMap[resourceType];
  invariant(builder, `Unknown resource type: ${resourceType}`);
  return builder;
}
