export class RESTAPIError extends Error {
  name = 'RESTAPIError';
  status: number;
  url: string;
  code: string;
  message: string;
  stack?: string;

  constructor({
    status,
    url,
    code,
    message,
    cause,
    stack,
  }: {
    status: number;
    url: string;
    code: string;
    message: string;
    cause?: unknown;
    stack?: string;
  }) {
    super(message);
    this.status = status;
    this.url = url;
    this.code = code;
    this.message = message;
    this.cause = cause;
    if (stack) {
      this.stack = stack || undefined;
    }
  }

  toJSON() {
    return { status: this.status, url: this.url, code: this.code, message: this.message, stack: this.stack };
  }
}

export function isRESTAPIError(error: unknown): error is RESTAPIError {
  return error instanceof RESTAPIError;
}

export class NetworkError extends Error {
  name = 'NetworkError';
  message: string;
  stack?: string;

  constructor({ message, cause, stack }: { message: string; cause?: unknown; stack?: string }) {
    super(message);
    this.message = message;
    this.cause = cause;
    if (stack) {
      this.stack = stack || undefined;
    }
  }

  toJSON() {
    return { message: this.message, stack: this.stack };
  }
}

export function isNetworkError(error: unknown): error is NetworkError {
  return error instanceof NetworkError;
}
