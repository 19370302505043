import { GetSourceMapsMetadataQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { sourcemapsMetadataList } from './internal/queries';

export const useSourcemapsMetadata = createQueryHook(({ query }: { query: GetSourceMapsMetadataQueryParams }) => {
  return sourcemapsMetadataList({
    header: { 'LD-API-Version': 'beta' },
    query,
  });
});
