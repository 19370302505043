import { type GetMetricQueryParams } from '@gonfalon/openapi';
import { type QueryOptions, useQueries } from '@tanstack/react-query';

import { createQueryHook } from './internal/createQueryHook';
import { metrics } from './internal/queries';
import { getMetricDetailsQuery } from './metrics';

export const useMetric = createQueryHook(metricQuery);

export const useMetricDetails = createQueryHook(getMetricDetailsQuery);

export function useMetricQueries(
  {
    projectKey,
    metricKeys,
    params,
  }: {
    projectKey: string;
    metricKeys: string[];
    params?: GetMetricQueryParams;
  },
  options?: QueryOptions,
) {
  return useQueries({
    ...options,
    queries: metricKeys.map((metricKey) => metricQuery({ projectKey, metricKey, params })),
  });
}

function metricQuery({
  projectKey,
  metricKey,
  params,
}: {
  projectKey: string;
  metricKey: string;
  params?: GetMetricQueryParams;
}) {
  return metrics.detail({ projectKey, metricKey, params });
}
