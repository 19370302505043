import { postFeatureFlag } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getFeatureFlagsQuery } from './flags';

export const useCreateFlag = createMutationHook(postFeatureFlag, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getFeatureFlagsQuery.partialQueryKey() });
  },
});
