import { Tag, TagGroup, TagList } from '@launchpad-ui/components';
import cx from 'clsx';

import { LaunchDarklyOsmo } from 'components/ui/icons/LaunchDarklyOsmo';
import { titlecase } from 'utils/stringUtils';

import styles from './styles/LogoWithWordmarkHeader.module.css';

type LogoWithWordmarkHeaderProps = {
  federalCssStylesEnabled?: boolean;
  hideHeaderBackground?: boolean;
  logoUrl?: string;
};

export function LogoWithWordmarkHeader({
  federalCssStylesEnabled,
  hideHeaderBackground,
  logoUrl,
}: LogoWithWordmarkHeaderProps) {
  return (
    <header
      className={cx(styles.logoWithWordmarkHeader, styles.dark, {
        [styles.noBackground]: hideHeaderBackground,
      })}
    >
      <a href={logoUrl || '/'} aria-label="LaunchDarkly">
        <LaunchDarklyOsmo />
      </a>
      {federalCssStylesEnabled && (
        <TagGroup className={styles.logoHeader} aria-label="type">
          <TagList>
            <Tag variant="federal">{titlecase('federal')}</Tag>
          </TagList>
        </TagGroup>
      )}
    </header>
  );
}
