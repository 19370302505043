import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { Dimensions, ResizeObserverTracker } from './ResizeObserverTracker';
import { useResizeObserver } from './useResizeObserver';

export const useNodeDimensions = <T extends Element = Element>(
  box: ResizeObserverBoxOptions = 'border-box',
  measureOnMount = false,
): [RefObject<T | null>, Partial<Dimensions>] => {
  const ref = useRef<T>(null);
  const emittedOnMount = useRef(false);
  const [dimensions, setDimensions] = useState<Partial<Dimensions>>({});
  const tracker = useRef(new ResizeObserverTracker(box, setDimensions));
  const onResize = useCallback((block: ResizeObserverEntry) => tracker.current.diff(block), []);

  useEffect(() => {
    if (measureOnMount && ref.current && !emittedOnMount.current) {
      emittedOnMount.current = true;
      const { width, height } = ref.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, [measureOnMount]);

  useResizeObserver<T>({
    ref,
    box,
    onResize,
    enabled: true,
  });

  useEffect(() => tracker.current.destroy(), [box]);

  return [ref, dimensions];
};
