import { NewTabLink } from '@gonfalon/launchpad-experimental';

import { trackCreateSupportTicket } from './trackCreateSupportTicket';

type CreateSupportTicketLinkProps = {
  onClick?: () => void;
  identifierForAnalyticsTracking?: string;
};

export const CreateSupportTicketLink = ({
  onClick,
  identifierForAnalyticsTracking = '',
}: CreateSupportTicketLinkProps) => {
  const handleClick = () => {
    onClick?.();
    trackCreateSupportTicket(identifierForAnalyticsTracking);
  };
  return (
    <NewTabLink
      href="https://support.launchdarkly.com/hc/en-us/requests/new"
      onClick={handleClick}
      text="create a support ticket"
    />
  );
};
