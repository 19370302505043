export function tokenizeInput(input: string) {
  if (!input) {
    return [];
  }

  return input
    .split(/[\n,]/g)
    .map((v) => v.trim())
    .filter((v) => v.length > 0);
}
