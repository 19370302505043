import { getAccount, getSamlConfig, patchAccount } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

export const getAccountQuery = createQueryOptions(getAccount);
export const useUpdateAccount = createMutationHook(patchAccount, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAccountQuery.partialQueryKey() });
  },
});
export const getSamlConfigQuery = createQueryOptions(getSamlConfig);
