import { InternalGetRolePresetsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = '20240415';

export type InternalGetRolePresetsQueryParams = InternalGetRolePresetsOpenAPIQueryParams & {
  expand?: 'roleTemplates';
};

export function querySerializer(queryParams: InternalGetRolePresetsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand);
  }

  return searchParams.toString();
}
