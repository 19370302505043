import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { isTypographyRefreshEnabled } from '@gonfalon/dogfood-flags';

import { ThemeContext } from './ThemeContext';
import { Theme } from './types';

const isValidTheme = (theme: string): theme is Theme => ['default', 'dark'].includes(theme);

const getFallbackTheme: () => Theme = () => 'default';

const loadThemeFromLocalStorage: () => Theme = () => {
  if (!isValidTheme(localStorage.theme)) {
    delete localStorage.theme;
  }

  return localStorage.theme ? localStorage.theme : getFallbackTheme();
};

const getInitialTheme = (forceTheme?: Theme) => forceTheme || loadThemeFromLocalStorage();

export const ThemeProvider = ({ children, forceTheme }: { children: ReactNode; forceTheme?: Theme }) => {
  const [theme, setTheme] = useState<Theme>(getInitialTheme(forceTheme));

  const updateTheme = useCallback(
    (newTheme: Theme) => {
      // guard against invalid theme values
      setTheme(isValidTheme(newTheme) ? newTheme : getFallbackTheme());
    },
    [setTheme],
  );

  const toggleTheme = useCallback(() => {
    setTimeout(() => document.documentElement.classList.add('theme-transition'));
    updateTheme(theme === 'dark' ? 'default' : 'dark');
    setTimeout(() => document.documentElement.classList.remove('theme-transition'), 500);
  }, [theme, updateTheme]);

  useEffect(() => {
    if (isTypographyRefreshEnabled()) {
      document.documentElement.dataset.typeRefresh = '';
    }
  }, []);

  useEffect(() => {
    /*
      Don't update local storage if the forceTheme prop is defined.
      Updating this value will overwrite the user's preference.
    */
    if (!forceTheme) {
      localStorage.theme = theme;
    }

    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    // Don't set theme from local storage if the forceTheme prop is defined
    if (forceTheme) {
      return;
    }

    const onStorage = () => {
      const val = localStorage.theme;
      updateTheme(val);
    };
    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, []);

  const value = useMemo(
    () => ({
      theme,
      toggleTheme,
    }),
    [theme, toggleTheme],
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
