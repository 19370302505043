import { isRouteErrorResponse } from 'react-router';

export function wrapRouteError(error: unknown) {
  if (isRouteErrorResponse(error)) {
    const responseError = new Error(`${error.status} ${error.statusText}`);
    responseError.name = 'ReactRouterResponseError';
    responseError.cause = error;

    return responseError;
  }

  const routeError = new Error();
  if (error instanceof Error) {
    routeError.message = error.message;
    routeError.stack = error.stack;
  }
  routeError.name = 'ReactRouterError';
  routeError.cause = error;

  return routeError;
}
