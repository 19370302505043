import { useEffect } from 'react';
import { useRouteError } from 'react-router';
import { RUMCustomErrorContext } from '@gonfalon/telemetry';

import { trackRouteError } from './trackRouteError';

/**
 * Wrapper around `useRouteError` hook that sends the error to Datadog.
 * @returns Route error object
 */
export function useTrackRouteError({ isCrash, severity }: RUMCustomErrorContext = {}) {
  const error = useRouteError();

  useEffect(() => {
    trackRouteError(error, { isCrash, severity });
  }, [error, isCrash, severity]);

  return error;
}
