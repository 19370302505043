import { isRESTAPIError } from '@gonfalon/rest-api';
import type { RUMCustomErrorContext } from '@gonfalon/telemetry';
import { trackError } from '@gonfalon/telemetry';

import { wrapRouteError } from './wrapRouteError';

export function trackRouteError(error: unknown, { isCrash, severity }: RUMCustomErrorContext = {}) {
  if (!error) {
    return;
  }

  const context: RUMCustomErrorContext = { isCrash, severity };

  if (isRESTAPIError(error)) {
    context.url = error.url;
  }

  trackError(wrapRouteError(error), context);
}
