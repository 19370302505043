import { schemas, updateIssue } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { monitoringIssueCounts, monitoringIssuesDetail, monitoringIssuesList } from './internal/queries';

type UpdateIssueInput = Parameters<typeof updateIssue>[0];

type MonitoringIssuesList = schemas['Issues'];
type MonitoringIssueCounts = schemas['IssueCounts'];
type MonitoringIssue = schemas['Issue'];

export const useUpdateIssue = createMutationHook(
  (input: UpdateIssueInput) => updateIssue({ ...input, apiVersion: 'beta' }),
  {
    onMutate: async ({ projectKey, issueId, body }) => {
      const queryClient = getQueryClient();

      // Utility to find all matching queries based on a predicate
      const findMatchingQueries = (partialKey: ReturnType<typeof monitoringIssuesList.partialQueryKey>) =>
        queryClient.getQueryCache().findAll({
          predicate: (query) => {
            const key = query.queryKey[0];
            // Check if the key is an object with type and projectKey properties,
            // this is a type-guard to ensure that the key is a valid query key
            if (key === null || typeof key !== 'object' || 'type' in key === false || 'projectKey' in key === false) {
              return false;
            }

            // Check if the query key matches the partial key
            return key.type === partialKey[0].type && key.projectKey === projectKey;
          },
        });

      // Find matching issue list and counts queries
      const issueListQueries = findMatchingQueries(monitoringIssuesList.partialQueryKey());
      const issueDetailQueries = findMatchingQueries(monitoringIssuesDetail.partialQueryKey());
      const countsQueries = findMatchingQueries(monitoringIssueCounts.partialQueryKey());
      const countsQuery = countsQueries[0];

      // Cancel any outgoing refetches
      const queryKeys = [
        ...issueListQueries.map((query) => query.queryKey),
        ...issueDetailQueries.map((query) => query.queryKey),
        countsQuery?.queryKey,
      ].filter(Boolean);
      await Promise.all(queryKeys.map((queryKey) => queryClient.cancelQueries({ queryKey })));

      // Snapshot previous state
      const previousState = {
        issues: issueListQueries.map((query) => ({
          queryKey: query.queryKey,
          data: queryClient.getQueryData(query.queryKey) as MonitoringIssuesList,
        })),
        counts: countsQuery ? (queryClient.getQueryData(countsQuery.queryKey) as MonitoringIssueCounts) : undefined,
        issueDetails: issueDetailQueries.map((query) => ({
          queryKey: query.queryKey,
          data: queryClient.getQueryData(query.queryKey) as MonitoringIssue,
        })),
      };

      // Locate the issue being updated
      const currentIssue =
        previousState.issueDetails.find(({ data }) => data.id === issueId)?.data ??
        previousState.issues.flatMap(({ data }) => data.issues).find((issue) => issue.id === issueId);

      if (!currentIssue) {
        return;
      }

      // Type-guard to narrow the target status,
      // if the target status is not one of the allowed statuses,
      // we can't optimistically update the issue
      // We hopefully should never hit this, but it's a sanity check
      const targetStatus = body.status;
      if (targetStatus !== 'open' && targetStatus !== 'resolved' && targetStatus !== 'ignored') {
        return;
      }

      // Optimistically update issue lists
      issueListQueries.forEach(({ queryKey }) => {
        const key = queryKey[0] as { query: { status: string[] } };
        const queryStatus = key.query.status[0];

        // If this is a list for the old status, remove the issue
        if (queryStatus === currentIssue.status) {
          queryClient.setQueryData(queryKey, (oldData?: MonitoringIssuesList) => ({
            ...oldData,
            issues: oldData?.issues?.filter((issue) => issue.id !== issueId) ?? [],
          }));
        }

        // If this is a list for the target status, add the issue
        if (queryStatus === targetStatus) {
          queryClient.setQueryData(queryKey, (oldData?: MonitoringIssuesList) => ({
            ...oldData,
            issues: [...(oldData?.issues ?? []), { ...currentIssue, status: targetStatus }],
          }));
        }
      });

      // Optimistically update counts
      if (countsQuery) {
        queryClient.setQueryData(countsQuery.queryKey, (oldCounts?: MonitoringIssueCounts) => ({
          ...oldCounts,
          counts: {
            ...oldCounts?.counts,
            [currentIssue.status]: Math.max(0, (oldCounts?.counts?.[currentIssue.status] ?? 0) - 1),
            [targetStatus]: (oldCounts?.counts?.[targetStatus] ?? 0) + 1,
          },
        }));
      }

      // Optimistically update issue details
      issueDetailQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData(queryKey, {
          ...currentIssue,
          status: targetStatus,
        });
      });

      return {
        previousState,
        countsQueryKey: countsQuery?.queryKey,
      };
    },
    onError: (_error, _variables, context) => {
      if (!context) {
        return;
      }

      const queryClient = getQueryClient();

      // If the mutation fails, we need to revert the optimistic update
      // This is a no-op if the mutation is successful
      context.previousState.issues.forEach(({ queryKey, data }) =>
        queryClient.setQueryData<MonitoringIssuesList>(queryKey, data),
      );

      context.previousState.issueDetails.forEach(({ queryKey, data }) =>
        queryClient.setQueryData<MonitoringIssue>(queryKey, data),
      );

      if (context.countsQueryKey) {
        queryClient.setQueryData<MonitoringIssueCounts>(context.countsQueryKey, context.previousState.counts);
      }
    },
  },
);
