import {
  getDependentFlags,
  getDependentFlagsByEnv,
  getEvaluationsUsage,
  getFeatureFlag,
  getFeatureFlags,
  getFeatureFlagStatus,
  getFeatureFlagStatusAcrossEnvironments,
  getFeatureFlagStatuses,
  getFlagFollowers,
  getFlagLinks,
  getFlagMeasuredRolloutConfiguration,
  getReleaseByFlagKey,
} from '@gonfalon/openapi';

import { createBatchedQueryOptions } from './internal/createBatchedQueryOptions';
import { createQueryOptions } from './internal/createQueryOptions';
import { internalSearchFlagsTaskRunner } from './internal/internalSearchFlagsTaskRunner';
import { queryFeatureFlagStatusesTaskRunner } from './internal/queryFeatureFlagStatusesTaskRunner';

export const getFeatureFlagsQuery = createQueryOptions(getFeatureFlags);
export const getFeatureFlagQuery = createQueryOptions(getFeatureFlag);
export const getFeatureFlagStatusQuery = createQueryOptions(getFeatureFlagStatus);
export const getFlagStatusesQueryOptions = createQueryOptions(getFeatureFlagStatuses);
export const searchFlagsQuery = createBatchedQueryOptions(internalSearchFlagsTaskRunner);

export const getFlagLinksQuery = createQueryOptions(getFlagLinks);

export const getFlagFollowersQuery = createQueryOptions(getFlagFollowers);

export const getDependentFlagsQuery = createQueryOptions(getDependentFlags);
export const getDependentFlagsByEnvQuery = createQueryOptions(getDependentFlagsByEnv);

export const getEvaluationsUsageQuery = createQueryOptions(getEvaluationsUsage);

export const getReleaseByFlagKeyQuery = createQueryOptions(getReleaseByFlagKey);

export const getFeatureFlagStatusAcrossEnvironmentsQuery = createQueryOptions(getFeatureFlagStatusAcrossEnvironments);

export const getFeatureFlagStatusesQuery = createBatchedQueryOptions(queryFeatureFlagStatusesTaskRunner);

export const getFlagMeasuredRolloutConfigurationQuery = createQueryOptions(getFlagMeasuredRolloutConfiguration);
