/* eslint-disable */
// @ts-nocheck

import {
  accountId,
  canduClientToken,
  ghosting,
  groupTraits,
  identifyTraits,
  segmentWriteKey,
  uberfunnelTrackingId,
  userId,
  intercomFinAppUserIdentityHash,
} from '@gonfalon/constants';
import { isAnalyticsTrackingEnabled, isCanduEnabled, spaPageViewsForSegmentCom } from '@gonfalon/dogfood-flags';
import { onDOMReady } from '@gonfalon/dom';

export const initSegmentAndCandu = () => {
  if (ghosting()) {
    return;
  }

  function onPageLoad() {
    function getCookieValue(name) {
      var value = '; ' + document.cookie;
      var parts = value.split('; ' + name + '=');
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    }

    if (isCanduEnabled() && canduClientToken() && userId()) {
      (function (d, params) {
        const script = d.createElement('script');
        script.setAttribute('src', `https://cdn.candu.ai/sdk/latest/candu.umd.js?token=${params.clientToken}`);
        script.onload = function () {
          Candu.init(params);
        };
        d.head.appendChild(script);
      })(document, {
        userId: userId(),
        clientToken: canduClientToken(),
      });
    }

    if (isAnalyticsTrackingEnabled()) {
      // check to see if we're running in a Datadog synthetic test before initializing analytics
      // the synthetic tests generate a lot of noise that we don't want in our production analytics
      if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
        !(function () {
          var analytics = (window.analytics = window.analytics || []);
          if (!analytics.initialize)
            if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
            else {
              analytics.invoked = !0;
              analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
                'addSourceMiddleware',
                'addIntegrationMiddleware',
                'setAnonymousId',
                'addDestinationMiddleware',
              ];
              analytics.factory = function (e) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  t.unshift(e);
                  analytics.push(t);
                  return analytics;
                };
              };
              for (var e = 0; e < analytics.methods.length; e++) {
                var key = analytics.methods[e];
                analytics[key] = analytics.factory(key);
              }
              analytics.load = function (key, e) {
                var t = document.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                var n = document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(t, n);
                analytics._loadOptions = e;
              };
              analytics._writeKey = segmentWriteKey();
              analytics.SNIPPET_VERSION = '4.15.3';
              analytics.load(segmentWriteKey());
              if (uberfunnelTrackingId()) {
                analytics.ready(function () {
                  if (typeof window.ga === 'function') {
                    window.ga('create', uberfunnelTrackingId(), 'auto', { name: 'uberfunnel' });
                    window.ga('uberfunnel.send', 'pageview', { page: location.pathname + location.search });
                    analytics.on('page', function (event, properties, options) {
                      window.ga('uberfunnel.send', 'pageview', { page: location.pathname + location.search });
                    });
                  }
                });
                analytics.on('track', function (event) {
                  if (typeof window.ga === 'function') {
                    window.ga('uberfunnel.send', 'event', 'All', event);
                  }
                });
              }

              if (!spaPageViewsForSegmentCom()) {
                analytics.page();
              }

              if (userId()) {
                analytics.identify(userId(), identifyTraits());
              }

              if (accountId()) {
                var groupId = getCookieValue('ajs_group_id');
                if (!groupId || groupId === 'null') {
                  // only do a `group` call if  there's no group_id set yet
                  analytics.group(accountId(), groupTraits());
                }
              }
            }
        })();
      }
    }
  }
  onDOMReady(onPageLoad);
};
