import { patchExperiment } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { experimentDetail } from './internal/queries';
import { getExperimentQuery, getExperimentsQuery } from './experiments';
export const useUpdateExperiment = createMutationHook(patchExperiment, {
  onSuccess: async (_, { projectKey, environmentKey, experimentKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: getExperimentQuery.partialQueryKey({
        projectKey,
        environmentKey,
        experimentKey,
      }),
    });

    await queryClient.invalidateQueries(experimentDetail({ projectKey, environmentKey, experimentKey }));

    await queryClient.invalidateQueries({
      queryKey: getExperimentQuery.partialQueryKey({
        projectKey,
        environmentKey,
        experimentKey,
      }),
    });
    await queryClient.invalidateQueries({
      queryKey: getExperimentsQuery.partialQueryKey({
        projectKey,
        environmentKey,
      }),
    });
  },
});
