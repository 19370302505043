import { ReactElement, useState } from 'react';
import { Member, memberModifier } from '@gonfalon/members';
import { isRESTAPIError, JSONPatch, usePatchMember } from '@gonfalon/rest-api';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTrigger,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  ProgressBar,
  snackbarQueue,
  toastQueue,
} from '@launchpad-ui/components';
import { Box, ButtonProps, IconButtonProps } from '@launchpad-ui/core';

export type UnsetLegacyRoleModalProps = {
  trigger: ReactElement<ButtonProps | IconButtonProps>;
  member: Member;
  createJsonPatch: <T>(
    prev: T,
    next: T,
  ) =>
    | JSONPatch
    | {
        comment: string;
        patch: JSONPatch;
      };
};

export function UnsetLegacyRoleModal({ trigger, member, createJsonPatch }: UnsetLegacyRoleModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: updateMember, isPending: isUpdatingMember } = usePatchMember();

  const handleOpenChange = (nextIsOpen: boolean) => {
    setIsOpen(nextIsOpen);
  };

  const handleUpdateMember = () => {
    const updatedMember = memberModifier(member).setRole('no_access');
    const patch = createJsonPatch(member, updatedMember) as JSONPatch;

    updateMember(
      { id: member._id, body: patch },
      {
        onSuccess: () => {
          toastQueue.add({ title: 'Legacy role updated', status: 'success' });
          handleOpenChange(false);
        },
        onError: (err: Error) => {
          snackbarQueue.add({
            description: isRESTAPIError(err) ? err?.message : 'Failed to update legacy role. Try again later.',
            status: 'error',
          });
        },
      },
    );
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      {trigger}
      <ModalOverlay>
        <Modal>
          <Dialog>
            <div slot="header">
              <Heading slot="title">Edit legacy role to No Access</Heading>
              <IconButton
                aria-label="close modal"
                icon="cancel"
                variant="minimal"
                onPress={() => handleOpenChange(false)}
              />
            </div>

            <Box slot="body" display="grid" gap="$500">
              <p>
                Legacy roles are no longer available to be modified or assigned. This action will edit this member's
                legacy role to be No Access, allowing you to take advantage of our new global roles. Do you want to
                proceed?
              </p>
            </Box>

            <div slot="footer">
              <ButtonGroup>
                <Button variant="default" onPress={() => handleOpenChange(false)}>
                  Cancel
                </Button>

                <Button variant="primary" isDisabled={isUpdatingMember} onPress={handleUpdateMember}>
                  {isUpdatingMember ? <ProgressBar isIndeterminate size="small" /> : 'Edit role'}
                </Button>
              </ButtonGroup>
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
