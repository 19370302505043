import { AccountAction, checkAccountAccess } from '@gonfalon/account';
import { AnalyticEventData, createTrackerForCategory, track } from '@gonfalon/analytics';
import { minPasswordLength, passwordMinCharsPerClass, passwordMinClasses } from '@gonfalon/constants';
import {
  enableRemoveOrganizationFromSignup,
  isCustomRolesEnabled,
  isExpandMembersEndpointEnabled,
  isTeamsEnabled,
} from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
import { hasCreateMemberRights as _hasCreateMemberRights } from '@gonfalon/members';
import { Access, RoleName } from '@gonfalon/permissions';
import { isBefore } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap, OrderedSet, Record, Set } from 'immutable';
import isValidEmail from 'validator/lib/isEmail';

import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { getUTMParams } from 'utils/analyticsUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { resourceKinds } from 'utils/resourceUtils';
import { Role, RoleSummaryType } from 'utils/roleUtils';
import { createSessionConfig, SessionConfig } from 'utils/sessionUtils';
import { isAbsoluteURL, urlWithRelayState } from 'utils/urlUtils';
import {
  checkPasswordCharacters,
  isEmail,
  isInRange,
  isLength,
  isNotEmpty,
  isValidEmailList,
  matches,
  optional,
  validateCustomRoles,
  validateRecord,
} from 'utils/validationUtils';

import { AccessToken } from './accessTokenUtils';
import { nilFilter } from './collectionUtils';
import { toFormattedString } from './dateUtils';
import { convertLastSeenDateToUnix, MemberFilters } from './memberUtils';

const MFA_DISABLED = 'disabled';
const MFA_RESET = 'reset';
export const GOOGLE_AUTH_KIND = 'google_oauth';
export const GITHUB_AUTH_KIND = 'github_oauth';
export type OAuthProviderKind = typeof GOOGLE_AUTH_KIND | typeof GITHUB_AUTH_KIND;
const PROFILE_NAME_MAX_LENGTH = 256;

export type SignUpFormType = {
  firstName: string;
  lastName: string;
  organization: string;
  username: string;
  password: string;
  captcha: string;
  oauth: ImmutableMap<{
    userId: string;
    provider: string;
  }> | null;
  jobRole?: string;
  acceptTerms?: boolean;
  marketingEmails?: boolean;
};

export function isAliasedEmail(email: string) {
  // Check if email contains a plus sign followed by one or more characters, followed by @ and one or more characters
  return /\+.*@.+/.test(email);
}

export class SignupForm extends Record<SignUpFormType>({
  firstName: '',
  lastName: '',
  organization: '',
  username: '',
  password: '',
  captcha: '',
  oauth: null,
  jobRole: '',
  acceptTerms: false,
  marketingEmails: false,
}) {
  validate() {
    let predicates = [
      isNotEmpty('username'),
      isEmail('username'),
      isNotEmpty('firstName'),
      isLength(0, PROFILE_NAME_MAX_LENGTH)('firstName'),
      isNotEmpty('lastName'),
      isLength(0, PROFILE_NAME_MAX_LENGTH)('lastName'),
      isNotEmpty('jobRole'),
    ];

    if (!enableRemoveOrganizationFromSignup()) {
      predicates = predicates.concat(isNotEmpty('organization'));
    }

    if (this.oauth !== null) {
      predicates = predicates.concat(isNotEmpty('userId'), isNotEmpty('oauth.provider'), isNotEmpty('oauth.userId'));
    } else {
      predicates = predicates.concat(
        isNotEmpty('password'),
        isLength(minPasswordLength())('password'),
        checkPasswordCharacters({
          requiredCharCountPerClass: passwordMinCharsPerClass(),
          requiredClassCount: passwordMinClasses(),
        })('password'),
      );
    }

    return validateRecord(this, ...predicates);
  }
}

export type FacilitatedTrialType = {
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
};

export class FacilitatedTrial extends Record<FacilitatedTrialType>({
  email: '',
  firstName: '',
  lastName: '',
  organization: '',
}) {}

export type FacilitatedTrialSignUpFormType = {
  password: string;
};

export class FacilitatedTrialSignUpForm extends Record<FacilitatedTrialSignUpFormType>({
  password: '',
}) {
  validate() {
    let predicates = [isNotEmpty('password')];
    predicates = predicates.concat(
      isNotEmpty('password'),
      isLength(minPasswordLength())('password'),
      checkPasswordCharacters({
        requiredCharCountPerClass: passwordMinCharsPerClass(),
        requiredClassCount: passwordMinClasses(),
      })('password'),
    );

    return validateRecord(this, ...predicates);
  }
}

export type AccountType = {
  _id: string;
  _links: Link;
  _access: AccessChecks | null;
  organization: string;
  sessionConfig: SessionConfig;
  requireMfa: boolean;
  billingContact: null | BillingContact;
  tokens: List<string>;
  enableAccountImpersonationUntil: Date | null;
  isSupportGenAiEnabled: boolean;
  canEnableDomainMatching: boolean;
  isDomainMatchingEnabled: boolean;
  isAutoSeatProvisioningEnabled: boolean;
};

export class Account extends Record<AccountType>({
  _id: '',
  _links: Map(),
  _access: null,
  organization: '',
  sessionConfig: createSessionConfig({}),
  requireMfa: false,
  billingContact: null,
  tokens: List(),
  enableAccountImpersonationUntil: null,
  isSupportGenAiEnabled: false,
  canEnableDomainMatching: false,
  isDomainMatchingEnabled: false,
  isAutoSeatProvisioningEnabled: false,
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  validate() {
    return validateRecord(this, optional(isNotEmpty)('organization'));
  }

  getCanonicalBillingContact(members: OrderedMap<string, Member>) {
    const owner = members.find((m) => m.isOwner());

    if (!owner) {
      return createBillingContact();
    }

    const contact = this.billingContact || createBillingContact();
    return contact.withMutations((c) => {
      if (isEmpty(c.name)) {
        c.set('name', owner.getFullName());
      }
      if (isEmpty(c.email)) {
        c.set('email', owner.email);
      }
    });
  }

  checkAccess({ profile }: { profile: Member }) {
    // must wrap in immutable accessDecision for compatibility
    // todo - switch to plain JS access decisions globally
    return (action?: string) => {
      const jsAccess = this._access ? (this._access.toJS() as Access) : undefined;
      const result = checkAccountAccess(profile.toJS(), action as AccountAction, jsAccess);
      return createAccessDecision(result);
    };
  }

  hasAddress() {
    const hasBillingAddress = !!(
      this.billingContact &&
      this.billingContact.address1 &&
      this.billingContact.city &&
      this.billingContact.postalCode &&
      this.billingContact.country
    );

    const hasTaxAddress = !!(
      this.billingContact &&
      this.billingContact.taxAddress &&
      this.billingContact.taxAddress.get('address1') &&
      this.billingContact.taxAddress.get('city') &&
      this.billingContact.taxAddress.get('postalCode') &&
      this.billingContact.taxAddress.get('country')
    );

    return hasBillingAddress || hasTaxAddress;
  }

  hasValidBillingContact() {
    return !!this.billingContact && this.hasAddress();
  }

  canEnableAutoSeatProvisioning() {
    return this.isDomainMatchingEnabled;
  }
}

export type BillingContactType = {
  name: string | null;
  email: string | null;
  company: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
  poNumber: string | null;
  taxAddress: ImmutableMap<{
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    country: string | null;
  }> | null;
};

export class BillingContact extends Record<BillingContactType>({
  name: null,
  email: null,
  company: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  postalCode: null,
  country: null,
  poNumber: null,
  taxAddress: null,
}) {
  validate() {
    let predicates = [
      isNotEmpty('name'),
      optional(isEmail)('email'),
      isNotEmpty('address1'),
      isNotEmpty('city'),
      isNotEmpty('postalCode'),
      isNotEmpty('country'),
      isNotEmpty('state'),
    ];

    if (this.taxAddress !== null) {
      predicates = predicates.concat(
        isNotEmpty('taxAddress.address1'),
        isNotEmpty('taxAddress.city'),
        isNotEmpty('taxAddress.postalCode'),
        isNotEmpty('taxAddress.country'),
        isNotEmpty('taxAddress.state'),
      );
    }

    return validateRecord(this, ...predicates);
  }

  isValid() {
    return this.validate().isEmpty();
  }
}

const memberFullName = (m: MemberSummary | Member) => [m.firstName, m.lastName].join(' ').trim();
const memberDisplayName = (m: MemberSummary | Member): string => m.getFullName() || m.email;

export class MemberSummary extends Record({
  _links: Map(),
  _id: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  _avatarUrl: '',
}) {
  getFullName() {
    return memberFullName(this as MemberSummary);
  }

  getDisplayName() {
    return memberDisplayName(this as MemberSummary);
  }
}

type lastSeenMetadata = ImmutableMap<{
  tokenId?: string;
}>;

type MemberTeamsType = { customRoleKeys: Set<string>; key: string; name: string };

export class MemberTeams extends Record<MemberTeamsType>({
  customRoleKeys: Set(),
  key: '',
  name: '',
}) {
  hasTeamCustomRoles() {
    return !this.customRoleKeys.isEmpty();
  }
}

type MemberPermissionGrantsType = {
  resource: string;
  actions?: Set<string>;
  actionSet: string;
};

export class MemberPermissionGrants extends Record<MemberPermissionGrantsType>({
  resource: '',
  actions: Set(),
  actionSet: '',
}) {
  isTeamMaintainer() {
    return this.actionSet === 'maintainTeam';
  }
  parseResource() {
    return this.resource.split('/');
  }
}

export type NormalizedMembersCollectionResponse = ImmutableMap<{
  entities: ImmutableMap<{
    members: OrderedMap<string, Member>;
  }>;
  result: ImmutableMap<{
    items: string[];
    totalCount: number;
    totalCountWithoutSelfOrOwner?: number;
    _links: ImmutableMap<{
      last: {
        href: string;
        type: string;
      };
      next: {
        href: string;
        type: string;
      };
      self: {
        href: string;
        type: string;
      };
    }>;
  }>;
}>;

export type MemberType = {
  _access: null | AccessChecks;
  _links: Link;
  _id: string;
  _pendingInvite: boolean;
  // Set the default to true for legacy users who do not have the verification property defined
  _verified: boolean;
  _pendingEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  customRoles: Set<string>;
  customRolesInfo: Set<RoleSummaryType>;
  permissionGrants: List<MemberPermissionGrants>;
  mfa: 'enabled' | 'disabled' | 'reset';
  excludedDashboards: Set<string>;
  teams?: List<MemberTeams>;
  _lastSeen: number;
  _lastSeenMetadata: null | lastSeenMetadata;
  _avatarUrl: string;
  creationDate: number;
  canReview?: boolean;
  oauthProviders: List<OAuthProviderKind>;
  version?: number;
  roleAttributes?: Map<string, string[]>;
};
export class Member extends Record<MemberType>({
  _access: null,
  _links: Map(),
  _id: '',
  _pendingInvite: false,
  // Set the default to true for legacy users who do not have the verification property defined
  _verified: true,
  _pendingEmail: '',
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  customRoles: Set(),
  customRolesInfo: Set(),
  permissionGrants: List(),
  mfa: MFA_DISABLED,
  excludedDashboards: Set(),
  teams: List(),
  _lastSeen: -1,
  _lastSeenMetadata: null,
  _avatarUrl: '',
  creationDate: 0,
  canReview: undefined,
  oauthProviders: List(),
  version: 0,
  roleAttributes: Map(),
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this.get('_access');

    if (this.isOwner()) {
      return () => createAccessDecision({ isAllowed: false });
    }

    if (profile._id === this._id) {
      return () =>
        createAccessDecision({ isAllowed: false, customMessage: 'You may not modify this setting for yourself' });
    }

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isWriter()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Writer' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  validate(forceCustom: boolean) {
    return validateRecord(
      this,
      optional(isNotEmpty)('firstName'),
      isLength(0, PROFILE_NAME_MAX_LENGTH)('firstName'),
      optional(isNotEmpty)('lastName'),
      isLength(0, PROFILE_NAME_MAX_LENGTH)('lastName'),
      isEmail('email'),
      validateCustomRoles<Member>((member) => member.customRoles, forceCustom),
    );
  }

  selfLink() {
    return this._links.getIn(['canonical', 'href']) || this._links.getIn(['self', 'href']);
  }

  getFullName() {
    return memberFullName(this as Member);
  }

  getDisplayName() {
    return memberDisplayName(this as Member);
  }

  isOwner() {
    return !this.hasCustomRoles() && this.role === 'owner';
  }

  isAdmin() {
    return !this.hasCustomRoles() && this.role === 'admin';
  }

  isAdminOrOwner() {
    return this.isAdmin() || this.isOwner();
  }

  isWriter() {
    return (
      this.role === 'writer' && !this.hasCustomRoles() && !this.hasTeamCustomRoles() && !this.hasPermissionGrants()
    );
  }

  isReader() {
    return (
      this.role === 'reader' && !this.hasCustomRoles() && !this.hasTeamCustomRoles() && !this.hasPermissionGrants()
    );
  }

  hasOwnerRights() {
    return this.isOwner();
  }

  hasAdminRights() {
    return this.isOwner() || this.isAdmin() || this.hasCustomRoles() || this.hasTeamCustomRoles();
  }

  hasWriterRights() {
    return this.hasAdminRights() || this.isWriter() || this.hasCustomRoles() || this.hasPermissionGrants();
  }

  // Determines if the current profile has adequate permissions to invite members to their LD account.
  hasCreateMemberRights(member: Member) {
    return _hasCreateMemberRights(this.toJS(), member.toJS());
  }

  hasApproveDomainMatchedMemberRights(member: Member) {
    if (this.isOwner() || this.isAdmin()) {
      return true;
    }

    if (!(this.hasCustomRoles() || this.hasTeamCustomRoles())) {
      return false;
    }

    if (!member) {
      return false;
    }

    return !member._access?.get('denied')?.find((v) => v.get('action') === 'approveDomainMatchedMember');
  }

  // TODO: When custom role restriction is fully rolled out across all actions,
  // replace hasWriterRights with this.
  hasStrictWriterRights() {
    return this.isAdmin() || this.isWriter() || this.isOwner();
  }

  hasTeamCustomRoles() {
    let hasTeamRoles = false;
    if (this.teams) {
      this.teams.forEach((team) => {
        if (team.hasTeamCustomRoles()) {
          hasTeamRoles = true;
          return;
        }
      });
    }
    return hasTeamRoles;
  }

  getTeamsMaintained() {
    return this.permissionGrants.map((permissionGrant) => {
      const [type, value] = permissionGrant.parseResource();
      if (type === 'team' && permissionGrant.isTeamMaintainer()) {
        return value;
      }
      return undefined;
    });
  }

  getAllMemberTeamKeys() {
    const memberTeams = this.teams?.map((team) => team?.key);

    if (!memberTeams) {
      return Set<string>();
    }

    const teamsMaintainedByMember = this.getTeamsMaintained().filter(nilFilter);
    return memberTeams.concat(teamsMaintainedByMember).toSet();
  }

  hasPermissionGrants() {
    return !this.permissionGrants.isEmpty();
  }

  hasOnlyTeamCustomRoles() {
    return this.hasTeamCustomRoles() && !this.hasCustomRoles();
  }

  hasCustomRoles() {
    return isTeamsEnabled() && isCustomRolesEnabled() && !this.customRoles.isEmpty();
  }

  hasPendingInvite() {
    return this._pendingInvite;
  }

  isVerified() {
    return this._verified;
  }

  verificationEmail() {
    return this._pendingEmail;
  }

  hasMFA() {
    return this.mfa !== MFA_DISABLED;
  }

  shouldResetMFA() {
    return this.mfa === MFA_RESET;
  }

  toCsvRow(roles?: List<Role>) {
    let customRoles = '""';
    if (isExpandMembersEndpointEnabled()) {
      customRoles = `"${this.customRolesInfo
        .toJS()
        .map((roleSummary) => roleSummary.name)
        .join('; ')}"`;
    } else {
      if (roles) {
        customRoles = `"${this.customRoles.map((r) => roles.find((cr) => cr._id === r)?.name || r).join('; ')}"`;
      }
    }

    let lastSeen = 'no data';
    if (this._lastSeen > 0) {
      lastSeen = `"${toFormattedString(this._lastSeen)}"`;
    } else if (this._lastSeen === 0 || this._pendingInvite) {
      lastSeen = 'never';
    }

    return [
      this._id,
      this.email,
      this.firstName,
      this.lastName,
      this.customRoles.isEmpty() ? this.role : '',
      customRoles,
      this.mfa,
      this._pendingInvite,
      lastSeen,
      JSON.stringify(this._lastSeenMetadata?.toJS()),
    ].join(',');
  }

  hasOAuthProvider() {
    return this.oauthProviders.size > 0;
  }

  hasGoogleOAuth() {
    return this.oauthProviders.some((provider) => provider === GOOGLE_AUTH_KIND);
  }

  hasGithubOAuth() {
    return this.oauthProviders.some((provider) => provider === GITHUB_AUTH_KIND);
  }

  getEmailDomain() {
    return this.email.split('@')[1];
  }
}

export async function membersToCsv(members: List<Member>, roles?: List<Role>): Promise<string[]> {
  return new Promise((resolve) => {
    resolve([
      'id,email,first_name,last_name,role,custom_roles,mfa,pending_invite,last_seen,last_seen_metadata',
      ...members.map((m) => m.toCsvRow(roles)),
    ]);
  });
}

type BulkMemberInviteType = {
  emails: OrderedSet<string>;
  successfulEmails: OrderedSet<string>;
  failedEmails: Map<string, List<string>>;
  role: string;
  customRoles: List<string>;
  teamKeys: List<string>;
};

export class BulkMemberInvite extends Record<BulkMemberInviteType>({
  emails: OrderedSet(),
  failedEmails: Map(),
  successfulEmails: OrderedSet(),
  role: 'reader',
  customRoles: List(),
  teamKeys: List(),
}) {
  isEmpty() {
    return this.emails.isEmpty();
  }

  validateSeatLimit(seatsLeft: number | null) {
    return () => {
      if (seatsLeft !== null && this.size() > seatsLeft) {
        // The message is handled separately in the UI
        return { for: 'emails', errors: [] };
      } else {
        return null;
      }
    };
  }

  validate(seatsLeft: number | null) {
    return validateRecord(this, isNotEmpty('emails'), isValidEmailList('emails'), this.validateSeatLimit(seatsLeft));
  }

  toRep() {
    return this.emails.map((email) => ({
      email,
      role: this.role === 'custom' ? 'reader' : this.role,
      customRoles: this.customRoles,
      teamKeys: this.teamKeys,
    }));
  }

  size(): number {
    return this.emails.size;
  }
}

type SuggestInviteMembersType = {
  emails: OrderedSet<string>;
};

export class SuggestInviteMembers extends Record<SuggestInviteMembersType>({
  emails: OrderedSet(),
}) {
  isEmpty() {
    return this.emails.isEmpty();
  }

  validate() {
    return validateRecord(this, isNotEmpty('emails'), isValidEmailList('emails'));
  }
}

export class OwnerInfo extends Record({
  promotedMember: createMember({}),
}) {
  validate() {
    return validateRecord(this, isNotEmpty('promotedMember'));
  }
}

export class MFAVerification extends Record({
  passcode: '',
}) {
  validate() {
    return validateRecord(this, isNotEmpty('passcode'), isLength(6)('passcode'));
  }
}

export class MFARecovery extends Record({
  code: '',
}) {
  validate() {
    return validateRecord(this, isNotEmpty('code'));
  }
}

export class InvitationDetails extends Record({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
}) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('firstName'),
      isLength(0, PROFILE_NAME_MAX_LENGTH)('firstName'),
      isNotEmpty('lastName'),
      isLength(0, PROFILE_NAME_MAX_LENGTH)('lastName'),
      isLength(minPasswordLength())('password'),
      checkPasswordCharacters({
        requiredCharCountPerClass: passwordMinCharsPerClass(),
        requiredClassCount: passwordMinClasses(),
      })('password'),
    );
  }
}

type MFARecoveryCodesType = {
  recoveryCodes: List<string>;
};

export class MFARecoveryCodes extends Record<MFARecoveryCodesType>({
  recoveryCodes: List(),
}) {}

export type PasswordUpdateProps = {
  current: string;
  password: string;
  confirm: string;
};

const PasswordUpdateProps = {
  current: '',
  password: '',
  confirm: '',
};

export class PasswordUpdate extends Record<PasswordUpdateProps>(PasswordUpdateProps) {
  validate() {
    return validateRecord(
      this,
      isLength(minPasswordLength())('password'),
      checkPasswordCharacters({
        requiredCharCountPerClass: passwordMinCharsPerClass(),
        requiredClassCount: passwordMinClasses(),
      })('password'),
      isNotEmpty('current'),
      isNotEmpty('password'),
      isNotEmpty('confirm'),
      matches('password')('confirm'),
    );
  }
}
export class SamlAppDetails extends Record({
  ssoAcsUrl: '',
  ssoEntityId: '',
  ssoStartUrl: '',
}) {}

type SamlConfigType = {
  _idpSynced: boolean;
  _testRedirectUrl: string;
  enabled: boolean;
  ssoUrl: string;
  x509Certificate: string;
  requireSso: boolean;
  logoutUrl: string | null;
  defaultRole?: RoleName;
  defaultCustomRoleId?: string;
  isManagingTeams: boolean;
  syncedTeamCount: number;
  unSyncedTeamCount: number;
  shouldDecryptAssertions?: boolean;
  shouldSignRequests?: boolean;
  x509KeystoreId?: string;
};

export class SamlConfig extends Record<SamlConfigType>({
  _idpSynced: false,
  _testRedirectUrl: '',
  enabled: false,
  ssoUrl: '',
  x509Certificate: '',
  requireSso: false,
  logoutUrl: null,
  defaultRole: undefined,
  defaultCustomRoleId: undefined,
  isManagingTeams: false,
  syncedTeamCount: 0,
  unSyncedTeamCount: 0,
  shouldDecryptAssertions: undefined,
  shouldSignRequests: undefined,
  x509KeystoreId: undefined,
}) {
  validate() {
    return validateRecord(this, isNotEmpty('ssoUrl'), isAbsoluteURL('ssoUrl'), isNotEmpty('x509Certificate'));
  }

  isDisabled() {
    return !this.enabled;
  }

  isEnabledForTesting() {
    return this.enabled && !this.requireSso;
  }

  isEnabled() {
    return this.enabled && this.requireSso;
  }

  isEmpty() {
    return isEmpty(this.ssoUrl) && isEmpty(this.x509Certificate);
  }

  disable() {
    return this.merge({
      enabled: false,
      requireSso: false,
    });
  }

  enableForTesting() {
    return this.merge({
      enabled: true,
      requireSso: false,
    });
  }

  enable() {
    return this.merge({
      enabled: true,
      requireSso: true,
    });
  }

  getRedirectUrlWithRelayState(redirectUrl: string) {
    return urlWithRelayState(this._testRedirectUrl, redirectUrl);
  }

  toRep() {
    return {
      ...this.toJS(),
      // When setting a custom role in the BE, we set the role type to undefined.
      defaultRole: !!this.defaultCustomRoleId ? undefined : this.defaultRole,
    };
  }
}

export class ScimConfig extends Record({
  _provider: '',
}) {
  isEnabled() {
    return !!this._provider;
  }
}

export class NewJoinTeamRequest extends Record({
  firstName: '',
  lastName: '',
  username: '',
  adminEmail: '',
}) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('username'),
      isEmail('username'),
      isNotEmpty('adminEmail'),
      isEmail('adminEmail'),
      optional(isNotEmpty)('firstName'),
      optional(isNotEmpty)('lastName'),
    );
  }
}

class FollowResource extends Record({
  id: '',
  resourceKind: '',
}) {}

export const createFollowResource = (props?: CreateFunctionInput<FollowResource>) =>
  props instanceof FollowResource ? props : new FollowResource(fromJS(props));

export type FollowableResource = {
  _followed: boolean;
};

export class FollowPreferences extends Record({
  _links: Map(),
  resources: List(),
  notResources: List(),
  resourceSpecs: List(),
  version: 0,
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  updateResource(resourceId: string, resourceKind: resourceKinds, isFollowed: boolean) {
    const followResource = createFollowResource({ id: resourceId, resourceKind });

    if (isFollowed) {
      return this.update('resources', (rs) => rs.push(followResource)).update('notResources', (rs) =>
        rs.filter((r) => r.id !== followResource.id),
      );
    }

    return this.update('notResources', (rs) => rs.push(followResource)).update('resources', (rs) =>
      rs.filter((r) => r.id !== followResource.id),
    );
  }
}

export type RequestSeatsFormType = {
  memberFirstName: string;
  memberLastName: string;
  memberEmail: string;
  numberOfSeats: number;
  description: string;
};

export class RequestSeatsFormRecord extends Record<RequestSeatsFormType>({
  memberFirstName: '',
  memberLastName: '',
  memberEmail: '',
  numberOfSeats: 0,
  description: '',
}) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('memberFirstName'),
      isNotEmpty('memberLastName'),
      isNotEmpty('memberEmail'),
      isEmail('memberEmail'),
      isNotEmpty('numberOfSeats'),
      isInRange({ min: 1 })('numberOfSeats'),
    );
  }
}

export function createNewRequestSeatsForm(props = {}) {
  return new RequestSeatsFormRecord(props);
}

export class APIVersions extends Record({
  validVersions: {},
  latestVersion: 0,
  currentVersion: 0,
  beta: false,
}) {}

export type NotificationChannelSettings = {
  app: boolean;
  email: boolean;
  chat: boolean;
};

export type NotificationChannel = keyof NotificationChannelSettings;
export type NotificationTopics = 'followFlags' | 'approvals' | 'organizationAccess' | 'suggestedInvites';

export type NotificationsSettingsType = {
  [key in NotificationTopics]: NotificationChannelSettings;
};

export type ImmutableNotificationsSettingsType = ImmutableMap<NotificationsSettingsType>;

export const createFollowPreferences = (props = {}) => {
  const prefs = props instanceof FollowPreferences ? props : new FollowPreferences(props);
  return prefs
    .update('resources', (rs) => rs.map(createFollowResource))
    .update('notResources', (rs) => rs.map(createFollowResource));
};

export function createBillingContact(props = {}) {
  return props instanceof BillingContact ? props : new BillingContact(fromJS(props));
}

export function createAccount(props = {}) {
  const account = props instanceof Account ? props : new Account(props);
  return account
    .update('billingContact', (bc) => (bc ? createBillingContact(bc) : null))
    .update('enableAccountImpersonationUntil', (d) => {
      if (!d) {
        return d;
      }
      return isBefore(d, Date.now()) ? null : d;
    });
}

export function createSignupForm(props: CreateFunctionInput<SignupForm> = {}) {
  return props instanceof SignupForm ? props : new SignupForm(fromJS(props));
}

export function createFacilitatedTrialSignupForm(props: CreateFunctionInput<FacilitatedTrialSignUpForm> = {}) {
  return props instanceof FacilitatedTrialSignUpForm ? props : new FacilitatedTrialSignUpForm(fromJS(props));
}

export function createFacilitatedTrial(props: CreateFunctionInput<FacilitatedTrial> = {}) {
  return props instanceof FacilitatedTrial ? props : new FacilitatedTrial(fromJS(props));
}

export function createAPIVersions(props = {}) {
  return props instanceof APIVersions ? props : new APIVersions(props);
}

export const createMemberSummary = (props?: CreateFunctionInput<MemberSummary>) =>
  props instanceof MemberSummary ? props : new MemberSummary(fromJS(props));

export function createMember(props: CreateFunctionInput<Member> = {}) {
  if (props instanceof Member) {
    return props;
  }

  return new Member(fromJS(props))
    .update('customRoles', (rs) => rs.toOrderedSet())
    .update('excludedDashboards', (ed) => ed?.toSet())
    .update('teams', (team) => team?.map((t) => new MemberTeams(t)))
    .update('permissionGrants', (permissionGrant) => permissionGrant?.map((g) => new MemberPermissionGrants(g)));
}

export function createBulkMemberInvite(props: CreateFunctionInput<BulkMemberInvite> = {}) {
  return props instanceof BulkMemberInvite
    ? props
    : new BulkMemberInvite(fromJS(props))
        .update('emails', (emails) => emails.toOrderedSet())
        .update('failedEmails', (failedEmails) => failedEmails.toMap());
}

export function createSuggestInviteMembers(props: CreateFunctionInput<SuggestInviteMembers> = {}) {
  return props instanceof SuggestInviteMembers
    ? props
    : new SuggestInviteMembers(fromJS(props)).update('emails', (emails) => emails.toOrderedSet());
}

export const findServiceTokenById = (serviceTokens: OrderedMap<string, AccessToken>, id: string) =>
  serviceTokens.find((token) => token._id === id);

export const findMembersFromAccountByEmail = ({
  candidateEmails,
  members,
}: {
  candidateEmails: string[];
  members: Member[];
}) => {
  const foundMembers: Member[] = [];
  const existingTeamMembers: Member[] = [];
  const badEmails: string[] = [];
  const unfoundEmails: string[] = [];
  candidateEmails.forEach((email) => {
    if (!isValidEmail(email)) {
      badEmails.push(email);
    } else {
      const member = members.find((m) => m.email === email);
      if (member) {
        foundMembers.push(member);
      } else {
        unfoundEmails.push(email);
      }
    }
  });
  return { foundMembers, existingTeamMembers, badEmails, unfoundEmails };
};

export const UNKNOWN_MEMBER = {
  firstName: 'Unknown',
  lastName: 'member',
  get displayName() {
    return memberFullName(new Member({ firstName: this.firstName, lastName: this.lastName }));
  },
} as const;

export const DELETED_MEMBER_NAME = 'Deleted member';

export function createUnknownMember(id: string) {
  return createMember({ _id: id, firstName: UNKNOWN_MEMBER.firstName, lastName: UNKNOWN_MEMBER.lastName });
}

export function getMemberDisplayAndEmail(
  id: string | null | undefined,
  members: Map<string, Member>,
  accountOrganization: string,
) {
  let memberEmail;
  let memberNameToDisplay;

  if (id && members.has(id)) {
    const member = members.get(id);
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    memberEmail = member!.email; /* eslint-enable @typescript-eslint/no-non-null-assertion */
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    memberNameToDisplay = member!.getDisplayName(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  } else if (id) {
    //maintainer id exists but the member does not
    memberNameToDisplay = createUnknownMember(id).getDisplayName();
  } else {
    //default to the account organization if there is no maintainer id (like from an API token)
    memberNameToDisplay = accountOrganization;
  }
  return { memberEmail, memberDisplayName: memberNameToDisplay };
}

export function getMember(members: Map<string, Member>, id: string) {
  return members.get(id) || createUnknownMember(id);
}

export function createOwnerRecord(props = {}) {
  return new OwnerInfo(props);
}

export function createMFAVerification(props = {}) {
  return new MFAVerification(props);
}

export function createMFARecovery(props = {}) {
  return new MFARecovery(props);
}

export function createInvitationDetails(props = {}) {
  return new InvitationDetails(props);
}

export function createPasswordUpdate(props = {}) {
  return new PasswordUpdate(props);
}

export function createSamlConfig(props = {}) {
  return props instanceof SamlConfig ? props : new SamlConfig(props);
}

export function createScimConfig(props = {}) {
  return props instanceof ScimConfig ? props : new ScimConfig(props);
}

export function createNewJoinTeamRequest(props = {}) {
  return props instanceof NewJoinTeamRequest ? props : new NewJoinTeamRequest(props);
}

export function createMFARecoveryCodes(props: CreateFunctionInput<MFARecoveryCodesType> = {}) {
  return new MFARecoveryCodes(fromJS(props));
}

enum MemberInstructionKindForReplacingRoles {
  REPLACE_SOME_MEMBERS_BUILT_IN_ROLES = 'replaceMembersRoles',
  REPLACE_SOME_MEMBERS_CUSTOM_ROLES = 'replaceMembersCustomRoles',
  REPLACE_ALL_MEMBERS_BUILT_IN_ROLES = 'replaceAllMembersRoles',
  REPLACE_ALL_MEMBERS_CUSTOM_ROLES = 'replaceAllMembersCustomRoles',
}

export function getBulkEditMemberRolesSemanticPatchInstructions(
  memberIDs: string[],
  customRoleKeys: string[],
  role: RoleName,
  filter: MemberFilters,
  ignoredMemberIDs?: string[],
) {
  const instructions = [];
  let filtersApplied = {};
  const filterQuery = filter?.get('query');
  const filterRoles = filter?.get('roles').toArray();
  const filterTeamKey = filter?.get('team');
  const filterLastSeenValue = filter?.get('lastSeen');
  let filterLastSeen;
  if (filterLastSeenValue) {
    if (filterLastSeenValue === 'never') {
      filterLastSeen = { never: true };
    } else if (filterLastSeenValue === 'no-data') {
      filterLastSeen = { noData: true };
    } else {
      // construct the object that gets sent to the BE
      filterLastSeen = {
        before: convertLastSeenDateToUnix(filterLastSeenValue),
      };
    }
    filtersApplied = { ...filtersApplied, filterLastSeen };
  }
  if (filterQuery) {
    filtersApplied = { ...filtersApplied, filterQuery };
  }
  if (filterRoles?.length) {
    filtersApplied = { ...filtersApplied, filterRoles };
  }
  if (filterTeamKey) {
    filtersApplied = { ...filtersApplied, filterTeamKey };
  }
  if (!customRoleKeys.length && memberIDs[0] !== '*') {
    instructions.push({
      kind: MemberInstructionKindForReplacingRoles.REPLACE_SOME_MEMBERS_BUILT_IN_ROLES,
      value: role,
      memberIDs,
    });
  } else if (!customRoleKeys.length && memberIDs[0] === '*') {
    instructions.push({
      ...filtersApplied,
      kind: MemberInstructionKindForReplacingRoles.REPLACE_ALL_MEMBERS_BUILT_IN_ROLES,
      value: role,
      ignoredMemberIDs,
    });
  }
  if (customRoleKeys.length && memberIDs[0] !== '*') {
    instructions.push({
      kind: MemberInstructionKindForReplacingRoles.REPLACE_SOME_MEMBERS_CUSTOM_ROLES,
      values: customRoleKeys,
      memberIDs,
    });
  } else if (customRoleKeys.length && memberIDs[0] === '*') {
    instructions.push({
      kind: MemberInstructionKindForReplacingRoles.REPLACE_ALL_MEMBERS_CUSTOM_ROLES,
      values: customRoleKeys,
      ignoredMemberIDs,
      ...filtersApplied,
    });
  }
  return instructions;
}
enum MemberInstructionKindForAddingToTeams {
  ADD_SOME_MEMBERS_TO_TEAMS = 'addMembersToTeams',
  ADD_ALL_MEMBERS_TO_TEAMS = 'addAllMembersToTeams',
}

export function getBulkAddMembersToTeamsSemanticPatchInstructions(
  memberIDs: string[],
  teamKeys: string[],
  filter: MemberFilters,
  ignoredMemberIDs?: string[],
) {
  const instructions = [];
  let filtersApplied = {};
  const filterQuery = filter?.get('query');
  const filterRoles = filter?.get('roles').toArray();
  const filterTeamKey = filter?.get('team');
  const filterLastSeenValue = filter?.get('lastSeen');
  let filterLastSeen;
  if (filterLastSeen) {
    if (filterLastSeenValue === 'never') {
      filterLastSeen = { never: true };
    }
    if (filterLastSeenValue === 'no-data') {
      filterLastSeen = { noData: true };
    }
    filtersApplied = { ...filtersApplied, filterLastSeen };
  }
  if (filterQuery) {
    filtersApplied = { ...filtersApplied, filterQuery };
  }
  if (filterRoles?.length) {
    filtersApplied = { ...filtersApplied, filterRoles };
  }
  if (filterTeamKey) {
    filtersApplied = { ...filtersApplied, filterTeamKey };
  }
  if (memberIDs[0] !== '*') {
    instructions.push({
      kind: MemberInstructionKindForAddingToTeams.ADD_SOME_MEMBERS_TO_TEAMS,
      memberIDs,
      teamKeys,
    });
  } else if (memberIDs[0] === '*') {
    instructions.push({
      kind: MemberInstructionKindForAddingToTeams.ADD_ALL_MEMBERS_TO_TEAMS,
      ignoredMemberIDs,
      teamKeys,
      ...filtersApplied,
    });
  }
  return instructions;
}

export const jobRoles: { [key: string]: string } = {
  engineer: 'Engineer',
  'product-manager': 'Product Manager',
  'data-scientist': 'Data Scientist',
  'director-or-executive': 'Director/Executive',
  student: 'Student',
  other: 'Other',
};

export const jobRolesList = Object.keys(jobRoles);

export const trackManageMembersPage = createTrackerForCategory('Manage Member');
export const trackSelectAPlan = createTrackerForCategory('Select A Plan');
export const trackTeamSync = createTrackerForCategory('Team Sync');

// Team Sync Events
export const trackTeamSyncTurnOnButtonClicked = () => trackTeamSync('Turn on Button Clicked');
export const trackTeamSyncTurnOffButtonClicked = () => trackTeamSync('Turn off Button Clicked');
export const trackTeamSyncTurnOnConfirmationButtonClicked = () => trackTeamSync('Turn on Confirmation Button Clicked');
export const trackTeamSyncTurnOffConfirmationButtonClicked = () =>
  trackTeamSync('Turn off Confirmation Button Clicked');

// Account security configuration
export const trackAddMembersList = (attr: {
  url: string;
  component: string;
  type: 'button' | 'link';
  hasInviteMemberAccess?: boolean;
}) => trackManageMembersPage('Invite Members Button Clicked', attr);

export const trackRequestNewSeats = (attr: { url: string; component: string; type: 'button' | 'link' }) =>
  trackManageMembersPage('Request seats Button Clicked', attr);

export const trackCustomRoleSelected = () => trackManageMembersPage('Invite Members Dropdown Custom Role Selected');
export const trackInviteMembersModalOpened = () => trackManageMembersPage('Invite Members Modal Opened'); // TODO:
export const trackInviteMembersModalCancelButtonClicked = () =>
  trackManageMembersPage('Invite Members Cancel Button Clicked');
export const trackInviteMembersTeamDropdownClicked = () =>
  trackManageMembersPage('Invite Members Team Dropdown Option Clicked');

export const trackInvitedMembersList = (attr: {
  url: string;
  component: string;
  type: 'button' | 'link';
  referrer: string;
  initialRole: string;
  numberOfInvitedMembers: number;
  teamCount: number;
}) => trackManageMembersPage('New Member Invited', { ...attr, ...getUTMParams() });

export const trackChangeAccountOwners = (attr: { url: string; component: string; type: 'button' | 'link' }) =>
  trackManageMembersPage('Account Owner Changed', attr);

export const trackResendInvitation = (attr: { url: string; component: string; type: 'button'; email: string }) =>
  trackManageMembersPage('Invitation Resent', attr);

export const trackSelectAPlanClicked = (attr: {
  url: string;
  path: string;
  component: string;
  type: 'button' | 'href';
  cta: string;
}) => track('Select A Plan Clicked', 'Select A Plan', attr);

const trackSuggestInvite = createTrackerForCategory('SuggestInvite');
export const trackInviteSuggestionSubmitted = (attrs: AnalyticEventData) =>
  trackSuggestInvite('Invite suggestion submitted', attrs);
