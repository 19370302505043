export const DEFAULT_LOCALE = 'en-US';

export const enum DateFormat {
  H_MM_A = 'h:mm a', // 8:57 AM
  H_MM_SS_A = 'h:mm:ss a', // 8:57:32 AM
  MM_DD_YYYY = 'MM/dd/yyy', // 09/04/1986
  MMMM_D_YYYY = 'MMMM d, yyyy', // September 4, 1986
  MMM_D_YYYY = 'MMM d, yyyy', // Sep 4, 1986
  MMMM_D_YYYY_H_MM_A = 'MMMM d, yyyy h:mm a', // September 4, 1986 8:30 PM
  MMM_D_YYYY_H_MM_A = 'MMM d, yyyy h:mm a', // Sep 4, 1986 8:30 PM
  MMM_D_YYYY_H_MM_SS_A = 'MMM d, yyyy h:mm:ss a', // Sep 4, 1986 8:30:10 PM
  DDD_MMMM_D_YYY_H_MM_A = 'PPPp', // September 26th, 2018 at 10:55 PM
  DDDD_MMMM_D_YYY_H_MM_A = 'PPPPp', // Thursday, September 26th, 2018 at 10:55 PM
  MMM_D = 'MMM d', // Sep 4,
  MMMM_D = 'MMMM d', // September 4
  // This is using the same value as `MMM_D_YYYY` above
  //
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MMM_D_YYY = 'MMM d, yyyy', // Sep 4, 1986
  // This is using the same value as `MMMM_D_YYYY` above
  //
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MMMM_D_YYY = 'MMMM d, yyyy', // September 4, 1986
  YYYY_MM_DD_HH_MM = 'yyy-MM-dd hh:mm a', // 1986-09-04 08:30 PM
  MMM_DST_YYY = 'MMMM do, yyyy', // September 23st, 2018
  MMM_YYYY = 'MMM yyyy', // Sep 1986
  D_YYYY = 'd, yyyy', // 4, 1986
  MM_DD_YYYY_H_MM_A_Z = 'MM/dd/yyyy h:mm a z', // 09/04/1986 8:30 PM UTC
  MM_DD_YYYY_H_MM_A = 'MM/dd/yyyy h:mm a', // 09/04/1986 8:30 PM
  MMM_D_AT_H_MM_A = "MMM d 'at' h:mm a", // Sep 4 at 8:30 PM
  MMM_D_YYYY_AT_H_MM_A = "MMM d, yyyy 'at' h:mm a", // Sep 4, 1986 at 8:30 PM
  MMM_D_H_MM_A = 'MMM d h:mm a', // Sep 4 8:30 PM
  MMM_D_AT_H_MM_A_Z = "MMM d 'at' h:mm a z", // Sep 4 at 8:30 PM UTC
  EE_MMM_D_YYY_H_MM_A_Z = 'EE, MMM d, yyyy h:mm a z', // Thu, Sep 4, 1986 8:30 PM UTC
  EE_MMM_D_YYY_H_MM_A = "EE., MMM. do, yyyy 'at' h:mm a", // Thu., Sep. 4th, 1986 at 8:30 PM
  EEEE_MMMM_D_YYY_H_MM_A = 'EEEE, MMMM d, yyyy h:mm a', // Thursday, September 4, 1986 8:30 PM
  EEEE_MMMM_D_YYY_H_MM_A_Z = 'EEEE, MMMM d, yyyy h:mm a z', // Thursday, September 4, 1986 8:30 PM UTC
  EEEE_YYYY_MM_DD_HH_MM_XXX = 'EEEE yyyy-MM-dd, HH:mm xxx', // Thursday 1986-09-04, 20:30 +00:00
  DEFAULT = 'yyyy-MM-dd, HH:mm xxx', // 1986-09-04, 20:30 +00:00
}
