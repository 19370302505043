import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router';
import { initAnalyticsSession, initCanaryToken, initSegmentAndCandu } from '@gonfalon/analytics';
import { onDOMReady } from '@gonfalon/dom';
// The only modules allowed to consume the provider are application entry points.
// eslint-disable-next-line no-restricted-imports
import { initializeQueryClient, ReactQueryClientProvider } from '@gonfalon/react-query-client';
import { initializeAPIClient } from '@gonfalon/rest-api';
import { trackPageLoadCustomMetrics } from '@gonfalon/telemetry';
import { ThemeProvider } from '@gonfalon/theme';
import { HelmetProvider } from 'helmet';
import thunk from 'redux-thunk';
import { unauthenticatedRoutes } from 'routes/unauthenticatedRoutes';

import { IconProvider } from 'components/IconProvider';
import { createStore } from 'reducers/createStore';

export const load = () => {
  initializeQueryClient();

  const { store } = createStore({
    middlewares: [thunk],
  });

  initAnalyticsSession();
  trackPageLoadCustomMetrics();
  initCanaryToken();
  initSegmentAndCandu();

  initializeAPIClient();

  onDOMReady(() => {
    const selector = '.ld-container';
    const container: HTMLElement | null = document.querySelector(selector);

    if (!container) {
      return;
    }

    const root = createRoot(container);
    const router = createBrowserRouter(createRoutesFromElements(unauthenticatedRoutes()));

    root.render(
      <ReactQueryClientProvider>
        <Provider store={store}>
          <IconProvider>
            <ThemeProvider forceTheme="dark">
              <HelmetProvider>
                <RouterProvider router={router} />
              </HelmetProvider>
            </ThemeProvider>
          </IconProvider>
        </Provider>
      </ReactQueryClientProvider>,
    );
  });
};
