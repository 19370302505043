import { postFlagCleanup } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getFlagCleanupResultsQuery } from './flag-cleanup';

export const useFlagCleanup = createMutationHook(postFlagCleanup, {
  onSuccess: async (data, variables) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getFlagCleanupResultsQuery(variables).queryKey });
  },
});
